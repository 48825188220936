"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomNumber = exports.randomID = void 0;
const errorCodes_enum_1 = require("../enums/errorCodes.enum");
const randomID = (length = 12) => {
    let result = "";
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = length; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
    return result;
};
exports.randomID = randomID;
const randomNumber = (length = 12) => {
    if (length <= 0 || !Number.isInteger(length))
        throw errorCodes_enum_1.ErrorCodes.LENGTH_MUST_BE_A_POSITIVE_NUMBER;
    const min = Math.pow(10, length - 1); // Smallest number with the desired length
    const max = Math.pow(10, length) - 1; // Largest number with the desired length
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
};
exports.randomNumber = randomNumber;
