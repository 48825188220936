import styled from "styled-components";
import { Link } from "react-router-dom";
import { Group } from "../layout/Group/Group";
import { CaretDown } from "react-bootstrap-icons";
import { AccordionItem as Item } from "@szhsin/react-accordion";
import { forPhoneOnly, growSmaller, keyboardFocusOnly } from "./Mixins";

export const StyledA = styled.a`
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    font-weight: 300;

    &:active {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
        font-weight: 300;
    }

    &:hover {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
    }
    ${keyboardFocusOnly}
`

export const StyledNavLink = styled(Link)`
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    font-weight: 300;
    
    &:active {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
        font-weight: 300;
    }

    &:hover {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
    }
    ${keyboardFocusOnly}
`

export const StyledUserPicture = styled.img`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
`

export const StyledDesc = styled.p`
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledH1 = styled.h1`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledH2 = styled.h2`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledH3 = styled.h3`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledH4 = styled.h4`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledH5 = styled.h5`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledH6 = styled.h6`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledErrorText = styled.div`
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.danger};
`

export const StyledSmall = styled.small`
    font-size: 12px;
    position: relative;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledIsRequired = styled.span`
    color: ${(props) => props.theme.colors.danger};
`

export const StyledSuccess = styled.span`
    color: ${(props) => props.theme.colors.success};
`

export const StyledTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
`

export const StyledTitleDesc = styled.p`
    font-size: 0.8rem;
    font-weight: 300;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledTitleDescDiv = styled.div`
    font-size: 0.9rem;
    font-weight: 300;
    color: ${(props) => props.theme.colors.gray};

    &.short {
      text-align: center;
      max-width: 700px;
      ${forPhoneOnly("max-width: 250px;")}
    }
`

const ItemWithChevron = ({ header, ...rest }: any) => (
    <Item
        {...rest}
        header={
            <>
                {header}
                <CaretDown className="chevron-down" />
            </>
        }
    />
);

export const StyledAccordionItem = styled(ItemWithChevron)`
    padding: 10px 14px;
    border-radius: 4px;
    max-width: 100%;
    border: 1px solid ${(props) => props.theme.colors.gray10};
  .szh-accordion__item {
    &-btn {
      flex: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      font-size: 1rem;
      font-weight: 400;
      text-align: left;
      background-color: transparent;
      border: none;
      &:hover {
        background-color: transparent;
      }
    }
  
    &-content {
      transition: height 0.25s cubic-bezier(0, 0, 0, 1);
    }
  
    &-panel {
    }
  }
  
  .chevron-down {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  }
  
  &.szh-accordion__item--expanded {
    .szh-accordion__item-btn {
      background-color: transparent;
    }
    .chevron-down {
      transform: rotate(180deg);
    }
  }
  `;


export const StyledSwitchContainer = styled(Group)`
    padding: 10px 14px;
    border-radius: 2px;
    border: 1px solid ${props => props.theme.colors.gray10};
`

export const Divider = styled.div`
    height: 1px;
    background-color: ${props => props.theme.colors.gray10};
`

export const StyledTableWrapper = styled(Group)`
    max-width: 100%;
    overflow-x: auto;
    table {
        max-width: 100%;
        margin: 20px 0;
        th {
            padding: 6px;
            font-size: 12px;
            text-align: start; 
            padding-bottom: 10px;
            border-bottom: 1px solid ${props => props.theme.colors.gray10};
        }

        tbody {
            tr {
                ${growSmaller}
                td {
                    padding: 6px;
                    font-size: 14px;
                }
            }
        }

    }
`

export const StyledCloseButton = styled.button`
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        color: #000;
        text-decoration: none;
        opacity: 0.75;
    }
    
    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        opacity: 1;
    }
    
`