import Modal from 'react-modal';
import { useEffect } from 'react';
import Home from './pages/Home/Home';
import { themes } from './utils/data';
import Build from './pages/Build/Build';
import Pricing from './pages/Pricing/Pricing';
import { Skeleton } from './components/Skeleton';
import { initGA, logPageView } from "./analytics";
import { ThemeProvider } from 'styled-components';
import Coupons from './pages/Admin/Coupons/Coupons';
import Templates from './pages/Templates/Templates';
import { ErrorBoundary } from 'react-error-boundary';
import { AuthContextProvider } from './context/auth';
import { mediaAssetTypesArray } from 'shared-library';
import SDKAssets from './pages/Admin/SDK/Assets/Assets';
import AdminProducts from './pages/Admin/Products/Products';
import { CouponTypes } from './utils/types/coupon.interface';
import UserDashboard from './pages/Users/Dashboard/Dashboard';
import AdminTemplates from './pages/Admin/Templates/Templates';
import { ReactQueryContextProvider } from './context/react-query';
import { ProfilePicker } from './pages/ProfilePicker/ProfilePicker';
import { NotificationContextProvider } from './context/notification';
import { Notifications } from './components/Notifications/Notifications';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { AuthResetPassword, AuthSignIn, AuthSignUp } from './pages/Auth/Auth';
import { PrivateRoute, PublicRoute } from './components/AuthGuards/PublicRoute';
import { ErrorFallback, InternalServerError, NotFoundError } from './pages/ErrorPage/Errors';

Modal.setAppElement('#root');

const Navigation = () => {

    const location = useLocation();

    useEffect(() => {
        initGA();
        logPageView();
    }, []);

    useEffect(() => {
        logPageView(location.pathname);
    }, [location]);

    return (
        <ThemeProvider theme={themes[0]}>
            <ErrorBoundary
                FallbackComponent={({ error }) => (
                    <ErrorFallback error={error} />
                )}
            >
                <Routes>
                    <Route path="/">

                        <Route path="" element={
                            <Skeleton>
                                {/* <PublicRoute> */}
                                <Home />
                                {/* </PublicRoute> */}
                            </Skeleton>
                        } index />

                        <Route path="auth">
                            <Route path="sign-in" element={
                                <Skeleton>
                                    <PublicRoute>
                                        <AuthSignIn />
                                    </PublicRoute>
                                </Skeleton>
                            } index />
                            <Route path="sign-up" element={
                                <Skeleton>
                                    <PublicRoute>
                                        <AuthSignUp />
                                    </PublicRoute>
                                </Skeleton>
                            } index />
                            <Route path="reset-password" element={
                                <Skeleton>
                                    <PublicRoute>
                                        <AuthResetPassword />
                                    </PublicRoute>
                                </Skeleton>
                            } index />

                        </Route>

                        <Route path="admin">
                            <Route path="products">
                                <Route path="" element={
                                    <Skeleton>
                                        <PrivateRoute strictlyForUser='admin'>
                                            <AdminProducts />
                                        </PrivateRoute>
                                    </Skeleton>
                                } index />
                            </Route>
                            <Route path="templates">
                                <Route path="" element={
                                    <Skeleton>
                                        <PrivateRoute strictlyForUser='admin'>
                                            <AdminTemplates />
                                        </PrivateRoute>
                                    </Skeleton>
                                } index />
                                <Route path=":shortId" element={
                                    <PrivateRoute strictlyForUser='admin'>
                                        <Build />
                                    </PrivateRoute>
                                } index />
                            </Route>
                            <Route path="coupons">
                                {
                                    [CouponTypes.DISCOUNT, CouponTypes.VOUCHER].map(codeType => <Route key={codeType} path={codeType} element={
                                        <Skeleton>
                                            <PrivateRoute strictlyForUser='admin'>
                                                <Coupons codeType={codeType} />
                                            </PrivateRoute>
                                        </Skeleton>
                                    } index />)
                                }
                            </Route>
                            <Route path="sdk">
                                {
                                    mediaAssetTypesArray.map(assetType => <Route key={assetType} path={assetType} element={
                                        <Skeleton>
                                            <PrivateRoute strictlyForUser='admin'>
                                                <SDKAssets assetType={assetType} />
                                            </PrivateRoute>
                                        </Skeleton>
                                    } index />)
                                }
                            </Route>
                        </Route>

                        <Route path="user">
                            <Route path="dashboard">
                                <Route path="" element={
                                    <Skeleton>
                                        <PrivateRoute strictlyForUser='user'>
                                            <UserDashboard />
                                        </PrivateRoute>
                                    </Skeleton>
                                } index />

                                <Route path="projects">
                                    <Route path=":shortId" element={
                                        <PrivateRoute strictlyForUser='user'>
                                            <Build />
                                        </PrivateRoute>
                                    } index />
                                </Route>

                            </Route>
                        </Route>

                        <Route path="templates" element={
                            <Skeleton>
                                <Templates />
                            </Skeleton>
                        } index />

                        <Route path="pricing" element={
                            <Skeleton>
                                <Pricing />
                            </Skeleton>
                        } index />

                        <Route path="profiles" element={
                            <PrivateRoute>
                                <ProfilePicker />
                            </PrivateRoute>
                        } />

                        <Route path="500" element={<Skeleton><InternalServerError /></Skeleton>} />
                        <Route path="*" element={<Skeleton><NotFoundError /></Skeleton>} />
                    </Route>
                </Routes>
            </ErrorBoundary>
            <Notifications />
        </ThemeProvider>
    )
}

const App = () => {
    return (
        <NotificationContextProvider>
            <AuthContextProvider>
                <ReactQueryContextProvider>
                    <BrowserRouter>
                        <Navigation />
                    </BrowserRouter>
                </ReactQueryContextProvider>
            </AuthContextProvider>
        </NotificationContextProvider>
    );
}

export default App;


