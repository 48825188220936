import { useEffect } from "react";
import { randomID } from "shared-library";
import { useTranslation } from "react-i18next";
import { Group } from "../../layout/Group/Group";
import styled, { useTheme } from "styled-components";
import { SupportedLanguages, Utils } from 'diy-website-builder-lib';

const StyledGroup = styled(Group)`
    min-width: 200px;
    min-height: 50px;
    position: relative;
`

const Loading = () => {
    const theme = useTheme()
    const { i18n } = useTranslation()
    const id = randomID()
    useEffect(() => {
        const container = document.getElementById(id)
        if (container) {
            new Utils({
                container,
                primaryColor: theme.colors.black,
                language: i18n.language as SupportedLanguages,
            }).Loading()
        }
    }, [i18n.language, theme.colors.black, id])

    return (
        <StyledGroup>
            <div id={id}></div>
        </StyledGroup>
    );
};

export default Loading;
