import styled from "styled-components";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useApi } from "../../hooks/use-api";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/auth";
import { Group } from "../../layout/Group/Group";
import { useForm } from "../../hooks/use-form.hook";
import { useMutation } from "@tanstack/react-query";
import BillingAddressForm from "./BillingAddressForm";
import { Button } from "../../components/Form/Button";
import Loading from "../../components/Loading/Loading";
import { useContext, useEffect, useState } from "react";
import SubscriptionFeatures from "./SubscriptionFeatures";
import Currency from "../../components/Currency/Currency";
import OffCanvas from "../../components/OffCanvas/OffCanvas";
import { useConsoleLog } from "../../hooks/use-console-log.hook";
import { useNotification } from "../../hooks/use-notification.hook";
import getPreppedInterval from "../../utils/getPreppedInterval.util";
import { TextInput } from "../../components/Form/TextInput/TextInput";
import { boxShadowSmaller, Divider, StyledTitleDesc, transition } from "../../elements";
import { PaymentAttempt, PaymentOptionTypes } from "../../utils/types/paymentAttempt.interface";
import { Dot, InfoCircleFill, Lock, Record, Record2, TicketDetailed, XLg } from "react-bootstrap-icons";
import { cloneDeep, Pricings, ProductSubscriptionDurations, SubscriptionPlan, UserBillingAddress } from "shared-library";

declare const FlutterwaveCheckout: any;

const OrderSummaryGroup = styled(Group)`
    min-height: 100%;
    .summary {
        padding: 10px 14px;
        border: 1px solid ${props => props.theme.colors.gray10};
        
        .add-discount {
            font-size: 10px;
            cursor: pointer;
            font-weight: bold;
            color: ${props => props.theme.colors.success};
        }

        .discount-section {
            margin: 0 10px;
            .closer {
                cursor: pointer;
                color: ${props => props.theme.colors.danger};
            }
        }

        .coupons-wrapper {
            margin-top: -16px;
            p {
                font-size: 14px;
                color: ${props => props.theme.colors.success};

                &.code {
                    font-size: 12px;
                    font-weight: bold;
                    padding: 2px 10px;
                    color: ${props => props.theme.colors.success};
                    background-color: ${props => props.theme.colors.success10};

                    small {
                        cursor: pointer;
                        margin-left: 10px;
                        color: ${props => props.theme.colors.danger};
                    }
                }
            }
        }
    }
    
    .billing-cycle {
        flex: 1;
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 4px;
        ${transition}
        border: 2px solid ${props => props.theme.colors.gray10};

        .save {
            font-size: 10px;
            padding: 4px 8px;
            font-weight: bold;
            border-radius: 4px;
            background-color: ${props => props.theme.colors.gray10};
        }

        .icon {
            color: ${props => props.theme.colors.gray10};
        }

        &.active {
            ${boxShadowSmaller()}
            border-color: ${props => props.theme.colors.success};
            .icon {
                color: ${props => props.theme.colors.success};
            }
            .save {
                color: ${props => props.theme.colors.success};
                background-color: ${props => props.theme.colors.success10};
            }
        }

    }

    .what-you-get-wrapper {
        .what-you-get {
            color: ${props => props.theme.colors.gray};
        }
    }

    .billing-address {
        padding: 14px;
        background-color: ${props => props.theme.colors.gray10};
        input {
            background-color: ${props => props.theme.colors.white};
        }
    }

    .bottom-section {
        small {
            color: ${props => props.theme.colors.gray};
            a {
                text-decoration: none;
                padding-bottom: 2px;
                border-bottom: 1px dotted ${props => props.theme.colors.gray};
                color: ${props => props.theme.colors.gray};
            }
        }
        .secure-transaction {
            font-size: 14px;
        }
    }

    .prorated {
        margin-top: -16px;
        .amount {
            color: ${props => props.theme.colors.success};
        }

        .proration-breakdown {
            margin: 0 6px;
        }
    }
`

interface OrderSummaryProps {
    pricing: Pricings;
    onDone?: () => void;
    offCanvasIsOpen: boolean;
    setOffCanvasIsOpen: (v: boolean) => void;
}
const OrderSummary = ({
    pricing,
    offCanvasIsOpen,
    setOffCanvasIsOpen,
    onDone = () => null,
}: OrderSummaryProps) => {

    const { t } = useTranslation();
    const { log } = useConsoleLog();
    const { notify } = useNotification();
    const { state, setPreferences } = useContext(AuthContext);
    const [paymentOptionIndex, setPaymentOptionIndex] = useState(0);
    const [checkoutInfo, setCheckoutInfo] = useState<PaymentAttempt>();
    const [showingDiscountCodeInput, setShowingDiscountCodeInput] = useState(false);
    const { preparePayment, confirmPayment, applyDiscount, removeDiscount, attemptPayment } = useApi();
    const { isPending: isConfirmingPayment, mutateAsync: confirmPaymentAsync } = useMutation({
        mutationFn: confirmPayment
    })
    const { isPending: applyingDiscount, mutateAsync: applyDiscountAsync } = useMutation({
        mutationFn: applyDiscount
    })
    const { isPending: removingDiscount, mutateAsync: removeDiscountAsync } = useMutation({
        mutationFn: removeDiscount
    })
    const { mutateAsync: preparePaymentAsync } = useMutation({
        mutationFn: preparePayment
    })
    const { isPending: attemptingPayment, mutateAsync: attemptPaymentAsync } = useMutation({
        mutationFn: attemptPayment
    })
    const { onSubmit, onChange, errors, formValues, reset } = useForm({
        defaultValues: {
            code: ""
        },
        validations: {
            code: { required: true },
        }
    })
    const { onSubmit: onSubmitVoucherCode, onChange: onChangeVoucherCode, errors: errorsVoucherCode, formValues: formValuesVoucherCode, reset: resetVoucherCode } = useForm({
        defaultValues: {
            voucher: ""
        },
        validations: {
            voucher: { required: true },
        }
    })

    useEffect(() => {
        try {
            if (!state.preferences.pages.orderSummary[pricing.productCode]) {
                preparePaymentAsync({
                    productPriceId: pricing.id,
                    productId: pricing.productId,
                    productCode: pricing.productCode
                });
            }
        } catch (error) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricing])

    useEffect(() => {
        if (state.preferences.pages.orderSummary[pricing.productCode]) {
            if (checkoutInfo && state.preferences.pages.orderSummary[pricing.productCode].find(p => p.subscriptionPriceBreakdown.productPriceId === checkoutInfo.subscriptionPriceBreakdown.productPriceId)) {
                return
            }
            setCheckoutInfo(state.preferences.pages.orderSummary[pricing.productCode].find(p => p.subscriptionPriceBreakdown.productPriceId === pricing.id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkoutInfo, pricing, state.preferences.pages.orderSummary[pricing.productCode]])

    const validateBillingAddress = (): boolean => {
        if (!checkoutInfo?.billingAddress.fullName?.trim()) {
            notify({ type: "error", title: t('billingAddressError'), body: t('fullNameIsRequired') });
            return false
        }
        if (!checkoutInfo?.billingAddress.address1?.trim()) {
            notify({ type: "error", title: t('billingAddressError'), body: t('addressIsRequired') });
            return false
        }
        if (!checkoutInfo?.billingAddress.city?.trim()) {
            notify({ type: "error", title: t('billingAddressError'), body: t('cityIsRequired') });
            return false
        }
        if (!checkoutInfo?.billingAddress.zip?.trim()) {
            notify({ type: "error", title: t('billingAddressError'), body: t('zipIsRequired') });
            return false
        }
        if (!checkoutInfo?.billingAddress.country?.trim()) {
            notify({ type: "error", title: t('billingAddressError'), body: t('countryIsRequired') });
            return false
        }
        return true
    }

    const handlePayment = async () => {
        try {
            if (!checkoutInfo || (checkoutInfo.profiles[paymentOptionIndex].billingAddressRequired && !validateBillingAddress())) return
            const result = await attemptPaymentAsync({
                paymentAttemptId: checkoutInfo.id,
                customerId: checkoutInfo.customerId,
                provider: checkoutInfo.profiles[paymentOptionIndex].provider,
                ...(formValuesVoucherCode.voucher ? ({ code: formValuesVoucherCode.voucher }) : ({}))
            })

            if (result === "paid") {
                resetVoucherCode()
                onDone()
                return
            }

            if (result === "ready") {
                if (checkoutInfo.profiles[paymentOptionIndex].provider === "flutterwave") {
                    FlutterwaveCheckout({
                        ...checkoutInfo.profiles[paymentOptionIndex].params,
                        callback: async (response: any) => {
                            log({ response });
                            const paymentConfirmation = await confirmPaymentAsync({
                                paymentAttemptId: checkoutInfo.id,
                                customerId: checkoutInfo.customerId,
                                billingAddress: checkoutInfo.billingAddress,
                                provider: checkoutInfo.profiles[paymentOptionIndex].provider,
                                flutterwaveTransactionId: `${response.transaction_id}`,
                            })
                            if (paymentConfirmation) {
                                onDone()
                                return
                            }
                        },
                        onclose: () => {
                            log("Payment closed!");
                        }
                    });
                }
            }
        } catch (error) {
        }
    }

    const getPercentageOff = (): number => {
        if (state.preferences.pages.orderSummary[pricing.productCode].length < 2) return 0
        return 20
    }

    const onBillingAddressChanged = (billingAddress: UserBillingAddress) => {
        setPreferences({
            ...state.preferences,
            pages: {
                ...state.preferences.pages,
                orderSummary: {
                    ...state.preferences.pages.orderSummary,
                    [pricing.productCode]: state.preferences.pages.orderSummary[pricing.productCode].map(p => ({ ...p, billingAddress }))
                }
            }
        })
        setCheckoutInfo(v => v ? ({ ...v, billingAddress: { ...billingAddress } }) : undefined)
    }

    const applyDiscountCode = async () => {
        try {
            const updatedPaymentAttempts = await applyDiscountAsync({
                code: formValues.code,
                customerId: checkoutInfo?.customerId!,
                paymentAttemptIds: state.preferences.pages.orderSummary[pricing.productCode].map(({ id }) => id)
            })

            let discountApplied = false;
            for (const updatedPaymentAttempt of updatedPaymentAttempts) {
                if (updatedPaymentAttempt.coupons.find(c => c.code.trim() === formValues.code.toUpperCase().trim())) {
                    discountApplied = true
                    break
                }
            }

            if (!discountApplied) {
                notify({ type: "error", title: t('invalidDiscountCode'), body: t('theDiscountCodeIsInvalid') });
            } else {
                reset()
            }

            if (checkoutInfo?.billingAddress) {
                const billingAddressCloned = cloneDeep(checkoutInfo?.billingAddress)
                updatedPaymentAttempts.map(updatedPaymentAttempt => ({ ...updatedPaymentAttempt, billingAddress: billingAddressCloned }))
                setPreferences({
                    ...state.preferences,
                    pages: {
                        ...state.preferences.pages,
                        orderSummary: {
                            ...state.preferences.pages.orderSummary,
                            [pricing.productCode]: cloneDeep(updatedPaymentAttempts)
                        }
                    }
                })
                setCheckoutInfo(updatedPaymentAttempts.find(({ id }) => id === checkoutInfo?.id))
            }
        } catch (error) {
        }
    };

    const removeDiscountCode = async (code: string) => {
        try {
            const updatedPaymentAttempts = await removeDiscountAsync({
                code,
                customerId: checkoutInfo?.customerId!,
                paymentAttemptIds: state.preferences.pages.orderSummary[pricing.productCode].map(({ id }) => id)
            })
            setShowingDiscountCodeInput(false)
            if (checkoutInfo?.billingAddress) {
                const billingAddressCloned = cloneDeep(checkoutInfo?.billingAddress)
                updatedPaymentAttempts.map(updatedPaymentAttempt => ({ ...updatedPaymentAttempt, billingAddress: billingAddressCloned }))
                setPreferences({
                    ...state.preferences,
                    pages: {
                        ...state.preferences.pages,
                        orderSummary: {
                            ...state.preferences.pages.orderSummary,
                            [pricing.productCode]: cloneDeep(updatedPaymentAttempts)
                        }
                    }
                })
                setCheckoutInfo(updatedPaymentAttempts.find(({ id }) => id === checkoutInfo?.id))
            }
        } catch (error) {
        }
    }

    return (
        <OffCanvas
            isOpen={offCanvasIsOpen}
            title={t('orderSummary')}
            backdrop={true}
            allowEsc={false}
            allowScroll={false}
            allowClickAway={false}
            handleClose={() => setOffCanvasIsOpen(false)}
        >
            <OrderSummaryGroup justify="center">
                {
                    !state.preferences.pages.orderSummary[pricing.productCode] || !checkoutInfo ? <Loading /> : <>
                        <Group gap="sm">

                            {
                                state.preferences.pages.orderSummary[pricing.productCode].length > 1 && <Group gap="none">
                                    <small><b>{t('billingCycle')}</b></small>
                                    <Group direction="row" gap="sm" wrap="nowrap">
                                        {
                                            state.preferences.pages.orderSummary[pricing.productCode].map(record => <Group key={record.id} gap="none" justify="center" onClick={() => setCheckoutInfo(record)} className={`billing-cycle ${record.subscriptionPriceBreakdown.productPriceId === checkoutInfo.subscriptionPriceBreakdown.productPriceId ? "active" : ""}`}>
                                                <Group direction="row" gap="sm" wrap="nowrap">
                                                    <b>{t(getPreppedInterval(record.subscriptionPriceBreakdown.duration))}</b>
                                                    {
                                                        record.subscriptionPriceBreakdown.duration.in === ProductSubscriptionDurations.YEAR && getPercentageOff() > 0 && <small className="save">{t('save')} {getPercentageOff()}%</small>
                                                    }
                                                </Group>
                                                <small>
                                                    <Currency
                                                        quantity={record.price.amount}
                                                        currency={record.currency}
                                                    /> {t('per' + record.subscriptionPriceBreakdown.duration.in).toLowerCase()}</small>
                                            </Group>)
                                        }
                                    </Group>
                                </Group>
                            }

                            <Group className="summary">
                                <Group direction="row" justify="space-between">
                                    <h5>{pricing.title.toUpperCase()}</h5>
                                    <h5>
                                        <Currency
                                            quantity={checkoutInfo.price.amount}
                                            currency={checkoutInfo.currency}
                                        />
                                    </h5>
                                </Group>

                                <Group>

                                    {
                                        checkoutInfo.subscriptionPriceBreakdown.prorate.howManyUnitsTotal > 1 && <Group className="prorated" align="center" direction="row" justify="space-between">
                                            <StyledTitleDesc>{t('plusBillingCycles', {
                                                billingCycles: checkoutInfo.subscriptionPriceBreakdown.prorate.howManyUnitsTotal - 1
                                            })}
                                                &nbsp;
                                                <b>
                                                    (<Currency
                                                        quantity={checkoutInfo.price.amount}
                                                        currency={checkoutInfo.currency}
                                                    /> * {checkoutInfo.subscriptionPriceBreakdown.prorate.howManyUnitsTotal - 1})
                                                </b>
                                                <InfoCircleFill size={12} className="pointer proration-breakdown" />
                                            </StyledTitleDesc>
                                            <p>
                                                <b>
                                                    <Currency
                                                        quantity={checkoutInfo.price.amount * (checkoutInfo.subscriptionPriceBreakdown.prorate.howManyUnitsTotal - 1)}
                                                        currency={checkoutInfo.currency}
                                                    />
                                                </b>
                                            </p>
                                            <Tooltip anchorSelect=".proration-breakdown" place="top">{t(`yourNextPaymentWillBeAfterX${checkoutInfo.subscriptionPriceBreakdown.duration.in}s`, { unit: checkoutInfo.subscriptionPriceBreakdown.prorate.howManyUnitsTotal })}</Tooltip>
                                        </Group>
                                    }

                                    {
                                        checkoutInfo.subscriptionPriceBreakdown.prorate.discount > 0 && <Group className="prorated" align="center" direction="row" justify="space-between">
                                            <StyledTitleDesc>{t('creditedProratedAmount')}</StyledTitleDesc>
                                            <p>
                                                <b className="amount">
                                                    - &nbsp;
                                                    <Currency
                                                        quantity={checkoutInfo.subscriptionPriceBreakdown.prorate.discount}
                                                        currency={checkoutInfo.currency}
                                                    />
                                                </b>
                                            </p>
                                        </Group>
                                    }

                                    {
                                        checkoutInfo.coupons.length === 0 ? <>
                                            {
                                                !showingDiscountCodeInput ? <small onClick={() => setShowingDiscountCodeInput(true)} className="add-discount">
                                                    {t('addDiscountCode')}
                                                </small> : <form onSubmit={onSubmit(applyDiscountCode)}>
                                                    <Group align="center" gap="xs" className="discount-section" direction="row" wrap="nowrap">
                                                        <TextInput
                                                            size="sm"
                                                            isRequired
                                                            type="text"
                                                            error={errors.code}
                                                            value={formValues.code}
                                                            readonly={applyingDiscount}
                                                            className="input-container"
                                                            onChange={onChange("code")}
                                                            parentStyle={{ flexGrow: 1 }}
                                                            placeholder={t('discountCode')}
                                                        />
                                                        <Group direction="row" gap="xs" align="center" wrap="nowrap">
                                                            <Button size="sm" text={t('apply')} type="submit" disabled={applyingDiscount} />
                                                            <XLg className="closer" onClick={() => setShowingDiscountCodeInput(false)} />
                                                        </Group>
                                                    </Group>
                                                </form>
                                            }
                                        </> : <Group gap="xs" className="coupons-wrapper">
                                            {
                                                checkoutInfo.coupons.map(coupon => <Group key={coupon.code} className={removingDiscount ? "disabledEvents" : ""} align="center" direction="row" justify="space-between">
                                                    <p className="code">{coupon.code} <small onClick={() => removeDiscountCode(coupon.code)}><XLg size={10} /></small></p>
                                                    <p>
                                                        <b>
                                                            <Currency
                                                                quantity={-coupon.actualValueOff}
                                                                currency={checkoutInfo.currency}
                                                            />
                                                        </b>
                                                    </p>
                                                </Group>)
                                            }
                                        </Group>
                                    }
                                </Group>

                                {
                                    state.resources.pricing && <Group gap="xs" className="what-you-get-wrapper">
                                        <SubscriptionFeatures icon={<Dot />} subscription={state.resources.pricing.subscriptionBenefits[pricing.productCode as SubscriptionPlan]} />
                                    </Group>
                                }

                            </Group>
                            <Divider></Divider>

                            {
                                checkoutInfo.profiles.length > 1 && <Group gap="none">
                                    <small><b>{t('howWouldYouLikeToPay')}</b></small>
                                    <Group gap="xs">
                                        {
                                            checkoutInfo.profiles.map((record, index) => <Group gap="none" key={record.provider} onClick={() => setPaymentOptionIndex(index)} justify="center" className={`billing-cycle ${paymentOptionIndex === index ? "active" : ""}`}>
                                                {
                                                    record.paymentOptionType === PaymentOptionTypes.Card && <Group align="center" wrap="nowrap" direction="row" justify="space-between">
                                                        <Group direction="row" gap="xs" align="center">
                                                            {
                                                                paymentOptionIndex === index ? <Record2 className="icon" size={22} /> : <Record className="icon" size={22} />
                                                            }
                                                            <b style={{ paddingTop: 2, fontSize: 12 }}>
                                                                {t('creditOrDebitCard')}
                                                            </b>
                                                        </Group>
                                                        <img width={170} src="/assets/images/card-types.png" alt="Card types" loading="lazy" />
                                                    </Group>
                                                }

                                                {
                                                    record.paymentOptionType === PaymentOptionTypes.Code && <Group align="center" wrap="nowrap" direction="row" justify="space-between">
                                                        <Group direction="row" gap="xs" align="center">
                                                            {
                                                                paymentOptionIndex === index ? <Record2 className="icon" size={22} /> : <Record className="icon" size={22} />
                                                            }
                                                            <b style={{ paddingTop: 2, fontSize: 12 }}>
                                                                {t('voucher')}
                                                            </b>
                                                        </Group>
                                                        <TicketDetailed size={24} />
                                                    </Group>
                                                }
                                            </Group>)
                                        }
                                    </Group>
                                </Group>
                            }

                            {
                                checkoutInfo.profiles.length > paymentOptionIndex && <>
                                    {
                                        checkoutInfo.profiles[paymentOptionIndex].billingAddressRequired && <Group gap="sm" className="billing-address">
                                            <small><b>{t('billingAddress')}</b></small>
                                            <BillingAddressForm data={checkoutInfo.billingAddress!} onDataChange={onBillingAddressChanged} />
                                        </Group>
                                    }

                                    {
                                        checkoutInfo.profiles[paymentOptionIndex].paymentOptionType === PaymentOptionTypes.Card && <>
                                            <Divider></Divider>
                                            <Group className="total" direction="row" align="start" justify="space-between">
                                                <Group gap="none">
                                                    <h5><b>{t('totalDue')}</b></h5>
                                                    <small><Currency
                                                        quantity={checkoutInfo.unitPrice}
                                                        currency={checkoutInfo.currency}
                                                    /> * {t(checkoutInfo.subscriptionPriceBreakdown.duration.in === ProductSubscriptionDurations.MONTH ? '1month' : '12months')}</small>
                                                </Group>
                                                <Group gap="xs" align="center" direction="row">
                                                    <h5>
                                                        <b>
                                                            <Currency
                                                                quantity={checkoutInfo.price.total}
                                                                currency={checkoutInfo.currency}
                                                            />
                                                        </b>
                                                    </h5>
                                                </Group>
                                            </Group>
                                            <Group gap="sm" className="bottom-section">
                                                <Group gap="xs" justify="center" align="center" direction="row">
                                                    <small>{t('byContinuingYouAgreeToOur')}</small>
                                                    <small>
                                                        <Link target="_blank" to="/terms-of-service">{t('terms')}</Link>
                                                    </small>
                                                    <small>{t('and').toLowerCase()}</small>
                                                    <small>
                                                        <Link target="_blank" to="/policies/privacy">{t('privacyPolicy')}</Link>.
                                                    </small>
                                                </Group>
                                                <Button text={t('payNow')} disabled={isConfirmingPayment || attemptingPayment} loading={isConfirmingPayment || attemptingPayment} onClick={handlePayment} />
                                                <Group className="secure-transaction" wrap="nowrap" justify="center" gap="xs" direction="row">
                                                    <Lock size={12} />
                                                    <small dangerouslySetInnerHTML={{ __html: t('allTransactionsAreSecureAndEncrypted') }}></small>
                                                </Group>
                                            </Group>
                                        </>
                                    }

                                    {
                                        checkoutInfo.profiles[paymentOptionIndex].paymentOptionType === PaymentOptionTypes.Code && <>
                                            <form onSubmit={onSubmitVoucherCode(handlePayment)}>
                                                <Group gap="sm">
                                                    <TextInput
                                                        isRequired
                                                        type="text"
                                                        placeholder={t('voucher')}
                                                        className="input-container"
                                                        error={errorsVoucherCode.voucher}
                                                        value={formValuesVoucherCode.voucher}
                                                        onChange={onChangeVoucherCode("voucher")}
                                                    />
                                                    <Button text={t('payNow')} disabled={isConfirmingPayment || attemptingPayment} loading={isConfirmingPayment || attemptingPayment} type="submit" />
                                                </Group>
                                            </form>
                                        </>
                                    }
                                </>
                            }
                        </Group>
                    </>
                }
            </OrderSummaryGroup>
        </OffCanvas>
    )
}

export default OrderSummary;