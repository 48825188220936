import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { FormEvent, useCallback, useState } from "react";
import { validURL, cloneDeep, isValidDateTime, isArray } from "shared-library";

const emailRegEx = new RegExp(/.+@.+\..+/);

type Errors<T> = Partial<Record<keyof T, string>>;

interface Validation {
  required?: boolean;
  min?: number;
  max?: number;
  url?: boolean;
  email?: boolean;
  date?: boolean;
}

export type ChildErrors = { [key: string]: string };
type Validations<T> = Partial<Record<keyof T, Validation>>;

interface useFormProps<T> {
  defaultValues: T;
  validations?: Validations<T>;
}

const validateField = (value: unknown, validation: Validation, t: TFunction<"translation", undefined>) => {
  const { required, min, max, email, date, url } = validation;
  if (required && !value && value !== 0) return t('thisFieldIsRequired');

  if (min && typeof value === "string" && value.length < min)
    return t('mustBeAtLeastXCharacters', { min });

  if (min && isArray(value) && value.length < min)
    return t('mustBeAtLeastXLength', { min });

  if (max && typeof value === "string" && value.length > max)
    return t('mustNotBeMoreThanCharacters', { max });

  if (email && typeof value === "string" && !value.match(emailRegEx))
    return t('thisMustBeAValidEmailAddress');

  if (url && typeof value === "string" && value && !validURL(value))
    return t('thisMustBeAValidURL');

  if (date && typeof value === "string" && value && !isValidDateTime(value))
    return t('thisMustBeAValidDate');
};

export const useForm = <T>({
  defaultValues,
  validations,
}: useFormProps<T>) => {

  const { t } = useTranslation();
  const [originalFormValues] = useState<T>(() => cloneDeep(defaultValues));
  const [formValues, setFormValues] = useState<T>(() => defaultValues);
  const [errors, setErrors] = useState<Errors<T>>(() => ({}));

  type valueOf<T> = T[keyof T]

  const onChange = useCallback(
    (key: keyof T) => {
      return (newValue: valueOf<T>) => {
        setErrors({});
        setFormValues((values) => ({
          ...values,
          ...{ [key]: newValue },
        }));
      };
    },
    []
  );

  const validate = useCallback(() => {
    let isValid = true;
    if (validations) {
      for (const key in validations) {
        const value = formValues[key];
        const validation = validations?.[key];
        if (!validation) continue;
        const validationResult = validateField(value, validation, t);
        if (validationResult) {
          isValid = false;
          setErrors((errs) => ({
            ...errs,
            ...{ [key]: validationResult },
          }));
        }
      }
    }
    return isValid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validations, formValues]);

  const onSubmit = useCallback(
    (callback: (formValues: T) => void) => {
      return (e: FormEvent) => {
        e.preventDefault();
        if (validate()) callback(formValues);
      };
    },
    [formValues, validate]
  );

  const reset = useCallback(() => setFormValues(() => { setErrors({}); return cloneDeep(originalFormValues) }), [originalFormValues])
  const updateValues = useCallback((d: T) => setFormValues(() => ({ ...formValues, ...d })), [formValues])
  return { onChange, onSubmit, formValues, errors, validate, updateValues, reset, setErrors, setFormValues };
};

export const validateNested = <T>(values: T, validations: Validations<T>, t: TFunction<"translation", undefined>) => {
  const response: {
    valid: boolean;
    errors: { [K in keyof T]?: string };
  } = {
    valid: true,
    errors: {}
  }
  if (validations) {
    for (const key in validations) {
      const value = values[key];
      const validation = validations[key];
      if (!validation) continue;
      const validationResult = validateField(value, validation, t);
      if (validationResult) {
        response.valid = false;
        response.errors[key] = validationResult;
      }
    }
  }
  return response;
};

export const findError = (errors: Array<ChildErrors>, key: string): string | undefined => {
  const error = errors.find(error => error[key]);
  return error ? error[key] : ""
}

export const childrenHasError = (errors: Array<ChildErrors>, prefix: string): boolean => {
  for (const error of errors) {
    for (const key in error) {
      if (key.startsWith(prefix)) return true
    }
  }
  return false
}