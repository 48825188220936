import styled from "styled-components";
import { isString } from "shared-library";
import styles from "./TextInput.module.scss";
import { CSSProperties, ReactNode } from "react";
import { InputContainer } from "../InputContainer/InputContainer";
import { FormComponentProps } from "../../../utils/types/form-component-props";

type Size = "sm" | "md";

const sizeMap: Record<Size, string> = {
    sm: styles.sm,
    md: styles.md,
};

interface TextInputProps extends FormComponentProps {
    size?: Size;
    id?: string;
    max?: number;
    value: string;
    label?: string;
    error?: string;
    tooltip?: string;
    maxLength?: number;
    className?: string;
    readonly?: boolean;
    leftIcon?: ReactNode;
    placeholder?: string;
    isRequired?: boolean;
    description?: string;
    rightIcon?: ReactNode;
    style?: CSSProperties;
    showErrorMsg?: boolean;
    parentStyle?: CSSProperties;
    onChange: (value: string) => void;
    type?: "text" | "number" | "email" | "url" | "password";
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const StyledInput = styled.input`
    padding: 10px;
    width: 100%;
    background-color: transparent;
    color: ${props => props.theme.colors.gray};
    border: 1px solid ${(props) => props.theme.colors.gray10};

    &::placeholder {
        color: ${(props) => props.theme.colors.gray10};
    }

    &.hasError {
        border-color: ${(props) => props.theme.colors.danger};
    }
`

const StyledTextArea = styled.textarea`
    padding: 10px;
    min-height: 100px;
    color: ${(props) => props.theme.colors.gray};
    border: 1px solid ${(props) => props.theme.colors.gray};

    &::placeholder {
        color: ${(props) => props.theme.colors.gray};
    }

    &.hasError {
        border-color: ${(props) => props.theme.colors.danger};
    }
`

export const TextInput = (props: TextInputProps) => {
    const {
        id,
        max,
        label,
        error,
        tooltip,
        onKeyUp,
        onChange,
        leftIcon,
        rightIcon,
        onKeyDown,
        maxLength,
        style = {},
        isRequired,
        value = "",
        description,
        placeholder,
        size = "md",
        type = "text",
        className = "",
        readonly = false,
        showErrorMsg = true,
    } = props;

    const classNames = `${styles.input} ${sizeMap[size]} ${className} ${error ? 'hasError' : ""} ${rightIcon ? styles.hasRightIcon : ""} ${leftIcon ? styles.hasLeftIcon : ""}`;

    return (
        <InputContainer
            id={id}
            label={label}
            tooltip={tooltip}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            description={description}
            error={showErrorMsg ? error : ""}
            isRequired={isRequired}
            style={props.parentStyle}
            render={(ID) => (
                <StyledInput
                    id={ID}
                    max={max}
                    type={type}
                    style={style}
                    value={value}
                    readOnly={readonly}
                    maxLength={maxLength}
                    className={classNames}
                    placeholder={placeholder}
                    onWheel={(e: any) => e.target.blur()}
                    onKeyUp={(e) => onKeyUp && onKeyUp(e)}
                    onKeyDown={(e) => onKeyDown && onKeyDown(e)}
                    onChange={({ target }) => onChange(isString(target.value) && type === "number" ? +target.value as any : target.value)}
                />
            )}
        />
    );
};

export const TextArea = (props: TextInputProps) => {
    const {
        value,
        label,
        error,
        onChange,
        rightIcon,
        style = {},
        isRequired,
        description,
        placeholder,
        size = "md",
        readonly = false,
        showErrorMsg = true,
    } = props;

    const classNames = `${styles.input} ${sizeMap[size]} ${error && 'hasError'} ${rightIcon && styles.hasRightIcon}`;

    return (
        <InputContainer
            label={label}
            rightIcon={rightIcon}
            description={description}
            error={showErrorMsg ? error : ""}
            isRequired={isRequired}
            style={props.parentStyle}
            render={(ID) => (
                <StyledTextArea
                    id={ID}
                    style={style}
                    value={value}
                    readOnly={readonly}
                    className={classNames}
                    placeholder={placeholder}
                    onChange={({ target }) => onChange(target.value)}
                />
            )}
        />
    );
};
