import Moment from "react-moment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { growSmaller } from "../../elements";
import { useTranslation } from "react-i18next";
import { Group } from "../../layout/Group/Group";
import { Facebook, Instagram, Linkedin, TwitterX, Youtube } from "react-bootstrap-icons";

const StyledGroup = styled(Group)`
    padding: 50px;
    margin-top: 100px;
    border-top: 1px solid ${props => props.theme.colors.gray10};
    ${growSmaller}

    .footer {

        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid ${props => props.theme.colors.gray10};

        .socials {
            cursor: pointer;
            span {
                opacity: 0.3;
                padding: 0 4px;
                color: ${props => props.theme.colors.gray};
            }

            .social-icon {
                color: ${props => props.theme.colors.gray};
            }
        }


        a {
            font-size: 12px;
            text-decoration: none;
            color: ${props => props.theme.colors.gray};
        }
    }

    .footer-base {
        text-decoration: none;
        font-size: 12px;
        color: ${props => props.theme.colors.gray};

        a {
            text-decoration: none;
            padding-bottom: 2px;
            border-bottom: 1px dotted ${props => props.theme.colors.gray};
            color: ${props => props.theme.colors.gray};
        }
    }
`;
const Footer = () => {
    const { t } = useTranslation();
    return <StyledGroup>
        <Group className="footer container-fluid" align="center" direction="row" justify="space-between">
            <Link to="/" role="logo">
                <img src="/logo.png" width="160" alt="Company logo" />
            </Link>
            <Group direction="row" align="center">
                <Link to="/">Contact Us</Link>
                <Link to="/">Support Center</Link>
                <Link to="/">Blog</Link>
                <Link to="/">User Guide</Link>
            </Group>
            <Group direction="row" gap="xs" wrap="nowrap">
                <Group direction="row" gap="none" align="center" className="socials"><span>{'{'}</span><Facebook className="social-icon" /><span>{'}'}</span></Group>
                <Group direction="row" gap="none" align="center" className="socials"><span>{'{'}</span><Instagram className="social-icon" /><span>{'}'}</span></Group>
                <Group direction="row" gap="none" align="center" className="socials"><span>{'{'}</span><TwitterX className="social-icon" /><span>{'}'}</span></Group>
                <Group direction="row" gap="none" align="center" className="socials"><span>{'{'}</span><Linkedin className="social-icon" /><span>{'}'}</span></Group>
                <Group direction="row" gap="none" align="center" className="socials"><span>{'{'}</span><Youtube className="social-icon" /><span>{'}'}</span></Group>
            </Group>
        </Group>
        <Group className="footer-base" gap="sm" direction="row" align="center" justify="center">
            <span>© <Moment format="YYYY" /> DIY Website Builder. All rights reserved.</span>
            <Link to="/policies/privacy">{t('privacyPolicy')}</Link>
            <Link to="/terms-of-service">Terms of Service</Link>
            <Link to="/policies/cookies">Cookies Settings</Link>
        </Group>
    </StyledGroup>
}

export default Footer;