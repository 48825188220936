import { ErrorCodes } from "shared-library";

export const parseResponse = async (response?: Response) => {
  try {
    if (!response) throw ErrorCodes.INVALID_REQUEST;
    const { status, statusText } = response;
    if (status === 500) throw statusText;
    const data = await response.json();
    return { data, status };
  } catch (e) {
    return { data: [], status: 500 };
  }
};
