import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { StyledTitleDesc } from "../../../elements";
import { InputContainer } from "../InputContainer/InputContainer";
import { ChangeEvent, CSSProperties, DragEvent, useState } from "react";
import { FormComponentProps } from "../../../utils/types/form-component-props";
import { FileEarmarkRichtext } from "react-bootstrap-icons";

interface FileInputProps extends FormComponentProps {
    id?: string;
    label?: string;
    error?: string;
    tooltip?: string;
    multiple?: boolean;
    extension?: string;
    uploading?: boolean;
    isRequired?: boolean;
    description?: string;
    showErrorMsg?: boolean;
    parentStyle?: CSSProperties;
    onChange: (value: File[]) => void;
}

const StyledInputContainer = styled.div`
    padding: 20px;
    cursor: pointer;
    border-radius: 8px;
    text-align: center;
    transition: background-color 0.3s, border-color 0.3s;
    border: 2px dashed ${props => props.theme.colors.gray15};

    &.dragging {
        border-color: ${props => props.theme.colors.secondary};
    }

    input[type="file"] {
        display: none;
    }

    label {
        margin-top: 10px; 
        cursor: pointer;
        display: inline-block; 

        span {
            font-size: 14px;
            padding: 10px 30px;
            color: ${props => props.theme.colors.white};
            background-color: ${props => props.theme.colors.primary};
        }
    }
`

export const FileInput = (props: FileInputProps) => {
    const {
        id,
        label,
        error,
        tooltip,
        onChange,
        isRequired,
        description,
        multiple = false,
        uploading = false,
        showErrorMsg = true,
        extension = "image/*",
    } = props;

    const { t } = useTranslation();
    const [isDragging, setIsDragging] = useState(false);

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
        const files = event.dataTransfer.files;
        onChange(Array.from(files));
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        onChange(Array.from(files!));
    };

    return (
        <InputContainer
            id={id}
            label={label}
            tooltip={tooltip}
            description={description}
            error={showErrorMsg ? error : ""}
            isRequired={isRequired}
            style={props.parentStyle}
            render={(ID) => (
                <StyledInputContainer
                    id={ID}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    className={isDragging ? "dragging" : ""}
                >
                    <StyledTitleDesc>
                        {t(isDragging ? 'dragYourFilesHere' : 'dragAndDropFilesHereOrClickToUpload')}
                    </StyledTitleDesc>
                    <input
                        type="file"
                        accept={extension}
                        multiple={multiple}
                        id={ID + "fileInput"}
                        onChange={handleFileChange}
                        className={uploading ? "disabledEvents" : ""}
                    />
                    <label htmlFor={ID + "fileInput"} className={uploading ? "disabledEvents" : ""}>
                        <span><FileEarmarkRichtext /> &nbsp; {t(uploading ? 'uploading' : 'browseFiles')}</span>
                    </label>
                </StyledInputContainer>
            )}
        />
    );
};
