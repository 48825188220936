import { CouponTypes } from "./coupon.interface";
import { ProductInterval, UserBillingAddress } from "shared-library";

export type Gateway = "flutterwave" | "stripe" | "paypal" | "paystack" | "voucher";
export enum SortDirections {
    ASC = "asc",
    DESC = "desc"
}
export enum PaymentOptionTypes {
    Card = "card",
    Code = "code"
}
export type AttemptPaymentResponse = "ready" | "paid";

export interface PaymentAttempt {
    id: string;
    price: {
        tax: number;
        amount: number;
        discount: number;
        /**
         * Total amount to be charged plus tax minus discounts
         */
        total: number;
    };
    quantity: number;
    unitPrice: number;
    productTitle: string;
    taxPercentage: number;
    coupons: ValidCoupons[];
    billingAddress: UserBillingAddress;
    subscriptionPriceBreakdown: SubscriptionPriceOnCheckout;

    paid: boolean;
    clientId: string;
    currency: string;
    customerId: string;
    profiles: {
        provider: Gateway;
        billingAddressRequired: boolean;
        paymentOptionType: PaymentOptionTypes;
        params: Record<string, string | number | Object>;
    }[];
}

export interface ValidCoupons {
    code: string;
    couponId: string;
    type: CouponTypes;
    productPriceId: string;
    discountAmount: number;
    actualValueOff: number;
    discountAmountInPercentage: boolean;
}

export interface SubscriptionPriceOnCheckout {
    price: number;
    currency: string;
    productId: string;
    productPriceId: string;
    duration: ProductInterval;
    prorate: {
        discount: number;
        freeDays: number;
        payableDays: number;
        howManyUnitsTotal: number;
    };
    action: SubscriptionPriceOnCheckoutActions;
}

export enum SubscriptionPriceOnCheckoutActions {
    UPGRADE = "UPGRADE",
    RENEWAL = "RENEWAL",
    DOWNGRADE = "DOWNGRADE",
    FORBIDDEN = "FORBIDDEN",
    NEWSUBSCRIPTION = "NEWSUBSCRIPTION"
}