import { Config } from "../../config";
import { DiskManager } from "../../utils/diskManager";
import { PaymentAttempt } from "../../utils/types/paymentAttempt.interface";
import { createContext, ReactNode, useCallback, useMemo, useState } from "react";
import { cloneDeep, Paginated, Pricings, PricingsResponse, ProjectDetails, Status, Template, UserDetails } from "shared-library";

export type AuthStatePreferencesValuesProject = "allProjects" | "ownProjects" | "shared";
interface AuthStatePreferencesValues {
    adminProductStatus: Status;
    adminTemplateStatus: Status;
    projectSort: AuthStatePreferencesValuesProject;
    whereILeftOff: {
        beforeAuthFlow: {
            payment?: {
                pricing: Pricings;
            }
            startProject?: {
                templateId?: string;
            }
        }
    };
    pages: {
        orderSummary: Record<string, PaymentAttempt[]>;
        // tutors: {
        //     limit: number;
        //     query?: string;
        //     status?: TutorStatus;
        //     nextBatchIndex?: string;
        //     filter?: PageFiltersValues;
        // };
    };
}

interface AuthStateResourcesValues {
    pricing?: PricingsResponse;
    adminTemplates: Template[];
    projects?: Paginated<ProjectDetails>;
}

interface AuthStateValues {
    version: string;
    user?: UserDetails;
    resources: AuthStateResourcesValues;
    preferences: AuthStatePreferencesValues;
}

interface AuthValues {
    logout: () => void;
    state: AuthStateValues;
    switchProfile: () => void;
    login: (user: UserDetails) => void;
    setUser: (user: UserDetails) => void;
    replaceState: (newState: AuthStateValues) => void;
    setResources: (resources: AuthStateResourcesValues) => void;
    setPreferences: (preferences: AuthStatePreferencesValues) => void;
}

const diskManager = new DiskManager(`_authData.${Config.AppBaseURL}`)

const defaultState: AuthStateValues = {
    version: "1.0.12",
    resources: {
        adminTemplates: []
    },
    preferences: {
        projectSort: "allProjects",
        adminProductStatus: Status.active,
        adminTemplateStatus: Status.active,
        pages: {
            orderSummary: {}
        },
        whereILeftOff: {
            beforeAuthFlow: {

            }
        }
    },
}

const getInitialState = (): AuthStateValues => {
    let _savedStateStr = diskManager.retrieve()
    if (!_savedStateStr) return { ...defaultState }
    const _savedState = JSON.parse(_savedStateStr) as AuthStateValues
    if (_savedState.version !== defaultState.version) {
        // Version update occurred 
        return { ...defaultState }
    }
    return _savedState
}

const initialState = getInitialState()
const ctxDefaultValue: AuthValues = {
    state: initialState,
    login: () => undefined,
    logout: () => undefined,
    setUser: () => undefined,
    replaceState: () => undefined,
    setResources: () => undefined,
    switchProfile: () => undefined,
    setPreferences: () => undefined,
};

export const AuthContext = createContext<AuthValues>(ctxDefaultValue);
export const AuthContextProvider = ({ children }: { children: ReactNode }) => {

    const [state, setState] = useState(ctxDefaultValue.state);
    const logout = useCallback(() => {
        setState(cloneDeep(defaultState))
    }, []);

    const setUser = useCallback((user: UserDetails) => setState({ ...state, user }), [state]);
    const replaceState = useCallback((newState: AuthStateValues) => setState({ ...newState }), []);
    const setResources = useCallback((resources: AuthStateResourcesValues) => setState({ ...state, resources }), [state]);
    const setPreferences = useCallback((preferences: AuthStatePreferencesValues) => setState({ ...state, preferences }), [state]);
    const switchProfile = useCallback(() => setState({ ...state, user: state.user ? { ...state.user, currentUserType: undefined } : undefined }), [state]);
    const login = useCallback((user: UserDetails) => setState({ ...defaultState, user, preferences: { ...defaultState.preferences, whereILeftOff: { ...state.preferences.whereILeftOff } } }), [state.preferences]);

    const value = useMemo(() => {
        diskManager.save(JSON.stringify(state))
        return { state, login, logout, setPreferences, setUser, setResources, replaceState, switchProfile }
    }, [state, login, logout, setPreferences, setUser, setResources, replaceState, switchProfile]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
