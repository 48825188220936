import styled from "styled-components";
import OrderSummary from "./OrderSummary";
import { useApi } from "../../hooks/use-api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Group } from "../../layout/Group/Group";
import { AuthContext } from "../../context/auth";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../components/Loading/Loading";
import { Arrow90degDown } from "react-bootstrap-icons";
import SubscriptionFeatures from "./SubscriptionFeatures";
import Currency from "../../components/Currency/Currency";
import { Switch } from "../../components/Form/Switch/Switch";
import { useConsoleLog } from "../../hooks/use-console-log.hook";
import { useNotification } from "../../hooks/use-notification.hook";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, OutlineButton } from "../../components/Form/Button";
import { useRouteResolver } from "../../hooks/use-route-resolver.hook";
import { boxShadowSmaller, forPhoneOnly, growSmaller } from "../../elements";
import { Pricings, ProductSubscriptionDurations, ProductTypes, SubscriptionPlan } from "shared-library";


const StyledGroup = styled(Group)`
    padding: 20px;
    .title-group {
        padding: 60px 0;
        text-align: center;
        h1 {
            letter-spacing: 4px;
            text-transform: uppercase;
            color: ${props => props.theme.colors.black};
        }

        h5 {
            font-weight: 300;
            color: ${props => props.theme.colors.gray};
        }
    }

    .billing-period-switch {
        position: relative;
        small {
            margin-top: 14px;
            color: ${props => props.theme.colors.gray};
        }

        .off-period {
            position: absolute;
            top: -26px;
            right: -70px;
            ${forPhoneOnly("right: -64px;")}
            small {
                font-size: 10px;
                font-weight: 700;
                color: ${props => props.theme.colors.success};

                &:nth-child(2) {
                    margin-top: 8px;
                }
            }
        }
    }

    .plan-wrapper {
        padding: 40px 20px;
        ${growSmaller}
        min-width: 250px;
        max-width: 250px;
        position: relative;
        border: 1px solid ${props => props.theme.colors.gray10};

        .placeholder {
            visibility: hidden;
        }

        &.popular {
            .popular-label {
                top: 0px;
                left: 32%;
                position: absolute;
                padding: 2px 20px;
                font-size: 10px;
                text-transform: uppercase;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                color: ${props => props.theme.colors.white};
                background-color: ${props => props.theme.colors.success};
            }
        }

        .plan-name {
            font-weight: 700;
            text-transform: uppercase;
            color: ${props => props.theme.colors.gray};
        }

        .price {
            padding: 20px 0;
            color: ${props => props.theme.colors.success};
            span {
                font-size: 28px;
                font-weight: 700;
            }

            small {
                font-size: 10px;
                font-weight: 700;
                ${boxShadowSmaller()}
                padding: 2px 8px;
                background-color: ${props => props.theme.colors.success10};
            }
        }

        &.gold {
            border-color: ${props => props.theme.colors.secondary};
            background-color: ${props => props.theme.colors.secondary10};

            .price {
                color: ${props => props.theme.colors.secondary};
                small {
                    background-color: ${props => props.theme.colors.secondary10};
                }
            }
        }

        .old-price {
            margin-top: -14px;
            color: ${props => props.theme.colors.gray};
            text-decoration: line-through;
        }

        .billing-period {
            color: ${props => props.theme.colors.gray};
        }

        button {
            text-transform: uppercase;
        }
    }

`;


const Pricing = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { log } = useConsoleLog();
    const { notify } = useNotification();
    const { dashboard } = useRouteResolver();
    const [pricing, setPricing] = useState<Pricings>();
    const { getPricings, refreshSessionDetails } = useApi();
    const { state, setPreferences } = useContext(AuthContext);
    const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false);
    const [billingPeriod, setBillingPeriod] = useState<ProductSubscriptionDurations>(ProductSubscriptionDurations.YEAR);

    const { isLoading } = useQuery({
        queryFn: getPricings,
        queryKey: ["pricing"],
        enabled: !state.resources.pricing
    });
    log(state.resources.pricing)
    useEffect(() => {
        if (state.preferences.whereILeftOff.beforeAuthFlow.payment) {
            getPlan(state.preferences.whereILeftOff.beforeAuthFlow.payment.pricing)
            const { payment, ...rest } = state.preferences.whereILeftOff.beforeAuthFlow
            if (payment.pricing.benefit.duration) {
                setBillingPeriod(payment.pricing.benefit.duration.in)
            }
            setPreferences({ ...state.preferences, whereILeftOff: { ...state.preferences.whereILeftOff, beforeAuthFlow: { ...rest } } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.preferences])

    const preparePricings = useCallback(() => {
        if (!state.resources.pricing) return []
        const pricings: Pricings[] = [ProductSubscriptionDurations.MONTH, ProductSubscriptionDurations.YEAR].map(_in => ({
            id: "",
            price: 0,
            priceRank: 0,
            onSale: true,
            title: "Free",
            productRank: 0,
            benefit: {
                duration: {
                    unit: 1,
                    in: _in,
                }
            },
            oldPrice: 0,
            unitPrice: 0,
            productId: "",
            isPopular: false,
            isRecurring: true,
            productCode: "free",
            productType: ProductTypes.Recurring,
            currency: state.resources.pricing?.pricings.length ? state.resources.pricing.pricings[0].currency : "USD",
        }))
        if (state.user?.subscription.activeSubscription) {
            return [...state.resources.pricing.pricings]
        }
        return [...pricings, ...state.resources.pricing.pricings]
    }, [state.resources.pricing, state.user?.subscription.activeSubscription])

    const getPlan = async (pricing: Pricings) => {

        if (!state.user) {
            if (!pricing.price) {
                //Signing up for free plan
                navigate("/auth/sign-in")
                return
            }
            //Wants to buy but not signed in
            setPreferences({ ...state.preferences, whereILeftOff: { ...state.preferences.whereILeftOff, beforeAuthFlow: { ...state.preferences.whereILeftOff.beforeAuthFlow, payment: { pricing } } } })
            navigate("/auth/sign-in")
            return
        }

        if (state.user.subscription.activeSubscription && state.user.subscription.activeSubscription.productRank === pricing.productRank && state.user.subscription.activeSubscription.priceRank === pricing.priceRank) {
            //Clicked on current subscription
            return
        }
        if (!state.user.subscription.activeSubscription && pricing.productCode === "free") {
            navigate(dashboard())
            return
        }

        setPricing(pricing)
        setOffCanvasIsOpen(true)
    }

    const onDone = () => {
        notify({ type: "success", title: t('success'), body: t('paymentSuccessful') });
        setOffCanvasIsOpen(false);
        refreshSessionDetails();
        navigate(dashboard());
    }

    return (
        <StyledGroup className="container-fluid" gap="none">
            {
                pricing && <OrderSummary offCanvasIsOpen={offCanvasIsOpen} setOffCanvasIsOpen={setOffCanvasIsOpen} pricing={pricing} onDone={onDone} />
            }
            <Group className="container title-group" align="center" justify="center" gap="xs">
                <h1>{t('pricingPlansBuildWebsitesYourWay')}</h1>
                <h5>{t('atDIYWebsiteBuilderWeveCreatedPricingPlans')}</h5>
            </Group>
            <Group gap="md" align="center" className="container">
                <Group direction="row" align="center" className="billing-period-switch">
                    <small>{t('billedMonthly')}</small>
                    <Switch
                        value={billingPeriod === ProductSubscriptionDurations.YEAR}
                        onChange={checked => setBillingPeriod(checked ? ProductSubscriptionDurations.YEAR : ProductSubscriptionDurations.MONTH)}></Switch>
                    <small>{t('billedYearly')}</small>
                    <Group direction="row" gap="xs" align="center" className="off-period" wrap="nowrap">
                        <small><Arrow90degDown /></small>
                        <small dangerouslySetInnerHTML={{ __html: t('upToTwoMonthsOff') }}></small>
                    </Group>
                </Group>
                <Group gap="sm" direction="row">
                    {
                        isLoading ? <Loading /> : <>
                            {
                                preparePricings().filter(({ benefit }) => benefit.duration?.in === billingPeriod).map((pricing, index) => <Group className={`plan-wrapper ${pricing.isPopular ? "popular" : ""} ${pricing.productCode}`} gap="md" key={index}>
                                    {
                                        pricing.isPopular && <span className="popular-label">{t('popular')}</span>
                                    }
                                    <Group gap="none" align="center">
                                        <small className="plan-name">{pricing.title}</small>
                                        <Group direction="row" wrap="nowrap" className="price" align="start" gap="none">
                                            <span>
                                                <Currency
                                                    quantity={pricing.unitPrice}
                                                    currency={pricing.currency}
                                                />
                                            </span>
                                            {
                                                !!pricing.price && pricing.benefit.duration?.in === ProductSubscriptionDurations.YEAR && <small>{t('20PercentOff')}</small>
                                            }
                                        </Group>
                                        {
                                            pricing.oldPrice > pricing.unitPrice ? <h4 className="old-price">
                                                <Currency
                                                    quantity={pricing.oldPrice}
                                                    currency={pricing.currency}
                                                />
                                            </h4> : <h4 className="old-price placeholder">{pricing.price}</h4>
                                        }

                                        <small className="billing-period">{t(billingPeriod === ProductSubscriptionDurations.MONTH ? 'billedMonthly' : 'billedYearly')}</small>
                                        {
                                            (pricing.isPopular || pricing.productCode === "gold") ? <Button disabled={state.user?.subscription.activeSubscription && state.user.subscription.activeSubscription.productRank === pricing.productRank && state.user?.subscription.activeSubscription.priceRank === pricing.priceRank} onClick={() => getPlan(pricing)} color={pricing.productCode === "gold" ? "secondary" : "success"} size="sm" text={t(state.user ? ((state.user.subscription.activeSubscription?.priceRank === pricing.priceRank && state.user.subscription.activeSubscription?.productRank === pricing.productRank) ? 'current' : (!state.user.subscription.activeSubscription || state.user.subscription.activeSubscription?.productRank < pricing.productRank || state.user.subscription.activeSubscription.priceRank < pricing.priceRank ? 'upgrade' : 'downgrade')) : 'getPlan')} /> :
                                                <OutlineButton disabled={state.user?.subscription.activeSubscription && state.user.subscription.activeSubscription.productRank === pricing.productRank && state.user?.subscription.activeSubscription.priceRank === pricing.priceRank} onClick={() => getPlan(pricing)} color="success" size="sm" text={pricing.productCode === "free" ? t(state.user ? (state.user.subscription.activeSubscription ? 'downgrade' : 'current') : 'getStartedForFree') : t(state.user ? ((state.user.subscription.activeSubscription?.priceRank === pricing.priceRank && state.user.subscription.activeSubscription?.productRank === pricing.productRank) ? 'current' : (!state.user.subscription.activeSubscription || state.user.subscription.activeSubscription?.productRank < pricing.productRank || state.user.subscription.activeSubscription.priceRank < pricing.priceRank ? 'upgrade' : 'downgrade')) : 'getPlan')} />
                                        }
                                    </Group>
                                    <SubscriptionFeatures subscription={state.resources.pricing!.subscriptionBenefits[pricing.productCode as SubscriptionPlan]} />
                                </Group>)
                            }
                        </>
                    }
                </Group>
            </Group>

        </StyledGroup>
    )
}
export default Pricing;