import { useState } from "react";
import Moment from "react-moment";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../hooks/use-api";
import Card from "../../../components/Card/Card";
import { Group } from "../../../layout/Group/Group";
import { useForm } from "../../../hooks/use-form.hook";
import { Button } from "../../../components/Form/Button";
import Loading from "../../../components/Loading/Loading";
import { useNotify } from "../../../hooks/use-notify.hook";
import Currency from "../../../components/Currency/Currency";
import { useMutation, useQuery } from "@tanstack/react-query";
import OffCanvas from "../../../components/OffCanvas/OffCanvas";
import { Switch } from "../../../components/Form/Switch/Switch";
import { useNotification } from "../../../hooks/use-notification.hook";
import { GetFilters } from "../../../utils/types/getFilters.interface";
import { TextInput } from "../../../components/Form/TextInput/TextInput";
import { DateTimeInput } from "../../../components/Form/DateTimeInput/DateTimeInput";
import { CouponInterface, CouponTypes } from "../../../utils/types/coupon.interface";
import { ArrowClockwise, Keyboard, Pencil, Percent, PlusLg, Search, Trash } from "react-bootstrap-icons";
import { currenciesPrepped, DropdownValues, SelectInput } from "../../../components/Form/Select/SelectInput";
import { addToDate, dateFormat, getMomentFunc, isEmpty, ProductInterface, randomID, Status, timeExpired, today } from "shared-library";
import { Divider, forPhoneOnly, StyledIsRequired, StyledSuccess, StyledSwitchContainer, StyledTableWrapper, StyledTitle, StyledTitleDesc } from "../../../elements";

const StyledGroup = styled(Group)`
    padding: 20px;
    
    .add-container {
        cursor: pointer;
        font-size: 14px;
        padding: 80px 40px;
        letter-spacing: 4px;
        text-transform: uppercase;
        ${forPhoneOnly("padding: 40px 15px;")}
        color: ${props => props.theme.colors.gray};
    }

    .search-input {
        width: 350px;
    }

    .bar {

        .navLink {
            font-size: 12px;
            cursor: pointer;
            padding-bottom: 4px;
            transition: all 0.5s;
            text-transform: uppercase;
            border-bottom: 1px solid transparent;
            color: ${props => props.theme.colors.black};

            &.active {
                border-bottom-color: ${props => props.theme.colors.secondary};
            }
        }
    }

    .data-container {
        padding: 0px 10px;
    }
`;

const StyledCouponGroup = styled(Group)`
    .coupon-input-group {
        height: 46px;
        min-width: 40px;
        border-radius: 2px;
        color: ${props => props.theme.colors.gray};
        background-color: ${props => props.theme.colors.gray10};
    }

    .add-more {
        font-size: 12px;
        cursor: pointer;
        font-weight: bold;
        color: ${props => props.theme.colors.success};
    }

    .discount-switch {
        small {
            padding-top: 16px;
        }
    }
`;
interface CouponsProps {
    codeType: CouponTypes;
}
interface CouponsGetProps extends GetFilters {
    codeType: CouponTypes;
}
const DELIMITER = ",";

const Coupons = ({ codeType }: CouponsProps) => {

    const { confirm } = useNotify();
    const { t } = useTranslation();
    const { notify } = useNotification();
    const { getCoupons, deleteCoupon } = useApi();
    const [filters, setFilters] = useState<CouponsGetProps>({
        page: 1,
        codeType,
        search: "",
        status: Status.active
    });
    const { refetch, data, isLoading } = useQuery({
        staleTime: Infinity,
        queryFn: () => getCoupons({ ...filters, codeType }),
        queryKey: [`${codeType}s`, filters.status, filters.page],
    });
    const [deleting, setDeleting] = useState<string[]>([]);
    const getBlankCoupon = (): CouponInterface => ({
        id: "",
        tnc: "",
        codeType,
        title: "",
        maxUsedBy: 0,
        clientId: "",
        currency: "",
        usedCounter: 0,
        creationDate: "",
        maxUsedPerUser: 1,
        startDate: today(),
        status: Status.active,
        validForProductPrices: [],
        discountAmountInPercentage: true,
        endDate: addToDate(today(), 1, "month", dateFormat),
        discountAmount: codeType === CouponTypes.VOUCHER ? 100 : 5,
        code: randomID(codeType === CouponTypes.DISCOUNT ? 12 : 24).toUpperCase(),
    })
    const [editingCoupon, setEditingCoupon] = useState<CouponInterface>();
    const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false);
    const { mutateAsync: deleteCouponAsync } = useMutation({
        mutationFn: deleteCoupon
    })

    const deleteCouponLocal = async (datum: CouponInterface) => {
        setDeleting([...deleting, datum.id])
        if (await deleteCouponAsync({ codeType: datum.codeType, code: datum.code })) {
            refetch()
            notify({ type: "success", title: t('success'), body: t('couponDeletedSuccessfully') });
            return
        }
        setDeleting(deleting.filter(id => id !== datum.id))
    }

    return (
        <StyledGroup className="container-fluid">
            {
                editingCoupon && offCanvasIsOpen && <CouponCreatorEditor offCanvasIsOpen={offCanvasIsOpen} coupon={editingCoupon} onDone={refetch} setOffCanvasIsOpen={setOffCanvasIsOpen} />
            }
            <Group gap="md" className="container">
                <Group direction="row">
                    <Card className="add-container" onClick={() => { setEditingCoupon(getBlankCoupon()); setOffCanvasIsOpen(true); }}>
                        <Group direction="row" wrap="nowrap">
                            <PlusLg size={18} />
                            {t('newCode')}
                        </Group>
                    </Card>
                </Group>
                <Group direction="row" justify="space-between" className="bar">
                    <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); refetch() }}>
                        <TextInput
                            type="text"
                            className="search-input"
                            placeholder={t('search')}
                            value={filters.search || ""}
                            leftIcon={<Search size={18} />}
                            onChange={search => setFilters({ ...filters, search: search as string })}
                        />
                    </form>
                    <Group direction="row" align="center" gap="sm" justify="center">
                        {
                            ([Status.active, Status.inactive]).map(status => <small key={status} onClick={() => setFilters({ ...filters, status })} className={"navLink" + (filters.status === status ? ' active' : '')}>{t(status)}</small>)
                        }
                    </Group>
                </Group>

                <Group className="data-container" gap="md">
                    {
                        !data || isLoading ? <Loading /> : <StyledTableWrapper>
                            <table>
                                <thead>
                                    <tr>
                                        <th align="left">{t('title')}</th>
                                        <th align="left">{t('code')}</th>
                                        <th align="left">{t('status')}</th>
                                        <th align="left">{t('actions')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.data.map(datum => <tr key={datum.id} className={deleting.includes(datum.id) ? "deleting" : ""}>
                                            <td>{datum.title}</td>
                                            <td>
                                                <div><b>{datum.code}</b></div>
                                                {
                                                    datum.currency && <div>{t('currency')}:
                                                        <b>{datum.currency}</b>
                                                    </div>
                                                }
                                                <div>
                                                    {t('amount')}:
                                                    {
                                                        datum.discountAmountInPercentage ? <b>{datum.discountAmount}<Percent size={12} /> {t('off').toLowerCase()}</b> : <b><Currency quantity={datum.discountAmount} currency={datum.currency} /> {t('off').toLowerCase()}</b>
                                                    }
                                                </div>
                                                <div>
                                                    {t('creationDate')}:
                                                    <b>
                                                        <Moment format="MMM Do, YYYY">{datum.creationDate}</Moment>
                                                    </b>
                                                </div>
                                                <div>{t('duration')}:
                                                    <b>
                                                        <Moment format="MMM Do, YYYY">{datum.startDate}</Moment> - <Moment format="MMM Do, YYYY">{datum.endDate}</Moment>
                                                    </b>
                                                </div>
                                            </td>
                                            <td>
                                                <small>
                                                    <b>
                                                        {
                                                            datum.status === Status.active ?
                                                                <StyledSuccess>{t(datum.status)}</StyledSuccess> :
                                                                <StyledIsRequired>{t(datum.status)}</StyledIsRequired>
                                                        }
                                                    </b>
                                                </small>
                                                <div>{t('usageCount')}: <b>{datum.usedCounter}</b></div>
                                                <div>{t('limit')}: <b>{datum.maxUsedBy || t('unlimited')}</b></div>
                                                <div>{t('limitPerUser')}: <b>{datum.maxUsedPerUser || t('unlimited')}</b></div>
                                                <div>{t('validOnProducts')}: <b>{datum.validForProductPrices.length || t('all')}</b></div>
                                            </td>
                                            <td>
                                                <Group direction="row" gap="xs" wrap="nowrap">
                                                    <Pencil size={16} className="pointer" onClick={() => { setEditingCoupon(datum); setOffCanvasIsOpen(true); }} />
                                                    {
                                                        !datum.usedCounter && <StyledIsRequired className="pointer">
                                                            <Trash size={16} className="pointer trash-icon" onClick={() => confirm({
                                                                onConfirmation: () => deleteCouponLocal(datum)
                                                            })} />
                                                        </StyledIsRequired>
                                                    }
                                                </Group>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </StyledTableWrapper>
                    }
                </Group>

            </Group>
        </StyledGroup>
    )
}

export default Coupons;

interface CouponCreatorEditorProps {
    onDone?: () => void;
    coupon: CouponInterface;
    offCanvasIsOpen: boolean;
    setOffCanvasIsOpen: (v: boolean) => void;
}
const CouponCreatorEditor = ({
    coupon,
    offCanvasIsOpen,
    setOffCanvasIsOpen,
    onDone = () => null
}: CouponCreatorEditorProps) => {

    const { t } = useTranslation();
    const { notify } = useNotification();
    const { createCoupon, getProducts, updateCoupon } = useApi();
    const [codes, setCodes] = useState<string[]>([coupon.code]);
    const { onSubmit, onChange, errors, formValues, reset, setErrors } = useForm({
        defaultValues: coupon,
        validations: {
            title: { required: true },
            endDate: { required: true },
            startDate: { required: true },
            maxUsedBy: { required: true },
            maxUsedPerUser: { required: true },
            discountAmount: { required: true, min: 1 },
        }
    })

    const { isPending: isCreatingCoupon, mutateAsync: createCouponAsync } = useMutation({
        mutationFn: createCoupon
    })

    const { isPending: isUpdatingCoupon, mutateAsync: updateCouponAsync } = useMutation({
        mutationFn: updateCoupon
    })

    const { data: products, isLoading: fetchingProducts } = useQuery({
        staleTime: Infinity,
        queryFn: getProducts,
        queryKey: ["adminProducts"],
    });

    const productsPrepped = (products: Array<ProductInterface>) => {
        const response: Array<DropdownValues> = [];
        for (const product of products) {
            for (const price of product.prices) {
                if (formValues.currency && price.currency !== formValues.currency) continue;
                response.push({
                    value: [product.id, price.id].join(DELIMITER),
                    label: `${product.title} - ${price.title}`,
                });
            }
        }
        return response;
    };

    const startCreateCoupon = async () => {

        try {
            if (formValues.discountAmountInPercentage && formValues.discountAmount > 100) {
                setErrors(error => ({ ...error, discountAmount: t('discountAmountShouldBeLessThan', { value: 100 }) }))
                return
            }
            if (coupon.codeType === CouponTypes.VOUCHER) {
                if (formValues.validForProductPrices.length !== 1) {
                    setErrors(error => ({ ...error, validForProductPrices: t('productCannotBeEmpty') }))
                    return
                }
            }
            if (coupon.codeType === CouponTypes.DISCOUNT) {
                if (formValues.discountAmountInPercentage && formValues.discountAmount > 95) {
                    setErrors(error => ({ ...error, discountAmount: t('discountAmountShouldBeLessThan', { value: 96 }) }))
                    return
                }
                if (!formValues.discountAmountInPercentage && !formValues.currency) {
                    setErrors(error => ({ ...error, currency: t('currencyIsRequired', { value: 96 }) }))
                    return
                }
            }
            if (codes.find(c => isEmpty(c))) {
                notify({ type: "error", title: t('inputError'), body: t('codeCannotBeEmpty') });
                return
            }
            if (timeExpired(formValues.endDate, getMomentFunc(formValues.startDate))) {
                setErrors(error => ({ ...error, endDate: t('endDateIsEarlierThanStartDate'), startDate: t('endDateIsEarlierThanStartDate') }))
                return
            }
            await Promise.all(codes.map(code => coupon.id ? updateCouponAsync({ ...formValues, code, tnc: formValues.tnc || "" }) : createCouponAsync({ ...formValues, code, tnc: formValues.tnc || "" })))
            reset()
            setCodes([randomID(coupon.codeType === CouponTypes.DISCOUNT ? 12 : 24).toUpperCase()])
            setOffCanvasIsOpen(false)
            notify({ type: "success", title: t('success'), body: t('couponListSuccessfullyUpdated') });
            onDone()
        } catch (error) {

        }

    };

    const updateProductIds = (values: DropdownValues[]) => {
        const valueToUpdate: { productId: string; productPriceId: string; }[] = []
        for (const value of values) {
            const arr = value.value.split(DELIMITER).filter(d => d)
            if (arr.length === 2) {
                valueToUpdate.push({
                    productId: arr[0],
                    productPriceId: arr[1],
                })
            }
        }
        onChange("validForProductPrices")(valueToUpdate)
    }

    const updateProductId = (value: string) => {
        let valueToUpdate: { productId: string; productPriceId: string; }[] = []
        const arr = value.split(DELIMITER).filter(d => d)
        if (arr.length === 2) {
            valueToUpdate = [{
                productId: arr[0],
                productPriceId: arr[1],
            }]
        }
        onChange("validForProductPrices")(valueToUpdate)
    }

    const prepareProductIdValues = (): DropdownValues[] => {
        const values: DropdownValues[] = []
        if (products) {
            for (const product of products.data) {
                for (const price of product.prices) {
                    if (formValues.validForProductPrices.find(({ productId, productPriceId }) => productId === product.id && productPriceId === price.id)) {
                        values.push({
                            value: [product.id, price.id].join(DELIMITER),
                            label: `${product.title} - ${price.title}`,
                        });
                    }
                }
            }
        }
        return values
    }

    const prepareProductIdValue = (): string => {
        if (products) {
            for (const product of products.data) {
                for (const price of product.prices) {
                    if (formValues.validForProductPrices.find(({ productId, productPriceId }) => productId === product.id && productPriceId === price.id)) {
                        return [product.id, price.id].join(DELIMITER)
                    }
                }
            }
        }
        return ""
    }

    return (
        <OffCanvas
            allowScroll={false}
            backdrop={true}
            isOpen={offCanvasIsOpen}
            title={t(!coupon.id ? `new${coupon.codeType}` : `edit${coupon.codeType}`)}
            handleClose={() => setOffCanvasIsOpen(false)}
        >
            {
                !products || fetchingProducts ? <Loading /> : <form onSubmit={onSubmit(startCreateCoupon)}>
                    <StyledCouponGroup justify="space-between" style={{ minHeight: '88vh', overflowX: 'hidden' }}>
                        <Group style={{ flexGrow: 1 }}>
                            <TextInput
                                isRequired
                                type="text"
                                label={t('title')}
                                error={errors.title}
                                placeholder={t('title')}
                                value={formValues.title}
                                onChange={onChange("title")}
                                leftIcon={<Keyboard size={16} />}
                            />
                            <Group gap="xs">
                                {
                                    codes.map((code, index) => <Group key={index} direction="row" gap="xs" className="coupon-row" align="center" wrap="nowrap">
                                        <Group justify="center" align="center" className="coupon-input-group">{index + 1}.</Group>
                                        <TextInput
                                            isRequired
                                            type="text"
                                            value={code}
                                            readonly={!!coupon.id}
                                            placeholder={t(coupon.codeType)}
                                            parentStyle={{ flexGrow: 1 }}
                                            onChange={v => setCodes(codes.map((code, _index) => index === _index ? (v as string).toUpperCase() : code))}
                                        />
                                        {
                                            !coupon.id && <>
                                                <Group onClick={() => setCodes(codes.map((code, _index) => index === _index ? randomID(coupon.codeType === CouponTypes.DISCOUNT ? 12 : 24).toUpperCase() : code))} justify="center" align="center" className="coupon-input-group pointer">
                                                    <ArrowClockwise />
                                                </Group>
                                                {
                                                    codes.length > 1 && <Group onClick={() => setCodes(codes.filter((_, _index) => index !== _index))} justify="center" align="center" className="coupon-input-group pointer">
                                                        <StyledIsRequired>
                                                            <Trash />
                                                        </StyledIsRequired>
                                                    </Group>
                                                }
                                            </>
                                        }
                                    </Group>)
                                }
                                {
                                    !coupon.id && codes.length <= 50 && <Group direction="row" gap="xs" align="center" wrap="nowrap" className="add-more pointer" onClick={() => setCodes([...codes, randomID(coupon.codeType === CouponTypes.DISCOUNT ? 12 : 24).toUpperCase()])}>
                                        <PlusLg />
                                        {t('addMoreCodes')}
                                    </Group>
                                }

                            </Group>

                            <Group direction="row" gap="xs" wrap="nowrap">
                                <DateTimeInput
                                    isRequired={true}
                                    showYearDropdown
                                    format={dateFormat}
                                    label={t('startDate')}
                                    error={errors.startDate}
                                    placeholder={t('startDate')}
                                    value={formValues.startDate}
                                    parentStyle={{ width: "44%" }}
                                    onChange={onChange("startDate")}
                                    minDate={getMomentFunc().toDate()}
                                />
                                <DateTimeInput
                                    isRequired={true}
                                    showYearDropdown
                                    format={dateFormat}
                                    label={t('endDate')}
                                    error={errors.endDate}
                                    placeholder={t('endDate')}
                                    value={formValues.endDate}
                                    parentStyle={{ flexGrow: 1 }}
                                    onChange={onChange("endDate")}
                                    minDate={getMomentFunc(formValues.startDate || today()).toDate()}
                                />
                            </Group>

                            {
                                coupon.codeType !== CouponTypes.VOUCHER ? <>

                                    <StyledSwitchContainer direction="row" justify="space-between" align="start" wrap="nowrap">
                                        <Group gap="none">
                                            <StyledTitle>{t('discountInPercentage')}</StyledTitle>
                                            <StyledTitleDesc>{t('leaveOffForFlatRate')}</StyledTitleDesc>
                                        </Group>
                                        <Switch
                                            value={formValues.discountAmountInPercentage}
                                            onChange={onChange("discountAmountInPercentage")}></Switch>
                                    </StyledSwitchContainer>

                                    <Group direction="row" gap="xs" align="end" wrap="nowrap">
                                        <SelectInput
                                            isRequired={!formValues.discountAmountInPercentage}
                                            searchable={false}
                                            parentStyle={{ width: "48%" }}
                                            clearable={formValues.discountAmountInPercentage}
                                            label={t('currency')}
                                            error={errors.currency}
                                            closeMenuOnSelect={true}
                                            value={formValues.currency}
                                            placeholder={t('currency')}
                                            options={currenciesPrepped()}
                                            onChange={onChange("currency")}
                                        />
                                        <TextInput
                                            isRequired
                                            type="number"
                                            label={t('discountAmount')}
                                            parentStyle={{ flexGrow: 1 }}
                                            error={errors.discountAmount}
                                            placeholder={t('discountAmount')}
                                            onChange={onChange("discountAmount")}
                                            value={`${formValues.discountAmount}`}
                                        />
                                        <Group justify="center" align="center" className="coupon-input-group">
                                            {
                                                formValues.discountAmountInPercentage ? <Percent /> : <small>{formValues.currency}</small>
                                            }
                                        </Group>
                                    </Group>

                                    <Group direction="row" gap="xs" wrap="nowrap">
                                        <TextInput
                                            isRequired
                                            type="number"
                                            label={t('maxUsedBy')}
                                            tooltip={t('overallLimitUsage')}
                                            parentStyle={{ width: "44%" }}
                                            error={errors.maxUsedBy}
                                            placeholder={t('maxUsedBy')}
                                            onChange={onChange("maxUsedBy")}
                                            value={`${formValues.maxUsedBy}`}
                                        />
                                        <TextInput
                                            isRequired
                                            type="number"
                                            label={t('maxUsedPerUser')}
                                            parentStyle={{ flexGrow: 1 }}
                                            error={errors.maxUsedPerUser}
                                            placeholder={t('maxUsedPerUser')}
                                            onChange={onChange("maxUsedPerUser")}
                                            value={`${formValues.maxUsedPerUser}`}
                                            tooltip={t('howManyTimeIsItReusablePerUser')}
                                        />
                                    </Group>
                                    <SelectInput
                                        isMulti={true}
                                        onChange={updateProductIds}
                                        label={t('validForProducts')}
                                        value={prepareProductIdValues()}
                                        placeholder={t('validForProducts')}
                                        error={errors.validForProductPrices}
                                        options={productsPrepped(products.data)}
                                        tooltip={t('whatProductShouldCodeBeValidFor')}
                                    />
                                </> : <SelectInput
                                    isMulti={false}
                                    isRequired={true}
                                    closeMenuOnSelect={true}
                                    onChange={updateProductId}
                                    label={t('validForProducts')}
                                    value={prepareProductIdValue()}
                                    placeholder={t('validForProducts')}
                                    error={errors.validForProductPrices}
                                    options={productsPrepped(products.data)}
                                />
                            }

                            {
                                coupon.id ? (
                                    <SelectInput
                                        isRequired={true}
                                        searchable={false}
                                        label={t('status')}
                                        error={errors.status}
                                        closeMenuOnSelect={true}
                                        placeholder={t('status')}
                                        onChange={onChange("status")}
                                        value={formValues.status as any}
                                        options={[Status.active, Status.inactive].map(status => ({ label: t(status), value: status }))}
                                    />
                                ) : null
                            }

                        </Group>
                        <Divider></Divider>
                        <Button text={t(!coupon.id ? 'create' : 'update')} type="submit" disabled={isCreatingCoupon || isUpdatingCoupon} loading={isCreatingCoupon || isUpdatingCoupon} />
                    </StyledCouponGroup>
                </form>
            }

        </OffCanvas>
    )
}
