"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsonToQueryString = void 0;
const lodash_1 = require("lodash");
const jsonToQueryString = (json, prefix = "?") => {
    if (!json || !(0, lodash_1.isObject)(json) || (0, lodash_1.isEmpty)(json))
        return '';
    const queryString = Object.keys(json)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
        .join('&');
    return queryString ? `${prefix}${queryString}` : "";
};
exports.jsonToQueryString = jsonToQueryString;
