import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ErrorMeaningEN } from 'shared-library';
const en = require('./locales/en.json');
const fr = require('./locales/fr.json');

i18n
    .use(initReactI18next) // passes i18n down to react-i18next   

    .init({
        resources: {
            en: {
                translation: { ...en, ...ErrorMeaningEN }
            },
            fr: {
                translation: { ...fr }
            }
        },
        lng: 'en', // default language
        fallbackLng: 'en', // fallback language
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;