"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseURL = exports.getAssetURL = void 0;
const getAssetURL = (environment) => {
    const environments = {
        Prod: "https://assets.diy-website-builder.com/",
        SandBox: "https://sandbox-assets.diy-website-builder.com/",
    };
    return environments[environment];
};
exports.getAssetURL = getAssetURL;
const getBaseURL = (environment) => {
    const environments = {
        Prod: "https://api.diy-website-builder.com/v1/",
        SandBox: "https://sandbox-api.diy-website-builder.com/v1/",
    };
    return environments[environment];
};
exports.getBaseURL = getBaseURL;
