"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.forceDecimalPlace = exports.toFixed = void 0;
const toFixed = (num, fixed = 2) => {
    return +(parseFloat(`${num}`).toFixed(fixed));
};
exports.toFixed = toFixed;
const forceDecimalPlace = (num, fixed = 2) => {
    let _num = num.toString().split(".");
    let _decimals = "";
    for (let index = 0; index < fixed; index++)
        _decimals += "0";
    if (_num.length === 1) {
        // No decimal
        return _decimals ? `${_num[0]}.${_decimals}` : _num[0];
    }
    if (_num[1].length === fixed) {
        // No changes needed
        return `${num}`;
    }
    if (_num[1].length < fixed) {
        // Pad the missing decimal
        let _decimals = "";
        for (let index = 0; index < fixed - _num[1].length; index++)
            _decimals += "0";
        return `${num}${_decimals}`;
    }
    return `${(0, exports.toFixed)(num, fixed)}`;
};
exports.forceDecimalPlace = forceDecimalPlace;
