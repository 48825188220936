import { AuthContext } from "../auth";
import { useTranslation } from "react-i18next";
import { ReactNode, useContext, useMemo } from "react";
import onErrorHandler from "../../utils/onErrorHandler.util";
import { useConsoleLog } from "../../hooks/use-console-log.hook";
import { useNotification } from "../../hooks/use-notification.hook";
import { DefaultOptions, MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";

const defaultOptions: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: true,
    retry: 1,
  },
};

export const ReactQueryContextProvider = ({ children }: { children: ReactNode }) => {

  const { t } = useTranslation();
  const { log } = useConsoleLog();
  const { notify } = useNotification();
  const { logout } = useContext(AuthContext)

  const queryClient = useMemo(() => {
    const onError = (e: any) => {
      onErrorHandler({
        e,
        t,
        logout,
        log,
        notify,
      })
    };

    const queryCache = new QueryCache({ onError });
    const mutationCache = new MutationCache({ onError });

    return new QueryClient({ defaultOptions, queryCache, mutationCache });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout, notify]);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
