import { Spinner } from "./Button";
import styled from "styled-components";
import { CSSProperties, MouseEvent, ReactNode } from "react";
import { grow, growSmaller, keyboardFocusOnly } from "../../../elements";

interface ButtonProps {
    text?: string;
    icon?: ReactNode;
    loading?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    rightIcon?: ReactNode;
    size?: "sm" | "lg" | "md";
    type?: "button" | "submit";
    color?: "primary" | "danger" | "success";
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const StyledButton = styled.button`
    color: ${props => props.theme.colors.gray};
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.gray};

    &.sm {
        font-size: 12px;
        padding: 6px 14px;
        gap: 0.25rem;
        ${growSmaller}
    }

    &.md {
        padding: 0.8rem 2rem;
        min-height: 2.4rem;
        gap: 0.75rem;
        line-height: 1.2rem;
        ${grow}
    }

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    overflow-wrap: break-word;
    ${keyboardFocusOnly}

    &:hover {
        border-color: ${props => props.theme.colors.black};
        text-decoration: none;
    }
    
    &:active {
        transform: scale(0.99);
    }

    &.success {
        color: ${(props) => props.theme.colors.success};
        border-color: ${(props) => props.theme.colors.success};
    
        .icon {
            color: ${(props) => props.theme.colors.success};
        }
    }

    &.danger {
        background-color: ${(props) => props.theme.colors.danger};
    
        &:hover {
            background-color: ${(props) => props.theme.colors.danger};
        }
    }

    .text {
        overflow-wrap: break-word;
    }
    
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${props => props.theme.colors.black};
    }

    &:disabled {
        opacity: .5;
        cursor: not-allowed;
    
        &.danger {
          background-color: ${(props) => props.theme.colors.danger};
    
          &:hover {
            background-color: ${(props) => props.theme.colors.danger};
          }
        }

        &.success {
            color: ${(props) => props.theme.colors.success};
            .icon {
                color: ${(props) => props.theme.colors.success};
            }
          }
    
        &:active {
          transform: none;
        }
      }  
`

export const OutlineButton = (props: ButtonProps) => {
    const {
        text,
        icon,
        rightIcon,
        disabled = false,
        loading = false,
        color = "primary",
        style = {},
        size = "md",
        type = "button",
        onClick,
    } = props;

    const classNames = ["btn", color, size];

    return (
        <StyledButton
            className={classNames.join(" ")}
            onClick={onClick}
            disabled={disabled}
            style={style}
            type={type}
        >
            {loading && <Spinner />}
            {!loading && icon && <div className="icon">{icon}</div>}
            {!loading && <div className="text">{text}</div>}
            {!loading && rightIcon && <div className="icon">{rightIcon}</div>}
        </StyledButton>
    );
};
