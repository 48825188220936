import { useState } from "react";
import styled from "styled-components";
import styles from "./Switch.module.scss";
import { focus } from "../../../elements";
import { FormComponentProps } from "../../../utils/types/form-component-props";
import { InputContainer } from "../InputContainer/InputContainer";

interface CheckboxProps extends FormComponentProps {
  value: boolean;
  onChange?: (value: boolean) => void;
}

const StyledSwitchDiv = styled.div`
  &[data-focus="true"] {
    ${focus}
  }

  input {
    &:focus+.slider {
      box-shadow: 0 0 1px ${(props) => props.theme.colors.gray10};
    }
    &:checked+.slider {
        background-color: ${(props) => props.theme.colors.gray15};
    }
  }

  .slider {
    background-color: ${(props) => props.theme.colors.gray10};
    &:before {
      background-color: ${(props) => props.theme.colors.black};
    }
  }
`

export const Switch = (props: CheckboxProps) => {
  const { value, label, error, description, isRequired, onChange } = props;
  const [focus, setFocus] = useState(false);

  return (
    <InputContainer
      label={label as string}
      style={{ flexDirection: "row", gap: "2rem" }}
      description={description}
      error={error}
      isRequired={isRequired}
      render={(ID) => (
        <StyledSwitchDiv
          className={styles.switch}
          data-focus={focus}
          data-checked={!!value}
          data-testid="switch"
          onClick={() => onChange && onChange(!value)}
        >
          <div className={styles.container}>
            <input
              type="checkbox"
              id={ID}
              checked={!!value}
              onChange={() => onChange && onChange(!value)}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              className={styles.hiddenInput}
            />
            <span className={`${styles.slider} slider`}></span>
          </div>
        </StyledSwitchDiv>
      )}
    />
  );
};
