import { Config } from "../config";
import { useContext } from "react";
import { useFetch } from "./use-fetch";
import { AuthContext } from "../context/auth";
import { useConsoleLog } from "./use-console-log.hook";
import { TemplateRequest } from "../utils/types/template-request.interface";
import { CouponInterface, CouponTypes } from "../utils/types/coupon.interface";
import { AttemptPaymentResponse, Gateway, PaymentAttempt, SortDirections } from "../utils/types/paymentAttempt.interface";
import { RequestResponse, Template, UserDetails, UserConfirmationCodeType, ProjectDetails, Paginated, ProductInterface, ProductPriceInterface, PricingsResponse, UserBillingAddress, MobileNumber, jsonToQueryString, Status, MediaAsset, MediaAssetTypes, PresignedPolicy, ExtensionLibrary } from "shared-library";


interface ResetPasswordParams {
    code: string;
    email: string;
    password: string;
}

interface SignUpParams extends ResetPasswordParams {
    fullName: string;
}
export const useApi = () => {

    const { log } = useConsoleLog();
    const { get, remove, post, patch, put, uploadFile } = useFetch();
    const { state, setResources, replaceState, setPreferences } = useContext(AuthContext);

    const getSessionDetails = async ({ jwt }: { jwt: string; }): Promise<UserDetails> => {
        log(jwt)
        const { data } = await get<RequestResponse<UserDetails>>(`${Config.APIBaseUrl}auth/session`, {
            headers: {
                Authorization: `Bearer ${jwt}`,
                "Content-Type": "application/json",
            },
        });
        log({ getSessionDetails: data })
        return data
    }

    const refreshSessionDetails = async (): Promise<UserDetails | undefined> => {
        log(state)
        if (!state.user?.jwt) return;
        const { data } = await get<RequestResponse<UserDetails>>(`${Config.APIBaseUrl}auth/session`, {
            headers: {
                Authorization: `Bearer ${state.user.jwt}`,
                "Content-Type": "application/json",
            },
        });
        log({ refreshSessionDetails: data })
        replaceState({
            ...state,
            user: {
                ...state.user,
                ...data
            },
            preferences: {
                ...state.preferences,
                pages: {
                    ...state.preferences.pages,
                    orderSummary: {}
                }
            }
        })
        return data
    }

    const signUp = async (signUpParams: SignUpParams): Promise<boolean> => {
        const { data } = await post<RequestResponse<boolean>>(`${Config.APIBaseUrl}public/sign-up`, signUpParams);
        log({ signUp: data })
        return data.data
    }

    const resetPassword = async (resetPasswordParams: ResetPasswordParams): Promise<boolean> => {
        const { data } = await post<RequestResponse<boolean>>(`${Config.APIBaseUrl}public/password-reset`, resetPasswordParams);
        log({ resetPassword: data })
        return data.data
    }

    const sendAuthenticationCode = async (param: { email: string; codeType: UserConfirmationCodeType }): Promise<boolean> => {
        const { data } = await post<RequestResponse<boolean>>(`${Config.APIBaseUrl}public/confirmation-code`, param);
        log({ sendAuthenticationCode: data })
        return data.data
    }

    const getProducts = async (): Promise<Paginated<ProductInterface>> => {
        const { data } = await get<RequestResponse<Paginated<ProductInterface>>>(`${Config.APIBaseUrl}admin/products`);
        log({ getProducts: data })
        return data
    }

    const createProduct = async (param: { title: string; }): Promise<ProductInterface> => {
        const { data } = await post<RequestResponse<ProductInterface>>(`${Config.APIBaseUrl}admin/products`, param);
        log({ createProduct: data })
        return data.data
    }

    const updateProduct = async (param: ProductInterface): Promise<ProductInterface> => {
        const { data } = await patch<RequestResponse<ProductInterface>>(`${Config.APIBaseUrl}admin/products`, param);
        log({ updateProduct: data })
        return data.data
    }

    const createProductPrice = async (param: ProductPriceInterface): Promise<ProductPriceInterface> => {
        const { data } = await post<RequestResponse<ProductPriceInterface>>(`${Config.APIBaseUrl}admin/product-prices`, param);
        log({ createProductPrice: data })
        return data.data
    }

    const updateProductPrice = async (param: ProductPriceInterface): Promise<ProductPriceInterface> => {
        const { data } = await patch<RequestResponse<ProductPriceInterface>>(`${Config.APIBaseUrl}admin/product-prices`, param);
        log({ updateProductPrice: data })
        return data.data
    }

    const getTemplates = async (): Promise<Template[]> => {
        const { data } = await get<RequestResponse<Template[]>>(`${Config.APIBaseUrl}public/templates`);
        log({ getTemplates: data })
        return data
    }

    const getAdminTemplates = async (): Promise<Template[]> => {
        const { data } = await get<RequestResponse<Template[]>>(`${Config.APIBaseUrl}admin/templates`);
        setResources({ ...state.resources, adminTemplates: data });
        log({ getAdminTemplates: data })
        return data
    }

    const createProject = async ({ templateId = "", shortId = "" }: { templateId?: string; shortId?: string }): Promise<{ shortId: string; }> => {
        const { data } = await post<RequestResponse<{ shortId: string; }>>(`${Config.APIBaseUrl}user/projects?templateId=${templateId}&shortId=${shortId}`, {});
        log({ createProject: data })
        return data.data
    }

    const getProjects = async (): Promise<Paginated<ProjectDetails>> => {
        const { data } = await get<RequestResponse<Paginated<ProjectDetails>>>(`${Config.APIBaseUrl}user/projects`);
        setResources({ ...state.resources, projects: data });
        log({ getProjects: data })
        return data
    }

    const preparePayment = async (param: { productId: string; productPriceId: string; productCode: string; }): Promise<Array<PaymentAttempt>> => {
        const { productCode, ...rest } = param
        const { data } = await post<RequestResponse<Array<PaymentAttempt>>>(`${Config.APIBaseUrl}user/prepare-payment`, rest);
        setPreferences({ ...state.preferences, pages: { ...state.preferences.pages, orderSummary: { ...state.preferences.pages.orderSummary, [productCode]: data.data } } });
        log({ preparePayment: data })
        return data.data
    }

    const confirmPayment = async (param: { flutterwaveTransactionId: string; paymentAttemptId: string; provider: Gateway; customerId: string; billingAddress: UserBillingAddress; }): Promise<boolean> => {
        const { data } = await post<RequestResponse<boolean>>(`${Config.APIBaseUrl}user/verify-payment`, param);
        log({ confirmPayment: data })
        return data.data
    }

    const attemptPayment = async (param: { code?: string; paymentAttemptId: string; provider: Gateway; customerId: string; }): Promise<AttemptPaymentResponse> => {
        const { data } = await post<RequestResponse<AttemptPaymentResponse>>(`${Config.APIBaseUrl}user/attempt-payment`, param);
        log({ attemptPayment: data })
        return data.data
    }

    const applyDiscount = async (param: { paymentAttemptIds: string[]; code: string; customerId: string; }): Promise<Array<PaymentAttempt>> => {
        const { data } = await post<RequestResponse<Array<PaymentAttempt>>>(`${Config.APIBaseUrl}user/apply-discount`, param);
        log({ applyDiscount: data.data })
        return data.data
    }

    const removeDiscount = async (param: { paymentAttemptIds: string[]; code: string; customerId: string; }): Promise<Array<PaymentAttempt>> => {
        const { data } = await post<RequestResponse<Array<PaymentAttempt>>>(`${Config.APIBaseUrl}user/remove-discount`, param);
        log({ removeDiscount: data })
        return data.data
    }

    const voucherDetails = async (param: { mobile: MobileNumber; code: string; email: string; fullName: string; }): Promise<PaymentAttempt> => {
        const { data } = await post<RequestResponse<PaymentAttempt>>(`${Config.APIBaseUrl}user/voucher-details`, param);
        log({ voucherDetails: data })
        return data.data
    }

    const redeemVoucher = async (param: { paymentAttemptId: string; provider: Gateway; customerId: string; }): Promise<PaymentAttempt> => {
        const { data } = await post<RequestResponse<PaymentAttempt>>(`${Config.APIBaseUrl}user/redeem-voucher`, param);
        log({ redeemVoucher: data })
        return data.data
    }

    const getPricings = async (): Promise<PricingsResponse> => {
        const { data } = await get<RequestResponse<PricingsResponse>>(!state.user ? `${Config.APIBaseUrl}public/pricing` : `${Config.APIBaseUrl}user/pricing`);
        setResources({ ...state.resources, pricing: data });
        log({ getPricings: data })
        return data
    }

    const duplicateProject = async (shortId: string): Promise<{ shortId: string; }> => {
        const { data } = await get<RequestResponse<{ shortId: string; }>>(`${Config.APIBaseUrl}user/duplicate-project/${shortId}`);
        log({ duplicateProject: data })
        return data
    }

    const deleteProject = async (projectId: string): Promise<boolean> => {
        const { data } = await remove<RequestResponse<boolean>>(`${Config.APIBaseUrl}user/projects/${projectId}`);
        getProjects()
        log({ deleteProject: data })
        return data.data
    }

    const createAdminTemplate = async (newAdminTemplate: TemplateRequest): Promise<Template[]> => {
        const { data } = newAdminTemplate.id ? await patch<RequestResponse<Template[]>>(`${Config.APIBaseUrl}admin/templates/${newAdminTemplate.shortId}`, newAdminTemplate) : await post<RequestResponse<Template[]>>(`${Config.APIBaseUrl}admin/templates/${newAdminTemplate.shortId}`, newAdminTemplate);
        log({ createAdminTemplate: data })
        setResources({ ...state.resources, adminTemplates: data.data });
        return data.data
    }

    const duplicateAdminTemplate = async (shortId: string, newAdminTemplate: Template): Promise<Template[]> => {
        const { data } = await post<RequestResponse<Template[]>>(`${Config.APIBaseUrl}admin/duplicate-template/${shortId}`, { templateToDuplicateId: newAdminTemplate.id });
        log({ duplicateAdminTemplate: data })
        setResources({ ...state.resources, adminTemplates: data.data });
        return data.data
    }

    const deleteAdminTemplate = async (shortId: string): Promise<Template[]> => {
        const { data } = await remove<RequestResponse<Template[]>>(`${Config.APIBaseUrl}admin/templates/${shortId}`);
        log({ deleteAdminTemplate: data })
        setResources({ ...state.resources, adminTemplates: data.data });
        return data.data
    }

    const getCoupons = async (param: { codeType: CouponTypes; status?: Status; page?: number; limit?: number; search?: string; sort?: SortDirections; } = { codeType: CouponTypes.DISCOUNT }): Promise<Paginated<CouponInterface>> => {
        const { codeType, ...rest } = param;
        const { data } = await get<RequestResponse<Paginated<CouponInterface>>>(`${Config.APIBaseUrl}admin/${codeType}s${jsonToQueryString(rest)}`);
        log({ getCoupons: data })
        return data
    }

    const createCoupon = async (param: CouponInterface): Promise<CouponInterface> => {
        const { data } = await post<RequestResponse<CouponInterface>>(`${Config.APIBaseUrl}admin/${param.codeType}s`, param);
        log({ createCoupon: data })
        return data.data
    }

    const updateCoupon = async (param: CouponInterface): Promise<CouponInterface> => {
        const { data } = await patch<RequestResponse<CouponInterface>>(`${Config.APIBaseUrl}admin/${param.codeType}s`, param);
        log({ updateCoupon: data })
        return data.data
    }

    const deleteCoupon = async (param: { codeType: CouponTypes; code: string; }): Promise<boolean> => {
        const { data } = await remove<RequestResponse<boolean>>(`${Config.APIBaseUrl}admin/${param.codeType}s?code=${param.code}`);
        log({ deleteCoupon: data })
        return data.data
    }

    const getSDKAssets = async (param: { assetType: MediaAssetTypes; status?: Status; page?: number; limit?: number; search?: string; sort?: SortDirections; } = { assetType: "images" }): Promise<Paginated<MediaAsset>> => {
        const { data } = await get<RequestResponse<Paginated<MediaAsset>>>(`${Config.APIBaseUrl}admin/sdk-assets${jsonToQueryString(param)}`);
        log({ getSDKAssets: data })
        return data
    }

    const prepareSDKAssetUpload = async (param: { extension: keyof typeof ExtensionLibrary; quantity?: number; }): Promise<PresignedPolicy[]> => {
        const { data } = await put<RequestResponse<PresignedPolicy[]>>(`${Config.APIBaseUrl}admin/sdk-assets`, param);
        log({ prepareSDKAssetUpload: data })
        return data.data
    }

    const uploadAsset = async ({ url, formData }: { url: string; formData: Blob; }): Promise<boolean> => {
        const { data } = await uploadFile<RequestResponse<boolean>>(url, formData);
        log({ uploadAsset: data })
        return data.data
    }

    const createSDKAsset = async (param: MediaAsset): Promise<boolean> => {
        const { data } = await post<RequestResponse<boolean>>(`${Config.APIBaseUrl}admin/sdk-assets`, param);
        log({ createSDKAsset: data })
        return data.data
    }

    const updateSDKAsset = async (param: MediaAsset): Promise<boolean> => {
        const { data } = await patch<RequestResponse<boolean>>(`${Config.APIBaseUrl}admin/sdk-assets`, param);
        log({ updateSDKAsset: data })
        return data.data
    }

    const deleteSDKAsset = async (param: { assetType: MediaAssetTypes; id: string; }): Promise<boolean> => {
        const { data } = await remove<RequestResponse<boolean>>(`${Config.APIBaseUrl}admin/sdk-assets${jsonToQueryString(param)}`);
        log({ deleteSDKAsset: data })
        return data.data
    }

    return {
        signUp,
        getCoupons,
        uploadAsset,
        getPricings,
        getProducts,
        getProjects,
        getSDKAssets,
        getTemplates,
        createCoupon,
        updateCoupon,
        deleteCoupon,
        createProject,
        resetPassword,
        deleteProject,
        createProduct,
        updateProduct,
        redeemVoucher,
        applyDiscount,
        updateSDKAsset,
        preparePayment,
        confirmPayment,
        attemptPayment,
        removeDiscount,
        voucherDetails,
        createSDKAsset,
        deleteSDKAsset,
        duplicateProject,
        getSessionDetails,
        getAdminTemplates,
        updateProductPrice,
        createProductPrice,
        createAdminTemplate,
        deleteAdminTemplate,
        prepareSDKAssetUpload,
        refreshSessionDetails,
        duplicateAdminTemplate,
        sendAuthenticationCode
    }
}