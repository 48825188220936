import styled from "styled-components";
import { Config } from "../../../../config";
import { Gallery } from "react-grid-gallery";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../../hooks/use-api";
import { forPhoneOnly } from "../../../../elements";
import { Group } from "../../../../layout/Group/Group";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "../../../../hooks/use-form.hook";
import { Button } from "../../../../components/Form/Button";
import Loading from "../../../../components/Loading/Loading";
import { useNotify } from "../../../../hooks/use-notify.hook";
import { fileToBase64 } from "../../../../utils/fileToBase64.util";
import OffCanvas from "../../../../components/OffCanvas/OffCanvas";
import { LoadMore } from "../../../../components/LoadMore/LoadMore";
import { useMutation, useInfiniteQuery } from "@tanstack/react-query";
import { useConsoleLog } from "../../../../hooks/use-console-log.hook";
import { useNotification } from "../../../../hooks/use-notification.hook";
import { GetFilters } from "../../../../utils/types/getFilters.interface";
import { TextInput } from "../../../../components/Form/TextInput/TextInput";
import { FileInput } from "../../../../components/Form/FileInput/FileInput";
import { VideoPlayer } from "../../../../components/VideoPlayer/VideoPlayer";
import { getImageDimensions } from "../../../../utils/getImageDimensions.util";
import { FileEarmarkFont, Search, SendCheck, Trash } from "react-bootstrap-icons";
import { DropdownValues, SelectInput } from "../../../../components/Form/Select/SelectInput";
import { Status, addToDate, timeExpired, MediaAssetTypes, MediaAsset, PresignedPolicy, ExtensionLibrary, currentFullDate, extensionToContentType, getAssetURL, getMediaAssetCategories, MediaAssetCategories } from "shared-library";

const StyledGroup = styled(Group)`
    padding: 20px;
    
    .add-container {
        cursor: pointer;
        font-size: 14px;
        padding: 80px 40px;
        letter-spacing: 4px;
        text-transform: uppercase;
        ${forPhoneOnly("padding: 40px 15px;")}
        color: ${props => props.theme.colors.gray};
    }

    .search-input {
        width: 350px;
    }

    .bar {

        .navLink {
            font-size: 12px;
            cursor: pointer;
            padding-bottom: 4px;
            transition: all 0.5s;
            text-transform: uppercase;
            border-bottom: 1px solid transparent;
            color: ${props => props.theme.colors.black};

            &.active {
                border-bottom-color: ${props => props.theme.colors.secondary};
            }
        }
    }

    .data-container {
        padding: 0px 10px;
    }
`;

interface MediaAssetsGetProps extends GetFilters {
    assetType: MediaAssetTypes;
}
interface MediaAssetsProps {
    assetType: MediaAssetTypes;
}
const SDKAssets = ({ assetType }: MediaAssetsProps) => {

    const { t } = useTranslation();
    const { getSDKAssets } = useApi();
    const [filters, setFilters] = useState<MediaAssetsGetProps>({
        page: 1,
        assetType,
        search: "",
        status: Status.active
    })

    const { data, isFetchingNextPage, isLoading, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery({
        queryFn: ({ pageParam: page }) => getSDKAssets({
            ...filters,
            assetType,
            page,
        }),
        initialPageParam: filters.page,
        getNextPageParam: (lastPage) => lastPage.totalPages > lastPage.currentPage ? lastPage.currentPage + 1 : undefined,
        queryKey: [`${assetType}s`, filters.status, filters.page],
    });

    const [selectedMedia, setSelectedMedia] = useState<MediaAsset>();

    const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false);


    const flattenAssets = useCallback(() => {
        let assets: Array<MediaAsset> = []
        if (data?.pages) {
            for (const page of data.pages) {
                assets = [...assets, ...page.data]
            }
        }
        return assets
    },
        [data?.pages]
    );

    return (
        <StyledGroup className="container-fluid">
            {
                selectedMedia && <MediaAssetSelected mediaAsset={selectedMedia} onDone={refetch} offCanvasIsOpen={offCanvasIsOpen} setOffCanvasIsOpen={setOffCanvasIsOpen} />
            }
            <Group gap="md" className="container">
                <UploadFile assetType={assetType} onDone={refetch} />
                <Group direction="row" justify="space-between" className="bar">
                    <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); refetch() }}>
                        <TextInput
                            type="text"
                            className="search-input"
                            placeholder={t('search')}
                            value={filters.search || ""}
                            leftIcon={<Search size={18} />}
                            onChange={search => setFilters({ ...filters, search: search as string })}
                        />
                    </form>
                    <Group direction="row" align="center" gap="sm" justify="center">
                        {
                            ([Status.active, Status.inactive]).map(status => <small key={status} onClick={() => setFilters({ ...filters, status })} className={"navLink" + (filters.status === status ? ' active' : '')}>{t(status)}</small>)
                        }
                    </Group>
                </Group>

                <Group className="data-container" gap="md">
                    {
                        !data || isLoading ? <Loading /> : <>
                            {
                                ["images", "snapshots"].includes(assetType) && <Gallery
                                    enableImageSelection={false}
                                    onClick={index => { setSelectedMedia(flattenAssets()[index]); setOffCanvasIsOpen(true) }}
                                    images={flattenAssets().map(asset => ({
                                        src: asset.URL,
                                        width: asset.width,
                                        height: asset.height,
                                        caption: asset.fileName,
                                        tags: asset.categories.map(value => ({ value: t(value), title: t(value) })),
                                    }))}
                                />
                            }
                            {
                                ["videos"].includes(assetType) && <Group direction="row">
                                    {
                                        flattenAssets().map(asset => <div className="pointer" onClick={() => { setSelectedMedia(asset); setOffCanvasIsOpen(true) }} key={asset.id} style={{ maxWidth: "350px" }}>
                                            <VideoPlayer file={asset} />
                                        </div>)
                                    }
                                </Group>
                            }
                            {
                                hasNextPage && <LoadMore loading={isFetchingNextPage} onclick={fetchNextPage} />
                            }
                        </>
                    }
                </Group>
            </Group>
        </StyledGroup>
    )
}
export default SDKAssets;


const presignedPoliciesArray: {
    creationDate: string;
    policies: PresignedPolicy[];
    extension: keyof typeof ExtensionLibrary;
}[] = [];

interface UploadFileProps {
    onDone: () => void;
    assetType: MediaAssetTypes;
}
const UploadFile = ({ assetType, onDone }: UploadFileProps) => {

    const { log } = useConsoleLog()
    const { t } = useTranslation();
    const { notify } = useNotification();
    const { prepareSDKAssetUpload, uploadAsset, createSDKAsset } = useApi();

    const { isPending: preparing1, mutateAsync: prepareSDKAssetUploadAsync } = useMutation({
        mutationFn: prepareSDKAssetUpload
    })
    const { isPending: preparing2, mutateAsync: uploadAssetAsync } = useMutation({
        mutationFn: uploadAsset
    })
    const { isPending: preparing3, mutateAsync: createSDKAssetAsync } = useMutation({
        mutationFn: createSDKAsset
    })

    const getPolicy = async (extension: keyof typeof ExtensionLibrary) => {
        const fromLocal = presignedPoliciesArray.findIndex(pp =>
            pp.policies.length &&
            pp.extension === extension &&
            !timeExpired(addToDate(pp.creationDate, 55, "minutes"))
        )
        if (fromLocal !== -1) return presignedPoliciesArray[fromLocal].policies.pop() as PresignedPolicy;
        const policies = await prepareSDKAssetUploadAsync({ extension, quantity: 10 })
        const policy = policies.pop() as PresignedPolicy;
        presignedPoliciesArray.push({
            policies,
            extension,
            creationDate: currentFullDate(),
        })
        return policy;
    }

    const b64toBlob = async (dataURI: string, contentType: string): Promise<Blob> => {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {
            type: contentType
        });
    }


    const fileSelected = async (files: File[]) => {
        let updated = false
        for (const file of files) {
            const extension = file.name.split(".").pop()?.toLowerCase() as keyof typeof ExtensionLibrary;
            if (["images", "snapshots"].includes(assetType)) {
                if (!["jpg", "jpeg", "png"].includes(extension)) {
                    notify({ type: "error", title: t('unexpectedError'), body: t('invalidFileTypeFound') });
                    return
                }
            }
            if (["videos"].includes(assetType)) {
                if (!["mp4", "webm", "mov", "mkv"].includes(extension)) {
                    notify({ type: "error", title: t('unexpectedError'), body: t('invalidFileTypeFound') });
                    return
                }
            }
        }
        // All good
        try {
            for (const file of files) {
                const fileStr = await fileToBase64(file);
                const getImageDimensionsLocal = getImageDimensions(fileStr)
                const extension = file.name.split(".").pop()?.toLowerCase() as keyof typeof ExtensionLibrary;
                const presignedPolicy = await getPolicy(extension)

                //Initiate upload
                const policyDecoded = JSON.parse(atob(presignedPolicy.fields.Policy));

                const formData = new FormData();
                formData.append('key', presignedPolicy.fields.key);
                const acl = policyDecoded.conditions.find((c: any) => c?.acl)
                if (acl) formData.append('acl', acl.acl);
                const contentType = extensionToContentType(extension as keyof typeof ExtensionLibrary);
                if (!contentType) {
                    notify({ type: "error", title: t('unexpectedError'), body: t('invalidFileTypeFound') });
                    return
                };

                formData.append('Content-Type', contentType);
                formData.append('Policy', presignedPolicy.fields.Policy);
                formData.append('X-Amz-Algorithm', presignedPolicy.fields['X-Amz-Algorithm']);
                formData.append('X-Amz-Credential', presignedPolicy.fields['X-Amz-Credential']);
                formData.append('X-Amz-Date', presignedPolicy.fields['X-Amz-Date']);
                formData.append('X-Amz-Signature', presignedPolicy.fields['X-Amz-Signature']);
                formData.append('X-Amz-Security-Token', presignedPolicy.fields['X-Amz-Security-Token']);
                formData.append('bucket', presignedPolicy.fields.bucket);
                formData.append('file', await b64toBlob(fileStr, contentType));
                const { width, height } = await getImageDimensionsLocal
                await uploadAssetAsync({
                    url: presignedPolicy.url,
                    formData: formData as unknown as Blob
                })

                const assetFolder = "assets/";
                let filePath = presignedPolicy.fields.key;
                if (presignedPolicy.fields.key.startsWith(assetFolder)) {
                    filePath = presignedPolicy.fields.key.replace(assetFolder, "")
                }
                let fileId = filePath.split('/').pop() as string;
                fileId = fileId.split('.')[0] as string;
                const newAsset: MediaAsset = {
                    assetType,
                    id: fileId,
                    width, height,
                    categories: [],
                    size: file.size,
                    deletable: false,
                    isTemplate: true,
                    fileName: file.name,
                    status: Status.active,
                    creationDate: currentFullDate(),
                    URL: `${getAssetURL(Config.Environment)}${filePath}`,
                }
                await createSDKAssetAsync(newAsset)
                updated = true
            }

            if (updated) {
                notify({ type: "success", title: t('success'), body: t('fileUploadedSuccessfully') });
                onDone()
            }
        } catch (error) {
            log(error)
        }
    }
    return (<FileInput multiple={true} extension={["videos"].includes(assetType) ? "video/mp4" : ""} uploading={preparing1 || preparing2 || preparing3} onChange={fileSelected} />)
}

interface MediaAssetSelectedProps {
    onDone: () => void;
    mediaAsset: MediaAsset;
    offCanvasIsOpen: boolean;
    setOffCanvasIsOpen: (v: boolean) => void;
}
const MediaAssetSelected = ({
    onDone,
    mediaAsset,
    offCanvasIsOpen,
    setOffCanvasIsOpen
}: MediaAssetSelectedProps) => {

    const { t } = useTranslation();
    const { confirm } = useNotify();
    const { notify } = useNotification();
    const { deleteSDKAsset, updateSDKAsset } = useApi();
    const { onSubmit, onChange, errors, formValues, reset, setFormValues } = useForm({
        defaultValues: {
            ...mediaAsset,
            categoriesLocal: mediaAsset.categories.map(c => ({
                value: c,
                label: t(c)
            })),
        },
        validations: {
            fileName: { required: true, max: 60 },
        }
    })

    const { isPending: isDeleting, mutateAsync: deleteSDKAssetAsync } = useMutation({
        mutationFn: deleteSDKAsset
    })

    const { isPending: isUpdating, mutateAsync: updateSDKAssetAsync } = useMutation({
        mutationFn: updateSDKAsset
    })

    useEffect(() => {
        setFormValues({
            ...mediaAsset,
            categoriesLocal: mediaAsset.categories.map(c => ({
                value: c,
                label: t(c)
            })),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaAsset])

    const deleteSDKAssetLocal = async (datum: MediaAsset) => {
        if (await deleteSDKAssetAsync({ assetType: datum.assetType, id: datum.id })) {
            notify({ type: "success", title: t('success'), body: t('deletedSuccessfully') });
            setOffCanvasIsOpen(false)
            onDone()
            reset()
            return
        }
    }

    const updateSDKAssetLocal = async (datum: typeof formValues) => {
        if (await updateSDKAssetAsync({ ...datum, categories: formValues.categoriesLocal.map(c => c.value) })) {
            notify({ type: "success", title: t('success'), body: t('updatedSuccessfully') });
            setOffCanvasIsOpen(false)
            onDone()
            reset()
            return
        }
    }

    const categoriesPrepped = () => {
        const response: Array<DropdownValues> = [];
        for (const key of getMediaAssetCategories()) {
            let value = key as MediaAssetCategories;
            response.push({
                value,
                label: t(value),
            });
        }
        return response;
    };

    return (
        <OffCanvas
            isOpen={offCanvasIsOpen}
            title={t('update')}
            backdrop={true}
            handleClose={() => setOffCanvasIsOpen(false)}
        >
            <Group>
                {
                    ["images", "snapshots"].includes(mediaAsset.assetType) && <img src={mediaAsset.URL} alt={mediaAsset.fileName} width={"100%"} />
                }
                {
                    ["videos"].includes(mediaAsset.assetType) && <div className="pointer" style={{ maxWidth: "100%", marginBottom: "30px" }}>
                        <VideoPlayer file={mediaAsset} />
                    </div>
                }
                <form onSubmit={onSubmit(updateSDKAssetLocal)}>
                    <Group>
                        <TextInput
                            isRequired
                            type="text"
                            error={errors.fileName}
                            placeholder={t('fileName')}
                            value={formValues.fileName}
                            leftIcon={<FileEarmarkFont size={16} />}
                            onChange={onChange("fileName")}
                        />
                        <SelectInput
                            isMulti={true}
                            options={categoriesPrepped()}
                            error={errors.categoriesLocal}
                            value={formValues.categoriesLocal}
                            placeholder={t('categoriesLocal')}
                            onChange={onChange("categoriesLocal")}
                        />
                        <Group gap="xs">
                            <Button text={t('update')} type="submit" icon={<SendCheck />} disabled={isUpdating} loading={isUpdating} />
                            <Button color="danger" text={t('delete')} icon={<Trash />} type="button" disabled={isDeleting} loading={isDeleting} onClick={() => confirm({
                                onConfirmation: () => deleteSDKAssetLocal(mediaAsset)
                            })} />
                        </Group>
                    </Group>
                </form>
            </Group>
        </OffCanvas>
    )
}