import { Status } from "shared-library";

export enum CouponTypes {
    VOUCHER = "voucher",
    DISCOUNT = "discount"
}

export interface CouponInterface {
    id: string;
    tnc: string;
    code: string;
    title: string;
    status: Status;
    endDate: string;
    clientId: string;
    currency?: string;
    startDate: string;
    /**
     * How many people are allowed to use this coupon, 0 for unlimited
     * @description
     */
    maxUsedBy: number;
    /**
    * How many people have used this coupon
    * @description
    */
    usedCounter: number;
    codeType: CouponTypes;
    /**
    * How many times can one user use this coupon, 0 for unlimited
    * @description
    */
    maxUsedPerUser: number;
    discountAmount: number;
    discountAmountInPercentage: boolean;
    validForProductPrices: {
        productId: string;
        productPriceId: string;
    }[];
    updatedOn?: string;
    creationDate: string;
}
