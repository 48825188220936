import ReactGA from "react-ga4";
import { Config } from "./config";

export const initGA = () => {
    if (Config.IsProd) {
        ReactGA.initialize("G-Z2QDMK0E68");
    }
};

export const logPageView = (path = window.location.pathname) => {
    if (Config.IsProd) {
        ReactGA.send({ hitType: "pageview", page: path });
    }
};

export const trackEvent = (category: string, action: string, label = "") => {
    if (Config.IsProd) {
        ReactGA.event({
            category,
            action,
            label,
        });
    }
};