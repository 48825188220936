import { TFunction } from "i18next";
import { ErrorCodes } from "shared-library";
import { CreateNotification } from "../context/notification/notification.context";


interface OnErrorParams {
    e: any;
    t: TFunction;
    logout: () => void;
    log: (e: any) => void;
    notify: (data: CreateNotification) => void;
}
const onErrorHandler = ({ e, logout, t, log, notify }: OnErrorParams) => {

    const notifyError = {
        type: "error",
        title: t('unexpectedError'),
        body: t('serviceNotAvailable'),
    } as const;

    if ([ErrorCodes.SERVICE_NOT_AVAILABLE].includes(e) || "Failed to fetch" === e?.message) {
        logout()
        return
    }

    log(e);
    // Unknown error
    if (typeof e === "string") {
        notify({ ...notifyError, body: t([e as ErrorCodes]) || e });
    } else {
        notify(notifyError);
    }
};

export default onErrorHandler;