"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPermission = void 0;
class UserPermission {
    userSubscription;
    constructor(userSubscription) {
        this.userSubscription = userSubscription;
    }
    canCreateProject(projects, userId) {
        if (this.userSubscription.features.limits.projects === 0)
            return true;
        return this.userSubscription.features.limits.projects > projects.filter(project => project.userId === userId).length;
    }
    canCreateProjectPage(currentLength) {
        if (this.userSubscription.features.limits.pagesPerProject === 0)
            return true;
        return this.userSubscription.features.limits.pagesPerProject > currentLength;
    }
    projectPageWithinLimit(currentLength) {
        if (this.userSubscription.features.limits.pagesPerProject === 0)
            return true;
        return this.userSubscription.features.limits.pagesPerProject >= currentLength;
    }
    hasAccessToComponent(componentId) {
        return this.userSubscription.features.sdk.componentTypes.includes(componentId);
    }
    hasAccessToMainComponent() {
        return this.userSubscription.features.sdk.addableMainComponent;
    }
    pageDownloadable() {
        return this.userSubscription.features.sdk.pageDownload;
    }
    projectExportable() {
        return this.userSubscription.features.sdk.exportProject;
    }
}
exports.UserPermission = UserPermission;
