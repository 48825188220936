export const getImageDimensions = async (imageUrl: string): Promise<{ width: number; height: number }> => {
    const dataPromise = new Promise<{ width: number; height: number }>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({ width: img.width, height: img.height });
        };
        img.onerror = (error) => {
            reject(error);
        };
        img.src = imageUrl;
    });
    try {
        const data = await dataPromise
        return data;
    } catch (error) {
        console.error('Error getting image dimensions:', error);
        return { width: 500, height: 500 };
    }
}
