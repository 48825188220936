import React from 'react';
import styled, { useTheme } from 'styled-components';


interface ProgressBarProps {
    value: number;
    color?: string;
    height?: number;
    bgColor?: string;
    maxValue?: number;
}

const StyledProgressBarContainerDiv = styled.div`
    border-radius: 15px;

    > div {
        height: 100%;
        border-radius: 15px;
    }
`

export const ProgressBar: React.FC<ProgressBarProps> = ({ value, maxValue, color, bgColor, height = 5 }) => {

    const theme = useTheme()
    const percentage = (value / (maxValue || 100)) * 100;

    return (
        <StyledProgressBarContainerDiv style={{ backgroundColor: bgColor || theme.colors.white, height }}>
            <div style={{ width: `${percentage}%`, backgroundColor: color || theme.colors.primary }}></div>
        </StyledProgressBarContainerDiv>
    );
};