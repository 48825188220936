import Moment from "react-moment";
import styled from "styled-components";
import { Tooltip } from 'react-tooltip';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../hooks/use-api";
import Card from "../../../components/Card/Card";
import { AuthContext } from "../../../context/auth";
import { Group } from "../../../layout/Group/Group";
import Loading from "../../../components/Loading/Loading";
import { useNotify } from "../../../hooks/use-notify.hook";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useConsoleLog } from "../../../hooks/use-console-log.hook";
import { useCallback, useContext, useEffect, useState } from "react";
import { ProjectDetails, Status, UserPermission } from "shared-library";
import { forPhoneOnly, grow, StyledIsRequired } from "../../../elements";
import { TextInput } from "../../../components/Form/TextInput/TextInput";
import { Copy, Globe2, Pencil, PlusLg, Search, Trash } from "react-bootstrap-icons";
import { AuthStatePreferencesValuesProject } from "../../../context/auth/auth.context";
import UpgradeToAccessModalComponent from "../../../components/Modals/UpgradeToAccessModal";

const StyledGroup = styled(Group)`
    padding: 20px;
    
    .add-container {
        cursor: pointer;
        font-size: 14px;
        padding: 80px 40px;
        letter-spacing: 4px;
        text-transform: uppercase;
        ${forPhoneOnly("padding: 40px 15px;")}
        color: ${props => props.theme.colors.gray};
    }

    .search-input {
        width: 350px;
    }

    .bar {

        .navLink {
            font-size: 12px;
            cursor: pointer;
            padding-bottom: 4px;
            transition: all 0.5s;
            text-transform: uppercase;
            border-bottom: 1px solid transparent;
            color: ${props => props.theme.colors.black};

            &.active {
                border-bottom-color: ${props => props.theme.colors.secondary};
            }
        }
    }

    .template-container {
        padding: 0px 10px;
        min-height: 500px;
    }
`;

const TemplateGroup = styled(Group)`
    overflow: hidden;
    flex-grow: 1;
    width: 265px;
    max-width: 265px;
    padding: 10px 0;
    ${grow}
    border: 1px solid ${props => props.theme.colors.gray10};
    ${forPhoneOnly("max-width: 85%; margin: 0 auto; margin-bottom: 20px;")}

    .scrollable {
        overflow-y: auto;
        width: 100%;
        min-height: 200px;
        max-height: 200px;

        ${forPhoneOnly("max-height: 500px;")}
        box-sizing: content-box;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar { 
            display: none;
        }

        img {
            width: 100%;
            margin-bottom: -10px;
        }

    }

    .details {
        padding: 10px;

        span:nth-child(1) {
            color: ${props => props.theme.colors.black};
            font-size: 16px;
            font-weight: bold;
        }

        span:nth-child(2), small {
            color: ${props => props.theme.colors.gray};
            font-size: 14px;
        }

        .icon {
            cursor: pointer;
            color: ${props => props.theme.colors.gray};
        }
    }
`

const UserDashboard = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { log } = useConsoleLog();
    const [query, setQuery] = useState('');
    const { state, setPreferences } = useContext(AuthContext);
    const [showPricingModal, setShowPricingModal] = useState(false);
    const { createProject, getProjects, getPricings, duplicateProject } = useApi();
    const { mutateAsync: duplicateProjectAsync } = useMutation({
        mutationFn: duplicateProject
    })
    useQuery({
        queryFn: getPricings,
        queryKey: ["pricing"],
        enabled: !state.resources.pricing
    });
    const { data: projects } = useQuery({
        queryFn: getProjects,
        queryKey: ["projects"],
    });
    const { isPending: isCreatingProject, mutateAsync: createProjectAsync } = useMutation({
        mutationFn: createProject
    })

    useEffect(() => {
        if (projects && state.user && state.preferences.whereILeftOff.beforeAuthFlow.startProject) {
            const userPermission = new UserPermission(state.user.subscription)
            const { startProject, ...rest } = state.preferences.whereILeftOff.beforeAuthFlow
            if (userPermission.canCreateProject(projects.data, state.user.id)) {
                createProjectAsync({ templateId: state.preferences.whereILeftOff.beforeAuthFlow.startProject.templateId }).then(({ shortId }) => {
                    setPreferences({ ...state.preferences, whereILeftOff: { ...state.preferences.whereILeftOff, beforeAuthFlow: rest } })
                    navigate(`/user/dashboard/projects/${shortId}`)
                }).catch(error => {
                    log(error)
                    setPreferences({ ...state.preferences, whereILeftOff: { ...state.preferences.whereILeftOff, beforeAuthFlow: rest } })
                })
                return
            }
            setPreferences({ ...state.preferences, whereILeftOff: { ...state.preferences.whereILeftOff, beforeAuthFlow: rest } })
            setShowPricingModal(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects, state.preferences, state.user])

    const startProject = useCallback(async () => {
        if (!projects || !state.user) return
        const userPermission = new UserPermission(state.user.subscription)
        if (!userPermission.canCreateProject(projects.data, state.user.id)) {
            setShowPricingModal(true)
            return
        }
        navigate(`/templates`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user, projects])

    const duplicateProjectFunc = useCallback(async (project: ProjectDetails) => {
        if (!projects || !state.user) return
        try {
            const userPermission = new UserPermission(state.user.subscription)
            if (!userPermission.canCreateProject(projects.data, state.user.id)) {
                setShowPricingModal(true)
                return
            }

            const { shortId } = await duplicateProjectAsync(project.shortId);
            navigate(`/user/dashboard/projects/${shortId}`);
        } catch (error) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user, projects])

    if (state.preferences.whereILeftOff.beforeAuthFlow.startProject) return <Loading />
    return (
        <StyledGroup className="container-fluid">
            <Group gap="md" className="container">
                <UpgradeToAccessModalComponent setIsOpen={setShowPricingModal} modalIsOpen={showPricingModal} />
                <Group direction="row">
                    <Card className="add-container" onClick={startProject}>
                        <Group direction="row" wrap="nowrap">
                            <PlusLg size={18} />
                            {t('newWebsite')}
                        </Group>
                    </Card>
                </Group>
                <Group direction="row" justify="space-between" className="bar">
                    <TextInput
                        isRequired
                        type="text"
                        value={query}
                        onChange={setQuery}
                        className="search-input"
                        placeholder={t('search')}
                        leftIcon={<Search size={18} />}
                    />
                    <Group direction="row" align="center" gap="sm" justify="center">
                        {
                            (["allProjects", "ownProjects", "shared"] as Array<AuthStatePreferencesValuesProject>).map(projectSort => <small key={projectSort} onClick={() => setPreferences({ ...state.preferences, projectSort })} className={"navLink" + (state.preferences.projectSort === projectSort ? ' active' : '')}>{t(projectSort)}</small>)
                        }
                    </Group>
                </Group>

                <Group className="template-container" direction="row" gap="sm">
                    {
                        state.resources.projects && state.resources.projects.data.filter(t => ("allProjects" === state.preferences.projectSort || ("ownProjects" === state.preferences.projectSort && t.userId === state.user?.id) || ("shared" === state.preferences.projectSort && t.userId !== state.user?.id)) && (!query.trim() || JSON.stringify(t).toLowerCase().includes(query.toLowerCase()))).map(template => <TemplateDom key={template.id} template={template} onDuplicateClick={() => duplicateProjectFunc(template)} />)
                    }
                </Group>

            </Group>
        </StyledGroup>
    )
}
export default UserDashboard;


const TemplateDom = ({ template, onDuplicateClick }: { template: ProjectDetails; onDuplicateClick: () => void; }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { confirm } = useNotify();
    const { deleteProject } = useApi();
    const { isPending: isDeletingProject, mutateAsync: deleteProjectAsync } = useMutation({
        mutationFn: deleteProject
    })

    return (
        <TemplateGroup gap="none" className={isDeletingProject ? "deleting" : ""}>
            <div className="scrollable">
                <img alt={t('templateScreenshot')} src={template.snapshots.desktopFullPage || "/assets/images/diy-website-builder-dummy-image.png"} />
            </div>
            <Group className="details">
                <Group direction="row" wrap="nowrap" justify="space-between">
                    <Group gap="none">
                        <span>{template.title || t('untitledProject')}</span>
                        <small>{template.shortId}</small>
                    </Group>
                    <Group gap="none" align="end">
                        <small>{t(template.deploymentHistory.isLive ? Status.active : Status.draft)}</small>
                        <small><Moment format="MMM Do, YYYY, hh:mm">{template.creationDate}</Moment></small>
                    </Group>
                </Group>
                <Group direction="row" align="center" justify="space-between">
                    <Pencil size={20} onClick={() => navigate(`/user/dashboard/projects/${template.shortId}`)} className="icon edit-icon" />
                    <Copy size={20} onClick={onDuplicateClick} className="icon duplicate-icon" />
                    {
                        template.deploymentHistory.isLive ? <Globe2 size={20} onClick={() => window.open(template.deploymentHistory.siteURL, '_blank')?.focus()} className="icon globe-icon" /> : null
                    }
                    <StyledIsRequired>
                        <Trash size={20} className="pointer trash-icon" onClick={() => confirm({
                            onConfirmation: () => deleteProjectAsync(template.id)
                        })} />
                    </StyledIsRequired>

                    <Tooltip anchorSelect=".edit-icon" place="top">{t('editProject')}</Tooltip>
                    <Tooltip anchorSelect=".duplicate-icon" place="top">{t('duplicateProject')}</Tooltip>
                    <Tooltip anchorSelect=".globe-icon" place="top">{t('visitPublishedSite')}</Tooltip>
                    <Tooltip anchorSelect=".trash-icon" place="top">{t('deleteProject')}</Tooltip>
                </Group>
            </Group>
        </TemplateGroup>
    )
}