import { Config } from "../../config";
import styled from "styled-components";
import { useApi } from "../../hooks/use-api";
import { trackEvent } from "../../analytics";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Group } from "../../layout/Group/Group";
import { AuthContext } from "../../context/auth";
import { useForm } from "../../hooks/use-form.hook";
import { useMutation } from "@tanstack/react-query";
import { Button } from "../../components/Form/Button";
import { useCallback, useContext, useState } from "react";
import { useConsoleLog } from "../../hooks/use-console-log.hook";
import { useNotification } from "../../hooks/use-notification.hook";
import { StyledErrorText, StyledIsRequired } from "../../elements";
import { isEmail, MobileNumber, UserDetails } from "shared-library";
import { TextInput } from "../../components/Form/TextInput/TextInput";
import { useRouteResolver } from "../../hooks/use-route-resolver.hook";
import { At, Lock, PersonAdd, SendCheckFill } from "react-bootstrap-icons";
import { StyledLabel } from "../../components/Form/InputContainer/InputContainer";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

const StyledAuthContainer = styled(Group)`
    min-height: 80vh;

    .auth-wrapper {
        padding: 20px;
        max-width: 450px;
        p:nth-child(1) {
            font-size: 34px;
            font-weight: bold;
            letter-spacing: 4px;
            text-align: center;
            text-transform: uppercase;
            color: ${props => props.theme.colors.black};
        }

        p:nth-child(2) {
            font-size: 16px;
            margin-top: -12px;
            text-align: center;
            color: ${props => props.theme.colors.gray};
        }

        a {
            font-weight: bold;
            font-size: 14px;
            color: ${props => props.theme.colors.black};
        }

        .lbl {
            font-size: 14px;
            color: ${props => props.theme.colors.gray};
        }

        .sent-code,
        .send-code,
        .sending-code {
            font-size: 14px;
            text-align: center;
            white-space: nowrap;
        }

        .send-code {
            cursor: pointer;
            color: ${props => props.theme.colors.black};
            border-bottom: 1px solid ${props => props.theme.colors.gray10};

            &.disabled {
                cursor: default;
                color: ${props => props.theme.colors.gray10};
            }
        }

        .sent-code {
            color: ${props => props.theme.colors.success};
        }
    }

`
export const AuthSignIn = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { log } = useConsoleLog();
    const { notify } = useNotification();
    const { getSessionDetails } = useApi();
    const { dashboard } = useRouteResolver();
    const [userAttributes, setUserAttributes] = useState({});


    const { login } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordResetRequired, setPasswordResetRequired] = useState(false);
    const [cognitoObj, setCognitoObj] = useState<CognitoUser>();
    const { onSubmit, onChange, errors, formValues } = useForm({
        defaultValues: {
            email: "",
            password: ""
        },
        validations: {
            password: { required: true },
            email: { email: true, required: true },
        }
    })

    const onSuccess = (jwt: string, cognitoUser: CognitoUser) => {
        cognitoUser.getUserAttributes(async (err, result) => {
            if (err) return log(err.message);
            const fullName = result?.find(({ Name, Value }) => (Name === "name" ? Value : false))?.Value || "";
            const firstName = result?.find(({ Name, Value }) => (Name === "given_name" ? Value : false))?.Value || fullName.split(" ")[0] || "";

            try {
                const info = await getSessionDetails({ jwt })
                const mobile: MobileNumber = {};
                const phoneNumber = result?.find(({ Name, Value }) => (Name === "phone_number" ? Value : ''))?.Value;
                if (phoneNumber) mobile.number = +phoneNumber
                const user: UserDetails = {
                    ...info,
                    jwt,
                    mobile,
                    fullName,
                    firstName,
                    sub: result?.find(({ Name, Value }) => (Name === "sub" ? Value : ''))?.Value || '',
                    email: result?.find(({ Name, Value }) => (Name === "email" ? Value : ''))?.Value || '',
                    timezone: result?.find(({ Name, Value }) => (Name === "locale" ? Value : ''))?.Value || '',
                    picture: result?.find(({ Name, Value }) => (Name === "picture" ? Value : ''))?.Value || '',
                    address: result?.find(({ Name, Value }) => (Name === "address" ? Value : false))?.Value || '',
                    website: result?.find(({ Name, Value }) => (Name === "website" ? Value : false))?.Value || '',
                    nickName: result?.find(({ Name, Value }) => (Name === "nickname" ? Value : false))?.Value || firstName,
                    verified: result?.find(({ Name, Value }) => (Name === "email_verified" ? Value : ''))?.Value === 'true',
                    lastName: result?.find(({ Name, Value }) => (Name === "family_name" ? Value : false))?.Value || fullName.split(" ").pop() || "",
                }

                if (user.userTypes.length === 1) user.currentUserType = user.userTypes[0]
                login(user);
                navigate(dashboard());

            } catch (error) {
                log(error)
                navigate(`/500`);
            }
        });
    };

    const authenticate = () => {
        trackEvent("Form", "Submit", "Sign In")
        setIsLoading(true);
        const authenticationDetails = new AuthenticationDetails({
            Username: formValues.email,
            Password: formValues.password,
        });

        const userPool = new CognitoUserPool({
            UserPoolId: Config.UserPoolId,
            ClientId: Config.UserPoolClientId
        });

        const cognitoUser = new CognitoUser({
            Pool: userPool,
            Username: formValues.email,
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess(result) {
                const token = result.getIdToken().getJwtToken();
                onSuccess(token, cognitoUser);
            },

            newPasswordRequired(userAttr) {
                delete userAttr.email_verified;
                delete userAttr.phone_number_verified;
                delete userAttr.email;

                userAttr.name = userAttr.name ? userAttr.name : "User";

                setPasswordResetRequired(true);
                setUserAttributes(userAttr);
                setCognitoObj(cognitoUser);
            },

            onFailure(err) {
                notify({ type: "error", title: t('loginErrorTitle'), body: err.message });
                setIsLoading(false);
            },
        });
    };

    const { onSubmit: onResetSubmit, onChange: onResetChange, errors: resetErrors, formValues: resetFormValues } = useForm({
        defaultValues: {
            newPassword: "",
            confirmPassword: "",
        },
        validations: {
            newPassword: { required: true, min: 8 },
            confirmPassword: { required: true, min: 8 },
        }
    })

    const newPasswordHandler = () => {
        if (!cognitoObj) return;

        if (resetFormValues.newPassword !== resetFormValues.confirmPassword) {
            notify({
                type: "error",
                body: t('resetPasswordErrorBody'),
                title: t('resetPasswordErrorTitle'),
            });
            return;
        }
        cognitoObj.completeNewPasswordChallenge(resetFormValues.newPassword, userAttributes, {
            onSuccess(result) {
                const token = result.getIdToken().getJwtToken();
                onSuccess(token, cognitoObj);
            },

            onFailure(err) {
                notify({ type: "error", title: t('loginErrorTitle'), body: err.message });
                setIsLoading(false);
            },
        });
    };

    return (<StyledAuthContainer align="center" justify="center">
        <Group className="auth-wrapper">
            {!passwordResetRequired ? (
                <>
                    <p>{t('signIn')}</p>
                    <p>{t('manageYourProjects')}</p>
                    <form onSubmit={onSubmit(authenticate)}>
                        <Group>
                            <TextInput
                                isRequired
                                type="email"
                                error={errors.email}
                                placeholder={t('email')}
                                value={formValues.email}
                                leftIcon={<At size={18} />}
                                onChange={onChange("email")}
                            />
                            <TextInput
                                isRequired
                                type="password"
                                error={errors.password}
                                placeholder={t('password')}
                                leftIcon={<Lock size={18} />}
                                value={formValues.password}
                                onChange={onChange("password")}
                            />
                            <Button text={t('signIn')} type="submit" disabled={isLoading} loading={isLoading} />
                            <Group direction="row" justify="space-between">
                                <Group direction="row" gap="xs" wrap="nowrap">
                                    <span className="lbl">{t('newUser')}</span>
                                    <a href="/auth/sign-up">{t('registerHere')}</a>
                                </Group>
                                <a href="/auth/reset-password">{t('forgotPassword')}</a>
                            </Group>
                        </Group>
                    </form>
                </>
            ) : (
                <>
                    <form onSubmit={onResetSubmit(newPasswordHandler)}>
                        <Group>
                            <p>{t('resetPassword')}</p>
                            <p>{t('manageYourProjects')}</p>
                            <TextInput
                                isRequired
                                type="password"
                                placeholder={t('password')}
                                leftIcon={<Lock size={18} />}
                                error={resetErrors.newPassword}
                                value={resetFormValues.newPassword}
                                onChange={onResetChange("newPassword")}
                            />

                            <TextInput
                                isRequired
                                type="password"
                                leftIcon={<Lock size={18} />}
                                placeholder={t('confirmPassword')}
                                error={resetErrors.confirmPassword}
                                value={resetFormValues.confirmPassword}
                                onChange={onResetChange("confirmPassword")}
                            />
                            <Button text={t('resetPassword')} type="submit" />
                        </Group>
                    </form>
                </>
            )}
        </Group>
    </StyledAuthContainer>)
}

export const AuthSignUp = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { notify } = useNotification();
    const { sendAuthenticationCode, signUp } = useApi();
    const { onSubmit, onChange, errors, formValues, setErrors } = useForm({
        defaultValues: {
            email: "",
            fullName: "",
            password: "",
            confirmPassword: "",
            confirmationCode1: "",
            confirmationCode2: "",
            confirmationCode3: "",
            confirmationCode4: "",
            confirmationCode5: "",
            confirmationCode6: "",
        },
        validations: {
            fullName: { required: true },
            password: { required: true, min: 9 },
            email: { email: true, required: true },
            confirmPassword: { required: true, min: 9 },
            confirmationCode1: { max: 9, required: true },
            confirmationCode2: { max: 9, required: true },
            confirmationCode3: { max: 9, required: true },
            confirmationCode4: { max: 9, required: true },
            confirmationCode5: { max: 9, required: true },
            confirmationCode6: { max: 9, required: true },
        }
    })
    const [confirmationState, setConfirmationState] = useState<{ email: string; state: "pending" | "sent"; }>({ email: "", state: "pending" });
    const { isPending: isSendingCode, mutateAsync: sendAuthenticationCodeAsync } = useMutation({
        mutationFn: sendAuthenticationCode
    })
    const { isPending: isSigningUp, mutateAsync: signUpAsync } = useMutation({
        mutationFn: signUp
    })

    const signUpFunc = async () => {
        try {
            trackEvent("Form", "Submit", "Sign Up")
            if (formValues.password !== formValues.confirmPassword) {
                setErrors((err) => ({
                    ...err,
                    ...{ password: t('passwordFieldsDoNotMatch'), confirmPassword: t('passwordFieldsDoNotMatch') },
                }))
                return
            }
            if (await signUpAsync({ email: formValues.email, password: formValues.password, fullName: formValues.fullName, code: [formValues.confirmationCode1, formValues.confirmationCode2, formValues.confirmationCode3, formValues.confirmationCode4, formValues.confirmationCode5, formValues.confirmationCode6].join("") })) {
                notify({
                    type: "success",
                    title: t('success'),
                    body: t('signUpSuccess'),
                });
                navigate(`/auth/sign-in`)
            }
        } catch (error) {
        }
    };

    const onKeyUpFunc = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (index !== 5 && (event.key === "Enter" || (event.key !== "Backspace" && event.key !== "Tab"))) {
            event.preventDefault();
            event.stopPropagation();
            document.getElementById(`confirmationCode${index + 1}`)?.focus()
            return
        }
        if (index !== 0 && event.key === "Backspace") {
            document.getElementById(`confirmationCode${index - 1}`)?.focus()
            return
        }
    }

    const getAuthenticationCodeError = () => {
        if (errors.confirmationCode1 || errors.confirmationCode2 || errors.confirmationCode3 || errors.confirmationCode4 || errors.confirmationCode5 || errors.confirmationCode6) {
            return t('authenticationCodeError')
        }
        return ''
    }

    const sendAuthCode = useCallback(async () => {
        if (!isEmail(formValues.email)) return
        try {
            const { email } = formValues
            await sendAuthenticationCodeAsync({ email: formValues.email, codeType: "sign-up" })
            setConfirmationState({ email, state: "sent" });
            setTimeout(() => {
                if (formValues.email === email) {
                    setConfirmationState({ email, state: "pending" })
                }
            }, 1000 * 60)
        } catch (error) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationState.email, formValues])


    return (<StyledAuthContainer align="center" justify="center">
        <Group className="auth-wrapper">
            <>
                <p>{t('signUp')}</p>
                <p>{t('signUpToday')}</p>
                <br />
                <form onSubmit={onSubmit(signUpFunc)}>
                    <Group>
                        <TextInput
                            isRequired
                            type="text"
                            label={t('fullName')}
                            error={errors.fullName}
                            placeholder={t('fullName')}
                            value={formValues.fullName}
                            leftIcon={<PersonAdd size={16} />}
                            onChange={onChange("fullName")}
                        />
                        <TextInput
                            isRequired
                            type="email"
                            label={t('email')}
                            error={errors.email}
                            placeholder={t('email')}
                            value={formValues.email}
                            leftIcon={<At size={18} />}
                            onChange={onChange("email")}
                        />
                        <Group gap="sm">
                            <StyledLabel>{t('authenticationCode')}<StyledIsRequired>*</StyledIsRequired></StyledLabel>
                            <Group direction="row" align="center" wrap="nowrap">
                                {
                                    [0, 1, 2, 3, 4, 5].map((_, index) => <TextInput key={index}
                                        max={9}
                                        isRequired
                                        type="number"
                                        maxLength={1}
                                        id={`confirmationCode${index}`}
                                        style={{ textAlign: "center" }}
                                        onKeyUp={(e) => onKeyUpFunc(e, index)}
                                        value={formValues[`confirmationCode${index + 1}` as "confirmationCode1"]}
                                        onChange={onChange(`confirmationCode${index + 1}` as "confirmationCode1")}
                                    />)
                                }
                                {
                                    confirmationState.email === formValues.email && confirmationState.state === "sent" ?
                                        <Group direction="row" align="center" wrap="nowrap" gap="xs" className={`sent-code`}><SendCheckFill /> {t('emailSent')}</Group> :
                                        <>
                                            {
                                                isSendingCode ? <span className="sending-code">{t('sending')}</span> :
                                                    <span onClick={sendAuthCode} className={`send-code ${isEmail(formValues.email) ? '' : 'disabled'}`}>{t('sendCode')}</span>
                                            }
                                        </>
                                }

                            </Group>
                            {getAuthenticationCodeError() && <StyledErrorText style={{ marginTop: '-7px' }}>{getAuthenticationCodeError()}</StyledErrorText>}
                        </Group>
                        <TextInput
                            isRequired
                            type="password"
                            label={t('password')}
                            error={errors.password}
                            placeholder={t('password')}
                            value={formValues.password}
                            leftIcon={<Lock size={18} />}
                            onChange={onChange("password")}
                        />
                        <TextInput
                            isRequired
                            type="password"
                            label={t('confirmPassword')}
                            error={errors.confirmPassword}
                            leftIcon={<Lock size={18} />}
                            placeholder={t('confirmPassword')}
                            value={formValues.confirmPassword}
                            onChange={onChange("confirmPassword")}
                        />
                        <Button text={t('signUp')} type="submit" disabled={isSigningUp} loading={isSigningUp} />
                        <Group direction="row" gap="xs" wrap="nowrap">
                            <span className="lbl">{t('alreadyAUser')}</span>
                            <a href="/auth/sign-in">{t('loginHere')}</a>
                        </Group>
                    </Group>
                </form>
            </>
        </Group>
    </StyledAuthContainer>)
}

export const AuthResetPassword = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { notify } = useNotification();
    const { sendAuthenticationCode, resetPassword } = useApi();
    const { onSubmit, onChange, errors, formValues, setErrors } = useForm({
        defaultValues: {
            email: "",
            password: "",
            confirmPassword: "",
            confirmationCode1: "",
            confirmationCode2: "",
            confirmationCode3: "",
            confirmationCode4: "",
            confirmationCode5: "",
            confirmationCode6: "",
        },
        validations: {
            password: { required: true, min: 9 },
            email: { email: true, required: true },
            confirmPassword: { required: true, min: 9 },
            confirmationCode1: { max: 9, required: true },
            confirmationCode2: { max: 9, required: true },
            confirmationCode3: { max: 9, required: true },
            confirmationCode4: { max: 9, required: true },
            confirmationCode5: { max: 9, required: true },
            confirmationCode6: { max: 9, required: true },
        }
    })
    const [confirmationState, setConfirmationState] = useState<{ email: string; state: "pending" | "sent"; }>({ email: "", state: "pending" });
    const { isPending: isSendingCode, mutateAsync: sendAuthenticationCodeAsync } = useMutation({
        mutationFn: sendAuthenticationCode
    })
    const { isPending: resetting, mutateAsync: resetPasswordAsync } = useMutation({
        mutationFn: resetPassword
    })

    const resetPasswordFunc = async () => {
        try {
            if (formValues.password !== formValues.confirmPassword) {
                setErrors((err) => ({
                    ...err,
                    ...{ password: t('passwordFieldsDoNotMatch'), confirmPassword: t('passwordFieldsDoNotMatch') },
                }))
                return
            }
            if (await resetPasswordAsync({ email: formValues.email, password: formValues.password, code: [formValues.confirmationCode1, formValues.confirmationCode2, formValues.confirmationCode3, formValues.confirmationCode4, formValues.confirmationCode5, formValues.confirmationCode6].join("") })) {
                notify({
                    type: "success",
                    title: t('success'),
                    body: t('passwordResetSuccess'),
                });
                navigate(`/auth/sign-in`)
            }
        } catch (error) {
        }
    };

    const onKeyUpFunc = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (index !== 5 && (event.key === "Enter" || (event.key !== "Backspace" && event.key !== "Tab"))) {
            event.preventDefault();
            event.stopPropagation();
            document.getElementById(`confirmationCode${index + 1}`)?.focus()
            return
        }
        if (index !== 0 && event.key === "Backspace") {
            document.getElementById(`confirmationCode${index - 1}`)?.focus()
            return
        }
    }

    const getAuthenticationCodeError = () => {
        if (errors.confirmationCode1 || errors.confirmationCode2 || errors.confirmationCode3 || errors.confirmationCode4 || errors.confirmationCode5 || errors.confirmationCode6) {
            return t('authenticationCodeError')
        }
        return ''
    }

    const sendAuthCode = useCallback(async () => {
        if (!isEmail(formValues.email)) return
        try {
            const { email } = formValues
            await sendAuthenticationCodeAsync({ email: formValues.email, codeType: "password-reset" })
            setConfirmationState({ email, state: "sent" });
            setTimeout(() => {
                if (formValues.email === email) {
                    setConfirmationState({ email, state: "pending" })
                }
            }, 1000 * 60)
        } catch (error) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationState.email, formValues])


    return (<StyledAuthContainer align="center" justify="center">
        <Group className="auth-wrapper">
            <>
                <p>{t('resetPassword')}</p>
                <p>{t('resetPasswordDescription')}</p>
                <br />
                <form onSubmit={onSubmit(resetPasswordFunc)}>
                    <Group>
                        <TextInput
                            isRequired
                            type="email"
                            label={t('email')}
                            error={errors.email}
                            placeholder={t('email')}
                            value={formValues.email}
                            leftIcon={<At size={18} />}
                            onChange={onChange("email")}
                        />
                        <Group gap="sm">
                            <StyledLabel>{t('authenticationCode')}<StyledIsRequired>*</StyledIsRequired></StyledLabel>
                            <Group direction="row" align="center" wrap="nowrap">
                                {
                                    [0, 1, 2, 3, 4, 5].map((_, index) => <TextInput key={index}
                                        max={9}
                                        isRequired
                                        type="number"
                                        maxLength={1}
                                        id={`confirmationCode${index}`}
                                        style={{ textAlign: "center" }}
                                        value={formValues[`confirmationCode${index + 1}` as "confirmationCode1"]}
                                        onChange={onChange(`confirmationCode${index + 1}` as "confirmationCode1")}
                                        onKeyUp={(e) => onKeyUpFunc(e, index)}
                                    />)
                                }
                                {
                                    confirmationState.email === formValues.email && confirmationState.state === "sent" ?
                                        <Group direction="row" align="center" wrap="nowrap" gap="xs" className={`sent-code`}><SendCheckFill /> {t('emailSent')}</Group> :
                                        <>
                                            {
                                                isSendingCode ? <span className={`sending-code`}>{t('sending')}</span> :
                                                    <span onClick={sendAuthCode} className={`send-code ${isEmail(formValues.email) ? '' : 'disabled'}`}>{t('sendCode')}</span>
                                            }
                                        </>
                                }

                            </Group>
                            {getAuthenticationCodeError() && <StyledErrorText style={{ marginTop: '-7px' }}>{getAuthenticationCodeError()}</StyledErrorText>}
                        </Group>
                        <TextInput
                            isRequired
                            type="password"
                            label={t('password')}
                            error={errors.password}
                            placeholder={t('password')}
                            leftIcon={<Lock size={18} />}
                            value={formValues.password}
                            onChange={onChange("password")}
                        />
                        <TextInput
                            isRequired
                            type="password"
                            label={t('confirmPassword')}
                            error={errors.confirmPassword}
                            placeholder={t('confirmPassword')}
                            leftIcon={<Lock size={18} />}
                            value={formValues.confirmPassword}
                            onChange={onChange("confirmPassword")}
                        />
                        <Button text={t('resetPassword')} type="submit" disabled={resetting} loading={resetting} />
                        <Group direction="row" gap="xs" wrap="nowrap">
                            <span className="lbl">{t('iRememberMyPassword')}</span>
                            <a href="/auth/sign-in">{t('loginHere')}</a>
                        </Group>
                    </Group>
                </form>
            </>
        </Group>
    </StyledAuthContainer>)
}