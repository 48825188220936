import Moment from "react-moment";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../hooks/use-api";
import Card from "../../../components/Card/Card";
import { Accordion } from "@szhsin/react-accordion";
import { AuthContext } from "../../../context/auth";
import { Group } from "../../../layout/Group/Group";
import { useForm } from "../../../hooks/use-form.hook";
import { useContext, useEffect, useState } from "react";
import Loading from "../../../components/Loading/Loading";
import Currency from "../../../components/Currency/Currency";
import { useMutation, useQuery } from "@tanstack/react-query";
import OffCanvas from "../../../components/OffCanvas/OffCanvas";
import { Switch } from "../../../components/Form/Switch/Switch";
import { useNotification } from "../../../hooks/use-notification.hook";
import { Button, OutlineButton } from "../../../components/Form/Button";
import { TextInput } from "../../../components/Form/TextInput/TextInput";
import { currenciesPrepped, DropdownValues, SelectInput } from "../../../components/Form/Select/SelectInput";
import { Boxes, CardText, InfoCircleFill, Pencil, PlusLg, Search, Tag, TextLeft } from "react-bootstrap-icons";
import { forPhoneOnly, StyledAccordionItem, StyledSwitchContainer, StyledTableWrapper } from "../../../elements";
import { enumValuesToArray, ProductGroups, ProductInterface, ProductPriceInterface, ProductSubscriptionDurations, ProductTypes, Status, SubscriptionPlan, subscriptionsPlans } from "shared-library";

const StyledGroup = styled(Group)`
    padding: 20px;
    
    .add-container {
        cursor: pointer;
        font-size: 14px;
        padding: 80px 40px;
        letter-spacing: 4px;
        text-transform: uppercase;
        ${forPhoneOnly("padding: 40px 15px;")}
        color: ${props => props.theme.colors.gray};
    }

    .search-input {
        width: 350px;
    }

    .bar {
        .navLink {
            font-size: 12px;
            cursor: pointer;
            padding-bottom: 4px;
            transition: all 0.5s;
            text-transform: uppercase;
            border-bottom: 1px solid transparent;
            color: ${props => props.theme.colors.black};

            &.active {
                border-bottom-color: ${props => props.theme.colors.secondary};
            }
        }
    }

    .product-container {
        padding: 0px 10px;
    }
`;

const ProductGroupDiv = styled(Group)`
    .product-details {
        margin: 20px 0;
        border-radius: 4px;
        padding: 20px;
        background-color: ${props => props.theme.colors.gray50};
        border: 1px solid ${props => props.theme.colors.gray10};
    }
`
const productGroup = ProductGroups.UserSubscription;

const AdminProducts = () => {

    const { t } = useTranslation();
    const { getProducts } = useApi();
    const [query, setQuery] = useState('');
    const { state, setPreferences } = useContext(AuthContext);
    const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false);

    const { data: products, refetch } = useQuery({
        staleTime: Infinity,
        queryFn: getProducts,
        queryKey: ["adminProducts"],
    });

    return (
        <StyledGroup className="container-fluid">
            <ProductCreatorEditor offCanvasIsOpen={offCanvasIsOpen} setOffCanvasIsOpen={setOffCanvasIsOpen} onDone={refetch} />
            <Group gap="md" className="container">

                <Group direction="row">
                    <Card className="add-container" onClick={() => setOffCanvasIsOpen(!offCanvasIsOpen)}>
                        <Group direction="row" wrap="nowrap">
                            <PlusLg size={18} />
                            {t('newProduct')}
                        </Group>
                    </Card>
                </Group>
                <Group direction="row" justify="space-between" className="bar">
                    <TextInput
                        isRequired
                        type="text"
                        value={query}
                        onChange={setQuery}
                        className="search-input"
                        placeholder={t('search')}
                        leftIcon={<Search size={18} />}
                    />
                    <Group direction="row" align="center" gap="sm" justify="center">
                        {
                            ([Status.active, Status.inactive]).map(status => <small key={status} onClick={() => setPreferences({ ...state.preferences, adminProductStatus: status })} className={"navLink" + (state.preferences.adminProductStatus === status ? ' active' : '')}>{t(status)}</small>)
                        }
                    </Group>
                </Group>
                <Group className="product-container" gap="sm">
                    {
                        !products ? <Loading /> : <>
                            {
                                products.data.filter(t => t.productGroup === productGroup && t.status === state.preferences.adminProductStatus && (!query.trim() || JSON.stringify(t).toLowerCase().includes(query.toLowerCase()))).map(product => <ProductDom key={product.id} product={product} onChange={refetch} />)
                            }
                        </>
                    }
                </Group>
            </Group>
        </StyledGroup>
    )
}

export default AdminProducts;

const emptyPrice = (productId: string): ProductPriceInterface => {
    return {
        id: "",
        rank: 0,
        title: "",
        price: 0,
        oldPrice: 0,
        unitPrice: 0,
        status: Status.active,
        clientId: "",
        productId,
        totals: {
            amount: 0,
            purchases: 0
        },
        currency: "USD",
        creationDate: "",
        onSale: true,
        meta: {},
        benefit: {
            duration: {
                unit: 1,
                in: ProductSubscriptionDurations.MONTH
            }
        }
    }
}

const ProductDom = ({ product, onChange }: { product: ProductInterface; onChange?: () => void; }) => {

    const { t } = useTranslation();
    const [productOffCanvasIsOpen, setProductOffCanvasIsOpen] = useState(false);
    const [productPriceOffCanvasIsOpen, setProductPriceOffCanvasIsOpen] = useState<{ open: boolean; price: ProductPriceInterface }>({ open: false, price: emptyPrice(product.id) });
    const triggerNewProductPriceAdd = (price: ProductPriceInterface) => {
        setProductPriceOffCanvasIsOpen(d => ({ ...d, open: !d.open, price }))
    };

    return (
        <ProductGroupDiv gap="none">
            <ProductEditor offCanvasIsOpen={productOffCanvasIsOpen} setOffCanvasIsOpen={setProductOffCanvasIsOpen} onDone={onChange} product={product} />
            <ProductPriceEditor offCanvasIsOpen={productPriceOffCanvasIsOpen.open} setOffCanvasIsOpen={() => setProductPriceOffCanvasIsOpen({ open: false, price: emptyPrice(product.id) })} onDone={onChange} product={product} price={productPriceOffCanvasIsOpen.price} />

            <Accordion className="maxWidth">
                <StyledAccordionItem
                    header={
                        <Group direction="row" gap="sm" align="center" wrap="nowrap">
                            <Boxes />
                            <Group gap='none'>
                                <b>{product.title}</b>
                                <small>{product.prices.length} {t('prices').toLowerCase()}</small>
                            </Group>
                        </Group>
                    }>
                    <Group align="center" className="product-details" justify="space-between" direction="row" wrap="nowrap">
                        <Group direction="row" gap="md">
                            <Group gap="none">
                                <small>{t('createdOn')}</small>
                                <small><Moment format="MMM Do, YYYY, hh:mm">{product.creationDate}</Moment></small>
                            </Group>
                            <Group gap="none">
                                <small>{t('status')}</small>
                                <small>{t(product.status)}</small>
                            </Group>
                            <Group gap="none">
                                <small>{t('popular')}</small>
                                <small>{t(product.isPopular ? t('yes') : t('no'))}</small>
                            </Group>
                            <Group gap="none">
                                <small>{t('onSale')}</small>
                                <small>{t(product.onSale ? t('yes') : t('no'))}</small>
                            </Group>
                            <Group gap="none">
                                <small>{t('rank')}</small>
                                <small>{product.rank}</small>
                            </Group>
                        </Group>
                        <Group direction="row" className="pointer" align="center" gap="xs" wrap="nowrap" onClick={() => setProductOffCanvasIsOpen(!productOffCanvasIsOpen)}>
                            <Pencil size={12} />
                            <small>{t('edit')}</small>
                        </Group>
                    </Group>

                    <Group>
                        <Group direction="row" justify="space-between">
                            <h5>{t('prices')}</h5>
                            <OutlineButton text={t('addNew')} size="sm" icon={<PlusLg size={14} />} onClick={() => triggerNewProductPriceAdd(emptyPrice(product.id))} />
                        </Group>
                        {
                            product.prices.length > 0 && <StyledTableWrapper>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{t('title')}</th>
                                            {
                                                product.productType === ProductTypes.Recurring && <th>{t('duration')}</th>
                                            }
                                            <th>{t('rank')}</th>
                                            <th>{t('status')}</th>
                                            <th>{t('price')}</th>
                                            <th>{t('unitPrice')}</th>
                                            <th>{t('oldPrice')}</th>
                                            <th>{t('onSale')}</th>
                                            <th>{t('purchases')}</th>
                                            <th>{t('createdOn')}</th>
                                            <th>{t('actions')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            product.prices.map(price => <tr key={price.id}>
                                                <td>{price.title}</td>
                                                {
                                                    product.productType === ProductTypes.Recurring && <td>{t('every')} {price.benefit.duration?.unit} {t(price.benefit.duration?.in!)}</td>
                                                }
                                                <td>{price.rank}</td>
                                                <td>{t(price.status)}</td>
                                                <td>
                                                    <Currency
                                                        quantity={price.price}
                                                        currency={price.currency}
                                                    />
                                                </td>
                                                <td>
                                                    <Currency
                                                        quantity={price.unitPrice}
                                                        currency={price.currency}
                                                    />
                                                </td>
                                                <td>
                                                    <Currency
                                                        quantity={price.oldPrice}
                                                        currency={price.currency}
                                                    />
                                                </td>
                                                <td>{t(price.onSale ? t('yes') : t('no'))}</td>
                                                <td>{price.totals.purchases}</td>
                                                <td><Moment format="MMM Do, YYYY, hh:mm">{price.creationDate}</Moment></td>
                                                <td>
                                                    <Group direction="row" className="pointer" gap="xs" wrap="nowrap" onClick={() => triggerNewProductPriceAdd(price)}>
                                                        <Pencil size={12} />
                                                        <small>{t('edit')}</small>
                                                    </Group>
                                                </td>
                                            </tr>)
                                        }
                                    </tbody>
                                </table>
                            </StyledTableWrapper>
                        }
                    </Group>
                </StyledAccordionItem>
            </Accordion>
        </ProductGroupDiv>
    )
}

interface ProductCreatorEditorProps {
    onDone?: () => void;
    offCanvasIsOpen: boolean;
    product?: {
        rank: number;
        title: string;
        onSale: boolean;
        isPopular: boolean;
        productType: ProductTypes;
        productGroup: ProductGroups;
        meta: Record<string, string>;
        productCode: SubscriptionPlan;
    };
    setOffCanvasIsOpen: (v: boolean) => void;
}
const ProductCreatorEditor = ({
    product,
    offCanvasIsOpen,
    setOffCanvasIsOpen,
    onDone = () => null,
}: ProductCreatorEditorProps) => {

    const { t } = useTranslation();
    const { notify } = useNotification();
    if (!product) {
        product = {
            rank: 1,
            meta: {},
            productGroup,
            onSale: true,
            isPopular: false,
            title: t('basic'),
            productCode: "basic",
            productType: ProductTypes.Recurring,
        }
    }

    const { createProduct } = useApi();
    const { onSubmit, onChange, errors, formValues, reset } = useForm({
        defaultValues: product
    })

    const { isPending, mutateAsync: createAdminProductAsync } = useMutation({
        mutationFn: createProduct
    })

    const createProductFunc = async () => {
        try {
            formValues.title = t(formValues.productCode)
            formValues.rank = subscriptionsPlans.findIndex(c => c === formValues.productCode)
            await createAdminProductAsync(formValues)
            reset()
            setOffCanvasIsOpen(false)
            notify({ type: "success", title: t('success'), body: t('productSuccessfullyCreated') });
            onDone()
        } catch (error) {
        }
    };

    const subscriptionPlansPrepped = () => {
        const response: Array<DropdownValues> = [];
        for (const key of subscriptionsPlans) {
            const value = key as SubscriptionPlan;
            if (value === "free") continue;
            response.push({
                value,
                label: t(value),
            });
        }
        return response;
    };

    return (
        <OffCanvas
            isOpen={offCanvasIsOpen}
            title={t('newProduct')}
            backdrop={false}
            handleClose={() => setOffCanvasIsOpen(false)}
        >
            <form onSubmit={onSubmit(createProductFunc)}>
                <Group>
                    <SelectInput
                        searchable={false}
                        closeMenuOnSelect={true}
                        error={errors.productCode}
                        placeholder={t('productCode')}
                        value={formValues.productCode}
                        onChange={onChange("productCode")}
                        options={subscriptionPlansPrepped()}
                    />
                    <StyledSwitchContainer direction="row" justify="space-between" wrap="nowrap" align="center">
                        <Group direction="row" wrap="nowrap" align="center" gap="xs">
                            <small>{t('onSale')}</small>
                            <InfoCircleFill size={13} className="pointer onsale-hint" />
                        </Group>
                        <Switch
                            value={formValues.onSale}
                            onChange={onChange("onSale")}></Switch>
                    </StyledSwitchContainer>
                    <StyledSwitchContainer direction="row" justify="space-between" wrap="nowrap" align="center">
                        <Group direction="row" wrap="nowrap" align="center" gap="xs">
                            <small>{t('popular')}</small>
                            <InfoCircleFill size={13} className="pointer popular-hint" />
                        </Group>
                        <Switch
                            value={formValues.isPopular}
                            onChange={onChange("isPopular")}></Switch>
                    </StyledSwitchContainer>
                    <Button text={t('create')} type="submit" disabled={isPending} loading={isPending} />
                    <Tooltip anchorSelect=".onsale-hint" place="top">{t('thisProductIsOnSaleAndWillBeShown')}</Tooltip>
                    <Tooltip anchorSelect=".popular-hint" place="top">{t('thisProductIsPopular')}</Tooltip>
                </Group>
            </form>
        </OffCanvas>
    )
}

interface ProductEditorProps {
    onDone?: () => void;
    offCanvasIsOpen: boolean;
    product: ProductInterface;
    setOffCanvasIsOpen: (v: boolean) => void;
}
const ProductEditor = ({
    product,
    offCanvasIsOpen,
    setOffCanvasIsOpen,
    onDone = () => null
}: ProductEditorProps) => {

    const { t } = useTranslation();
    const { notify } = useNotification();

    const { updateProduct } = useApi();
    const { onSubmit, onChange, errors, formValues, reset } = useForm({
        defaultValues: product,
        validations: {
            title: { required: true },
            status: { required: true },
        }
    })

    const { isPending, mutateAsync: updateAdminProductAsync } = useMutation({
        mutationFn: updateProduct
    })

    const updateProductFunc = async () => {
        try {
            await updateAdminProductAsync(formValues)
            reset()
            setOffCanvasIsOpen(false)
            notify({ type: "success", title: t('success'), body: t('productSuccessfullyUpdated') });
            onDone()
        } catch (error) {
        }
    };
    return (
        <OffCanvas
            isOpen={offCanvasIsOpen}
            title={t('editProduct')}
            backdrop={false}
            handleClose={() => setOffCanvasIsOpen(false)}
        >
            <form onSubmit={onSubmit(updateProductFunc)}>
                <Group>
                    <TextInput
                        isRequired
                        type="text"
                        readonly
                        error={errors.title}
                        placeholder={t('title')}
                        value={formValues.title}
                        leftIcon={<TextLeft size={16} />}
                        onChange={onChange("title")}
                    />

                    <StyledSwitchContainer direction="row" justify="space-between" wrap="nowrap" align="center">
                        <Group direction="row" wrap="nowrap" align="center" gap="xs">
                            <small>{t(Status.active)}</small>
                            <InfoCircleFill size={13} className="pointer status-hint" />
                        </Group>
                        <Switch
                            value={formValues.status === Status.active}
                            onChange={(active) => active ? onChange("status")(Status.active) : onChange("status")(Status.inactive)}></Switch>
                    </StyledSwitchContainer>

                    <StyledSwitchContainer direction="row" justify="space-between" wrap="nowrap" align="center">
                        <Group direction="row" wrap="nowrap" align="center" gap="xs">
                            <small>{t('onSale')}</small>
                            <InfoCircleFill size={13} className="pointer onsale-hint" />
                        </Group>
                        <Switch
                            value={formValues.onSale}
                            onChange={onChange("onSale")}></Switch>
                    </StyledSwitchContainer>

                    <StyledSwitchContainer direction="row" justify="space-between" wrap="nowrap" align="center">
                        <Group direction="row" wrap="nowrap" align="center" gap="xs">
                            <small>{t('popular')}</small>
                            <InfoCircleFill size={13} className="pointer popular-hint" />
                        </Group>
                        <Switch
                            value={formValues.isPopular}
                            onChange={onChange("isPopular")}></Switch>
                    </StyledSwitchContainer>
                    <Tooltip anchorSelect=".status-hint" place="top">{t('thisProductIsActive')}</Tooltip>
                    <Tooltip anchorSelect=".popular-hint" place="top">{t('thisProductIsPopular')}</Tooltip>
                    <Tooltip anchorSelect=".onsale-hint" place="top">{t('thisProductIsOnSaleAndWillBeShown')}</Tooltip>

                    <Button text={t('update')} type="submit" disabled={isPending} loading={isPending} />
                </Group>
            </form>
        </OffCanvas>
    )
}

interface ProductPriceEditorProps {
    onDone?: () => void;
    offCanvasIsOpen: boolean;
    product: ProductInterface;
    price: ProductPriceInterface;
    setOffCanvasIsOpen: (v: boolean) => void;
}
const ProductPriceEditor = ({
    price,
    product,
    offCanvasIsOpen,
    setOffCanvasIsOpen,
    onDone = () => null
}: ProductPriceEditorProps) => {

    const { t } = useTranslation();
    const { notify } = useNotification();

    const { updateProductPrice, createProductPrice } = useApi();
    const { onSubmit, onChange, errors, formValues, reset, setFormValues } = useForm({
        defaultValues: {
            id: price.id,
            rank: price.rank,
            title: price.title,
            price: price.price,
            status: price.status,
            onSale: price.onSale,
            oldPrice: price.oldPrice,
            currency: price.currency,
            unitPrice: price.unitPrice,
            benefitDurationIn: price.benefit.duration!.in,
            benefitDurationUnit: price.benefit.duration!.unit,
        },
        validations: {
            rank: { required: true },
            price: { required: true },
            status: { required: true },
            currency: { required: true },
        }
    })

    const { isPending, mutateAsync: createAdminProductPriceAsync } = useMutation({
        mutationFn: createProductPrice
    })

    const { isPending: isUpdating, mutateAsync: updateAdminProductPriceAsync } = useMutation({
        mutationFn: updateProductPrice
    })

    useEffect(() => {
        setFormValues({
            id: price.id,
            rank: price.rank,
            title: price.title,
            price: price.price,
            status: price.status,
            onSale: price.onSale,
            oldPrice: price.oldPrice,
            currency: price.currency,
            unitPrice: price.unitPrice,
            benefitDurationIn: price.benefit.duration!.in,
            benefitDurationUnit: price.benefit.duration!.unit,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [price])

    const updateProductPriceFunc = async () => {
        try {

            const { benefitDurationIn, benefitDurationUnit, ...rest } = formValues;
            formValues.title = `${product.title} / ${benefitDurationIn} / ${formValues.currency}`.toUpperCase();
            const combinedPrice: ProductPriceInterface = {
                ...price,
                ...rest,
                title: formValues.title,
                price: +formValues.price,
                oldPrice: +formValues.oldPrice,
                unitPrice: +formValues.unitPrice,
                benefit: {
                    ...price.benefit,
                    duration: {
                        ...price.benefit.duration,
                        in: benefitDurationIn,
                        unit: benefitDurationUnit
                    }
                }
            }

            if (formValues.id) {
                await updateAdminProductPriceAsync(combinedPrice)
            } else {
                await createAdminProductPriceAsync(combinedPrice)
            }
            reset()
            setOffCanvasIsOpen(false)
            notify({ type: "success", title: t('success'), body: t(formValues.id ? 'productPriceSuccessfullyUpdated' : 'productPriceSuccessfullyCreated') });
            onDone()
        } catch (error) {
        }
    };

    const durationsPrepped = () => enumValuesToArray(ProductSubscriptionDurations).map(duration => ({
        label: t(duration),
        value: duration
    }))

    return (
        <OffCanvas
            isOpen={offCanvasIsOpen}
            title={t(formValues.id ? 'editPrice' : 'createPrice')}
            backdrop={false}
            handleClose={() => setOffCanvasIsOpen(false)}
        >
            <form onSubmit={onSubmit(updateProductPriceFunc)}>
                <Group>
                    {
                        !!formValues.id && <TextInput
                            isRequired
                            type="text"
                            readonly
                            label={t('title')}
                            error={errors.title}
                            placeholder={t('title')}
                            value={`${formValues.title}`}
                            leftIcon={<CardText size={16} />}
                            onChange={onChange("title")}
                        />
                    }

                    <TextInput
                        isRequired
                        type="number"
                        label={t('rank')}
                        error={errors.rank}
                        placeholder={t('rank')}
                        onChange={onChange("rank")}
                        value={`${formValues.rank}`}
                    />

                    <Group direction="row" wrap="nowrap">
                        <SelectInput
                            searchable={false}
                            label={t('currency')}
                            error={errors.currency}
                            closeMenuOnSelect={true}
                            disabled={!!formValues.id}
                            value={formValues.currency}
                            placeholder={t('currency')}
                            parentStyle={{ flexGrow: 1 }}
                            options={currenciesPrepped()}
                            onChange={onChange("currency")}
                        />

                        <TextInput
                            isRequired
                            type="number"
                            label={t('price')}
                            error={errors.price}
                            placeholder={t('price')}
                            leftIcon={<Tag size={16} />}
                            onChange={onChange("price")}
                            value={`${formValues.price}`}
                            parentStyle={{ maxWidth: 215 }}
                            readonly={price.totals.purchases > 0}
                        />
                    </Group>
                    <Group direction="row" wrap="nowrap">
                        <TextInput
                            isRequired
                            type="number"
                            label={t('unitPrice')}
                            error={errors.unitPrice}
                            parentStyle={{ flexGrow: 1 }}
                            placeholder={t('unitPrice')}
                            onChange={onChange("unitPrice")}
                            value={`${formValues.unitPrice}`}
                        />

                        <TextInput
                            isRequired
                            type="number"
                            label={t('oldPrice')}
                            error={errors.oldPrice}
                            placeholder={t('oldPrice')}
                            parentStyle={{ maxWidth: 250 }}
                            onChange={onChange("oldPrice")}
                            value={`${formValues.oldPrice}`}
                        />
                    </Group>
                    {
                        product.productType === ProductTypes.Recurring && <Group direction="row" wrap="nowrap">
                            <TextInput
                                isRequired
                                type="number"
                                label={t('duration')}
                                readonly={!!formValues.id}
                                placeholder={t('duration')}
                                parentStyle={{ maxWidth: 250 }}
                                error={errors.benefitDurationUnit}
                                onChange={onChange("benefitDurationUnit")}
                                value={`${formValues.benefitDurationUnit}`}
                            />
                            <SelectInput
                                searchable={false}
                                parentStyle={{ flexGrow: 1 }}
                                label={t('durationIn')}
                                error={errors.benefitDurationIn}
                                closeMenuOnSelect={true}
                                disabled={!!formValues.id}
                                value={formValues.benefitDurationIn}
                                placeholder={t('durationIn')}
                                options={durationsPrepped()}
                                onChange={onChange("benefitDurationIn")}
                            />
                        </Group>
                    }

                    {
                        !!formValues.id && <StyledSwitchContainer direction="row" justify="space-between" wrap="nowrap" align="center">
                            <Group direction="row" wrap="nowrap" align="center" gap="xs">
                                <small>{t(Status.active)}</small>
                                <InfoCircleFill size={13} className="pointer status-hint" />
                            </Group>
                            <Switch
                                value={formValues.status === Status.active}
                                onChange={(active) => active ? onChange("status")(Status.active) : onChange("status")(Status.inactive)}></Switch>
                        </StyledSwitchContainer>
                    }

                    <StyledSwitchContainer direction="row" justify="space-between" wrap="nowrap" align="center">
                        <Group direction="row" wrap="nowrap" align="center" gap="xs">
                            <small>{t('onSale')}</small>
                            <InfoCircleFill size={13} className="pointer onsale-hint" />
                        </Group>
                        <Switch
                            value={formValues.onSale}
                            onChange={onChange("onSale")}></Switch>
                    </StyledSwitchContainer>

                    <Tooltip anchorSelect=".status-hint" place="top">{t('thisProductPriceIsActive')}</Tooltip>
                    <Tooltip anchorSelect=".onsale-hint" place="top">{t('thisProductPriceIsOnSaleAndWillBeShown')}</Tooltip>
                    <Button text={t(formValues.id ? 'update' : 'create')} type="submit" disabled={isUpdating || isPending} loading={isUpdating || isPending} />
                </Group>
            </form>
        </OffCanvas>
    )
}