import { ReactNode } from "react";
import styled from "styled-components";
import { Subscription } from "shared-library";
import { useTranslation } from "react-i18next";
import { Group } from "../../layout/Group/Group";
import { Check2, XLg } from "react-bootstrap-icons";

const StyledGroup = styled(Group)`
    .features-li {
        font-size: 12px;
        color: ${props => props.theme.colors.gray};
    }
`

interface SubscriptionFeaturesProp {
    icon?: ReactNode;
    subscription: Subscription;
}
const SubscriptionFeatures = ({ subscription, icon = <Check2 size={16} /> }: SubscriptionFeaturesProp) => {
    const { t } = useTranslation();
    const forceGB = () => {
        if (subscription.features.limits.storageInGB >= 1) return t('gbTotalStorage', { size: subscription.features.limits.storageInGB })
        return t('mbTotalStorage', { size: Math.floor(parseInt(`${subscription.features.limits.storageInGB * 1024}`)) })
    }
    return (
        <StyledGroup gap="xs">
            <Group direction="row" wrap="nowrap" align="center" gap="xs" className="features-li"><span>{icon}</span> <span><b>{subscription.features.limits.projects || t('unlimited')}</b> {t('websites').toLowerCase()}</span></Group>
            <Group direction="row" wrap="nowrap" align="center" gap="xs" className="features-li"><span>{icon}</span> <span dangerouslySetInnerHTML={{ __html: forceGB() }}></span></Group>
            <Group direction="row" wrap="nowrap" align="center" gap="xs" className="features-li"><span>{icon}</span> <span><b>{subscription.features.limits.pagesPerProject || t('unlimited')}</b> {t('pagesPerProject').toLowerCase()}</span></Group>
            <Group direction="row" wrap="nowrap" align="center" gap="xs" className="features-li"><span>{icon}</span> <span><b>{subscription.features.limits.monthlyVisits ? subscription.features.limits.monthlyVisits.toLocaleString() : t('unlimited')}</b> {t('monthlyVisits').toLowerCase()}</span></Group>
            <Group direction="row" wrap="nowrap" align="center" gap="xs" className="features-li"><span>{icon}</span> <span>{t(`${subscription.features.support}Support`)}</span></Group>
            <Group direction="row" wrap="nowrap" align="center" gap="xs" className="features-li"><span>{subscription.features.sdk.seo ? icon : <XLg size={12} />}</span> <span>{t('SEOTools')}</span></Group>
            <Group direction="row" wrap="nowrap" align="center" gap="xs" className="features-li"><span>{subscription.features.shareProject ? icon : <XLg size={12} />}</span> <span>{t('shareProject')}</span></Group>
            <Group direction="row" wrap="nowrap" align="center" gap="xs" className="features-li"><span>{subscription.features.sdk.exportProject ? icon : <XLg size={12} />}</span> <span>{t('exportProject')}</span></Group>
        </StyledGroup>
    )
}

export default SubscriptionFeatures