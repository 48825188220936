"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateSlug = void 0;
const generateSlug = (text) => {
    if (!text)
        return "";
    return text
        .toLowerCase() // Convert to lowercase
        .trim() // Remove any leading or trailing whitespace
        .replace(/[\s\W-]+/g, '-') // Replace spaces, special characters, and dashes with a single hyphen
        .replace(/^-+|-+$/g, ''); // Remove any leading or trailing hyphens
};
exports.generateSlug = generateSlug;
