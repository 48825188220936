import { currencies } from "shared-library";
import { useTheme } from "styled-components";
import { CSSProperties, ReactNode } from "react";
import { InputContainer } from "../InputContainer/InputContainer";
import Select, { GroupBase, OptionsOrGroups } from 'react-select';
import { FormComponentProps } from "../../../utils/types/form-component-props";


interface SelectInputProps extends FormComponentProps {
    id?: string;
    label?: string;
    error?: string;
    tooltip?: string;
    isMulti?: boolean;
    disabled?: boolean;
    className?: string;
    clearable?: boolean;
    placeholder?: string;
    leftIcon?: ReactNode;
    isRequired?: boolean;
    description?: string;
    searchable?: boolean;
    rightIcon?: ReactNode;
    showErrorMsg?: boolean;
    closeMenuOnSelect?: boolean;
    parentStyle?: CSSProperties;
    onChange: (newValue: any | any[]) => void;
    options: OptionsOrGroups<unknown, GroupBase<unknown>>;
    value?: string | string[] | DropdownValues | DropdownValues[];
}

export const SelectInput = (props: SelectInputProps) => {
    const theme = useTheme()
    const {
        id,
        value,
        label,
        error,
        tooltip,
        options,
        onChange,
        leftIcon,
        rightIcon,
        isRequired,
        parentStyle,
        description,
        placeholder,
        className = "",
        isMulti = false,
        disabled = false,
        searchable = true,
        clearable = false,
        showErrorMsg = true,
        closeMenuOnSelect = false
    } = props;

    const classNames = `${className} ${error ? 'hasError' : ''}`;

    const customStyles = {
        control: (base: CSSProperties, state: { isFocused: boolean }) => ({
            ...base,
            padding: "4px 0",
            fontSize: "16px",
            // background: "transparent",
            // match with the menu
            color: theme.colors.gray,
            borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            // Overwrites the different states of border
            borderColor: theme.colors.gray10,
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrites the different states of border
                borderColor: theme.colors.gray10,
            }
        }),
        input: (styles: CSSProperties) => ({ ...styles, color: theme.colors.black }),
        singleValue: (styles: CSSProperties) => ({ ...styles, color: theme.colors.black }),
        placeholder: (styles: CSSProperties) => ({ ...styles, color: theme.colors.gray10 }),
        multiValueLabel: (styles: CSSProperties) => ({
            ...styles,
            backgroundColor: theme.colors.gray10,
        }),
        multiValueRemove: (styles: CSSProperties) => ({
            ...styles,
            cursor: "pointer",
            transition: "all 0.25s",
            backgroundColor: theme.colors.gray10,
            ':hover': {
                backgroundColor: theme.colors.gray10,
                color: 'red',
            },
        }),
        menu: (base: CSSProperties) => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0
        }),
        menuList: (base: CSSProperties) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        })
    };

    return (
        <InputContainer
            id={id}
            label={label}
            tooltip={tooltip}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            description={description}
            error={showErrorMsg ? error : ""}
            isRequired={isRequired}
            style={parentStyle}
            render={(ID) => (
                <Select
                    id={ID}
                    options={options}
                    isMulti={isMulti}
                    isDisabled={disabled}
                    className={classNames}
                    isClearable={clearable}
                    isSearchable={searchable}
                    placeholder={placeholder}
                    styles={customStyles as any}
                    closeMenuOnSelect={closeMenuOnSelect}
                    onChange={(v: any) => onChange(v?.value || v || "")}
                    value={isMulti ? value : options.find(option => option === value || (option as any)?.value === value)} />
            )}
        />
    );
};

export interface DropdownValues { label: string, value: string }
export const currenciesPrepped = () => {
    const response: Array<DropdownValues> = [];
    for (const value of currencies) {
        response.push({
            value,
            label: value,
        });
    }
    return response;
};