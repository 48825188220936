import { ProductInterval, ProductSubscriptionDurations } from "shared-library";

const getPreppedInterval = (duration: ProductInterval) => {
    type FtwIntervals = "hourly" | "daily" | "weekly" | "monthly" | "yearly";
    const prepped: Record<ProductSubscriptionDurations, FtwIntervals> = {
        day: "daily",
        year: "yearly",
        week: "weekly",
        month: "monthly",
    }
    return prepped[duration.in]
}

export const getDaysInDuration = (duration: ProductInterval) => {
    const prepped: Record<ProductSubscriptionDurations, number> = {
        day: 1,
        week: 7,
        year: 365,
        month: 30,
    }
    return prepped[duration.in]
}

export default getPreppedInterval;