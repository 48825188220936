import { useTranslation } from "react-i18next";
import { Person } from "react-bootstrap-icons";
import { Group } from "../../layout/Group/Group";
import { useForm } from "../../hooks/use-form.hook";
import { countries, UserBillingAddress } from "shared-library";
import { TextInput } from "../../components/Form/TextInput/TextInput";
import { SelectInput } from "../../components/Form/Select/SelectInput";

interface BillingAddressFormProp {
    data: UserBillingAddress;
    onDataChange: (data: UserBillingAddress) => void;
}
const BillingAddressForm = ({ data, onDataChange }: BillingAddressFormProp) => {

    const { t } = useTranslation();
    const { onChange, errors, formValues } = useForm({
        defaultValues: data,
        validations: {
            zip: { required: true },
            city: { required: true },
            country: { required: true },
            fullName: { required: true },
            address1: { required: true },
        }
    })

    return <>
        <Group gap="xs">
            <TextInput
                isRequired
                type="text"
                label={t('fullName')}
                error={errors.fullName}
                placeholder={t('fullName')}
                onChange={v => { onChange("fullName")(v); onDataChange({ ...formValues, fullName: v }) }}
                value={formValues.fullName}
                leftIcon={<Person size={16} />}
            />

            <Group gap="xs">
                <TextInput
                    isRequired
                    type="text"
                    label={t('address')}
                    error={errors.address1}
                    placeholder={t('address1')}
                    onChange={v => { onChange("address1")(v); onDataChange({ ...formValues, address1: v }) }}
                    value={formValues.address1}
                />
                <TextInput
                    type="text"
                    error={errors.address2}
                    placeholder={t('address2')}
                    onChange={v => { onChange("address2")(v); onDataChange({ ...formValues, address2: v }) }}
                    value={formValues.address2}
                />
            </Group>

            <Group gap="xs" direction="row" wrap="nowrap">
                <TextInput
                    isRequired
                    type="text"
                    label={t('city')}
                    error={errors.city}
                    placeholder={t('city')}
                    onChange={v => { onChange("city")(v); onDataChange({ ...formValues, city: v }) }}
                    value={formValues.city}
                />

                <TextInput
                    isRequired
                    type="text"
                    label={t('zipOrPostalCode')}
                    error={errors.zip}
                    placeholder={t('zipOrPostalCode')}
                    onChange={v => { onChange("zip")(v); onDataChange({ ...formValues, zip: v }) }}
                    value={formValues.zip}
                />

            </Group>
            <TextInput
                type="text"
                label={t('stateOrProvinve')}
                error={errors.state}
                placeholder={t('stateOrProvinve')}
                onChange={v => { onChange("state")(v); onDataChange({ ...formValues, state: v }) }}
                value={formValues.state}
            />

            <SelectInput
                label={t('country')}
                error={errors.country}
                closeMenuOnSelect={true}
                placeholder={t('country')}
                value={formValues.country}
                onChange={v => { onChange("country")(v); onDataChange({ ...formValues, country: v }) }}
                options={countries.map(({ countryCode, name }) => ({ label: name, value: countryCode }))}
            />
        </Group>
    </>
}

export default BillingAddressForm