import { AuthContext } from '../context/auth';
import { useCallback, useContext } from 'react';

export const useRouteResolver = () => {

    const { setUser, state } = useContext(AuthContext);
    const dashboard = useCallback((): string => {
        if (state.user?.userTypes.length) {
            if (state.user.currentUserType && !state.user.userTypes.includes(state.user.currentUserType)) return `/access-denied`;
            if (state.preferences.whereILeftOff.beforeAuthFlow.payment?.pricing) return `/pricing`
            if (state.user.currentUserType) return `/${state.user.currentUserType}/dashboard`
            if (state.user.userTypes.length === 1) {
                //TODO:: Does not work as it's suppose to
                setUser({ ...state.user, currentUserType: state.user.userTypes[0] })
                return `/${state.user.userTypes[0]}/dashboard`
            }
            return `/profiles`
        }
        return `/auth/sign-in`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.preferences.whereILeftOff.beforeAuthFlow.payment, state.user])

    return { dashboard }
}