import { DeploymentEnvironments } from "shared-library";

export const Config = {
    APIKey: process.env.REACT_APP_APIKey as string,
    WebURL: process.env.REACT_APP_WebURL as string,
    IsProd: process.env.REACT_APP_IsProd === "true",
    AppBaseURL: process.env.REACT_APP_HOST as string,
    APIBaseUrl: process.env.REACT_APP_APIBaseUrl as string,
    UserPoolId: process.env.REACT_APP_USER_POOL_ID as string,
    UserPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID as string,
    Environment: process.env.REACT_APP_ENVIRONMENT as DeploymentEnvironments,
}