interface CurrencyProps {
    quantity: number;
    currency?: string;
}
const Currency = ({ quantity, currency }: CurrencyProps) => {
    return <>
        {
            new Intl.NumberFormat(undefined, {
                style: 'currency',
                currencyDisplay: 'narrowSymbol',
                currency,
            }).format(quantity)
        }
    </>
}

export default Currency