import Modal from 'react-modal';
import { Button } from '../Form/Button';
import Loading from '../Loading/Loading';
import Currency from '../Currency/Currency';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth';
import { Group } from '../../layout/Group/Group';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronRight } from 'react-bootstrap-icons';
import styled, { useTheme } from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import SubscriptionFeatures from '../../pages/Pricing/SubscriptionFeatures';
import { Pricings, ProductSubscriptionDurations, SubscriptionPlan } from 'shared-library';
import { Divider, StyledCloseButton, StyledTitle, StyledTitleDescDiv } from '../../elements';

const StyledGroup = styled(Group)`
    min-width: 350px;

    .plan {
        padding: 30px 14px;
        border-radius: 4px;
        border: 2px solid ${props => props.theme.colors.gray10};

        &.active {
            border-color: ${props => props.theme.colors.secondary};
        }

        .price {
            small {
                font-size: 12px;
                opacity: 0.5;
                color: ${props => props.theme.colors.black};
            }
        }
    }

    .plan-footer {
        a {
        text-align: right;
            font-size: 14px;
            margin-bottom: 10px;
            text-decoration: none;
            color: ${props => props.theme.colors.black};
            span {
                padding-bottom: 2px;
                border-bottom: 1px solid ${props => props.theme.colors.gray10};
            }
        }
    }
`

interface UpgradeToAccessModalComponentProps {
    modalIsOpen: boolean;
    setIsOpen: (value: boolean) => void;
}
const UpgradeToAccessModalComponent = ({ modalIsOpen, setIsOpen }: UpgradeToAccessModalComponentProps) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { state, setPreferences } = useContext(AuthContext);
    const [selectedPricing, setSelectedPricing] = useState<Pricings>();
    const pricingFilter = (pricing: Pricings) => pricing.benefit.duration?.in === ProductSubscriptionDurations.YEAR && (!state.user?.subscription.activeSubscription || pricing.productRank > state.user.subscription.activeSubscription.productRank)

    useEffect(() => {
        if (state.resources.pricing && !selectedPricing) {
            const pricings = state.resources.pricing.pricings.filter(pricingFilter)
            if (pricings.length) {
                setSelectedPricing(pricings.length > 1 ? pricings[1] : pricings[0])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.resources.pricing, selectedPricing])

    const startPayment = () => {
        if (selectedPricing) {
            setPreferences({ ...state.preferences, whereILeftOff: { ...state.preferences.whereILeftOff, beforeAuthFlow: { ...state.preferences.whereILeftOff.beforeAuthFlow, payment: { pricing: selectedPricing } } } })
            navigate("/pricing")
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: `2px solid ${theme.colors.gray10}`
        },
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            // onAfterOpen={console.log}
            onRequestClose={() => setIsOpen(false)}
            contentLabel="Upgrade to access plan modal"
        >
            {
                !state.resources.pricing || !state.user ? <Loading /> : <>
                    <StyledGroup gap='md'>
                        <Group align='center' direction='row' justify='space-between'>
                            <img src="/logo.png" width="200" alt="Company logo" />
                            <StyledCloseButton
                                onClick={() => setIsOpen(false)}
                                type="button"
                                tabIndex={0}
                                aria-label="Close"
                            />
                        </Group>
                        {
                            selectedPricing && <>
                                <Group gap='none'>
                                    <StyledTitle>{t('upgradeToAccess')}</StyledTitle>
                                    <StyledTitleDescDiv>{t('thisFeatureIsAvailableWithAnUpgradedPlan')}</StyledTitleDescDiv>
                                </Group>
                                <Divider></Divider>
                            </>
                        }

                        <Group gap='sm'>
                            <Group direction='row' gap='xs' align='center' justify='center'>
                                {
                                    state.resources.pricing.pricings.filter(pricingFilter).map(pricing => <Group className={`plan ${pricing.id === selectedPricing?.id ? "active" : ""}`} gap='sm' key={pricing.id}>
                                        <StyledTitle>{pricing.title}</StyledTitle>
                                        <Group direction="row" wrap="nowrap" className="price" align="center" gap="xs">
                                            <h5>
                                                <Currency
                                                    quantity={pricing.unitPrice}
                                                    currency={pricing.currency}
                                                />
                                            </h5>
                                            <small>/{t('permonth').toLowerCase()}</small>
                                        </Group>
                                        <Button color={pricing.id === selectedPricing?.id ? "secondary" : "pale"} onClick={() => setSelectedPricing(pricing)} text={t('select')} size="sm" />
                                        <Divider></Divider>
                                        <SubscriptionFeatures subscription={state.resources.pricing!.subscriptionBenefits[pricing.productCode as SubscriptionPlan]} />
                                    </Group>)
                                }
                            </Group>
                            <Divider></Divider>
                        </Group>
                        <Group gap='xs' className='plan-footer'>
                            <Link target='_blank' to={"/pricing"}>
                                <span>
                                    {t('comparePlansAndPricingOptions')} <ChevronRight size={12} />
                                </span>
                            </Link>
                            <Button text={t('upgrade')} disabled={!selectedPricing} onClick={startPayment} />
                        </Group>
                    </StyledGroup>
                </>
            }
        </Modal>
    )
}

export default UpgradeToAccessModalComponent;