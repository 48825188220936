"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSubscriptionDurations = exports.ProductTypes = void 0;
var ProductTypes;
(function (ProductTypes) {
    ProductTypes["OneOff"] = "one-off";
    ProductTypes["Recurring"] = "recurring";
})(ProductTypes || (exports.ProductTypes = ProductTypes = {}));
var ProductSubscriptionDurations;
(function (ProductSubscriptionDurations) {
    ProductSubscriptionDurations["DAY"] = "day";
    ProductSubscriptionDurations["YEAR"] = "year";
    ProductSubscriptionDurations["WEEK"] = "week";
    ProductSubscriptionDurations["MONTH"] = "month";
})(ProductSubscriptionDurations || (exports.ProductSubscriptionDurations = ProductSubscriptionDurations = {}));
