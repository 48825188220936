import { Config } from "../../config";
import { useApi } from "../../hooks/use-api";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/auth";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useConsoleLog } from "../../hooks/use-console-log.hook";
import DIYWebsiteBuilder, { SupportedLanguages } from 'diy-website-builder-lib';
import UpgradeToAccessModalComponent from "../../components/Modals/UpgradeToAccessModal";


const Build = () => {

    const location = useLocation();
    const { log } = useConsoleLog();
    const { shortId } = useParams();
    const { getPricings } = useApi();
    const { i18n } = useTranslation();
    const { state, setResources } = useContext(AuthContext);
    const [showPricingModal, setShowPricingModal] = useState(false);

    useQuery({
        queryFn: getPricings,
        queryKey: ["pricing"],
        enabled: !state.resources.pricing
    });

    useEffect(() => {
        const container = document.getElementById('container')
        if (container && shortId) {

            let suffix = ""
            if (location.pathname.includes("admin/templates")) {
                suffix = "/admin/templates"
            }
            if (location.pathname.includes("user/dashboard")) {
                suffix = "/user/dashboard"
            }

            new DIYWebsiteBuilder({
                shortId,
                container,
                // sidebarColor: "#ECF0F4",
                // hoverColor: "#80a7ce",
                // primaryColor: "#80a7ce",
                // allowPageCRUD: false,
                initializePages: ["index"],
                apiKey: state.user?.apiKeys[0],
                language: i18n.language as SupportedLanguages,
                exitURL: `${Config.WebURL}${suffix}`,
                environment: Config.Environment,
                onPaidFeatureClick: () => {
                    setShowPricingModal(true)
                },
                onPublish: (url, thumbnails) => {
                    log({ url, thumbnails })
                    const indexInAdminTemplate = state.resources.adminTemplates.findIndex(template => template.shortId === shortId)
                    if (indexInAdminTemplate > -1) {
                        setResources({ ...state.resources, adminTemplates: state.resources.adminTemplates.map((template, index) => index === indexInAdminTemplate ? ({ ...template, thumbnails, fullURL: url }) : template) })
                    }
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language, location.pathname, shortId, state.resources, state.user?.apiKeys])

    return (
        <>
            <UpgradeToAccessModalComponent setIsOpen={setShowPricingModal} modalIsOpen={showPricingModal} />
            <div id='container'></div>
        </>
    );
};

export default Build;
