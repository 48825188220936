"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMeaningEN = exports.ErrorCodes = void 0;
var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["INVALID_URL"] = "INVALID_URL";
    ErrorCodes["UNAUTHORIZED"] = "UNAUTHORIZED";
    ErrorCodes["UPDATE_FAILED"] = "UPDATE_FAILED";
    ErrorCodes["ACCESS_DENIED"] = "ACCESS_DENIED";
    ErrorCodes["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    ErrorCodes["INVALID_REQUEST"] = "INVALID_REQUEST";
    ErrorCodes["INVOICE_NOT_PAID"] = "INVOICE_NOT_PAID";
    ErrorCodes["INVALID_SHORT_ID"] = "INVALID_SHORT_ID";
    ErrorCodes["CLIENT_NOT_FOUND"] = "CLIENT_NOT_FOUND";
    ErrorCodes["INVOICE_NOT_FOUND"] = "INVOICE_NOT_FOUND";
    ErrorCodes["PRODUCT_NOT_FOUND"] = "PRODUCT_NOT_FOUND";
    ErrorCodes["PROJECT_NOT_FOUND"] = "PROJECT_NOT_FOUND";
    ErrorCodes["UNKNOWN_ORDER_TYPE"] = "UNKNOWN_ORDER_TYPE";
    ErrorCodes["CUSTOMER_NOT_FOUND"] = "CUSTOMER_NOT_FOUND";
    ErrorCodes["TEMPLATE_NOT_FOUND"] = "TEMPLATE_NOT_FOUND";
    ErrorCodes["NO_UPDATABLE_PARAM"] = "NO_UPDATABLE_PARAM";
    ErrorCodes["FILE_UPLOAD_FAILED"] = "FILE_UPLOAD_FAILED";
    ErrorCodes["FORBIDDEN_SHORT_ID"] = "FORBIDDEN_SHORT_ID";
    ErrorCodes["ERROR_CREATING_USER"] = "ERROR_CREATING_USER";
    ErrorCodes["EMAIL_IS_DISPOSABLE"] = "EMAIL_IS_DISPOSABLE";
    ErrorCodes["INVALID_VOUCHER_CODE"] = "INVALID_VOUCHER_CODE";
    ErrorCodes["ERROR_SAVING_ZIP_FILE"] = "ERROR_SAVING_ZIP_FILE";
    ErrorCodes["SERVICE_NOT_AVAILABLE"] = "SERVICE_NOT_AVAILABLE";
    ErrorCodes["PROJECT_NOT_PUBLISHED"] = "PROJECT_NOT_PUBLISHED";
    ErrorCodes["SHORT_ID_NOT_AVAILABLE"] = "SHORT_ID_NOT_AVAILABLE";
    ErrorCodes["UNKNOWN_UPSTREAM_ERROR"] = "UNKNOWN_UPSTREAM_ERROR";
    ErrorCodes["ACCOUNT_ALREADY_EXISTS"] = "ACCOUNT_ALREADY_EXISTS";
    ErrorCodes["BILLING_ADDRESS_INVALID"] = "BILLING_ADDRESS_INVALID";
    ErrorCodes["PRODUCT_PRICE_NOT_FOUND"] = "PRODUCT_PRICE_NOT_FOUND";
    ErrorCodes["ERROR_PUBLISHING_PROJECT"] = "ERROR_PUBLISHING_PROJECT";
    ErrorCodes["INVALID_PAYMENT_PROVIDER"] = "INVALID_PAYMENT_PROVIDER";
    ErrorCodes["MAXIMUM_PROJECT_EXCEEDED"] = "MAXIMUM_PROJECT_EXCEEDED";
    ErrorCodes["ERROR_DUPLICATING_PROJECT"] = "ERROR_DUPLICATING_PROJECT";
    ErrorCodes["UNKNOWN_FILE_CONTENT_TYPE"] = "UNKNOWN_FILE_CONTENT_TYPE";
    ErrorCodes["PRODUCT_CODE_ALREADY_EXIST"] = "PRODUCT_CODE_ALREADY_EXIST";
    ErrorCodes["PAYMENT_PROVIDER_NOT_FOUND"] = "PAYMENT_PROVIDER_NOT_FOUND";
    ErrorCodes["INVALID_AUTHENTICATION_CODE"] = "INVALID_AUTHENTICATION_CODE";
    ErrorCodes["HREF_FIELD_FAILED_VALIDATION"] = "HREF_FIELD_FAILED_VALIDATION";
    ErrorCodes["ICON_FIELD_FAILED_VALIDATION"] = "ICON_FIELD_FAILED_VALIDATION";
    ErrorCodes["FONT_FIELD_FAILED_VALIDATION"] = "FONT_FIELD_FAILED_VALIDATION";
    ErrorCodes["IMAGE_FIELD_FAILED_VALIDATION"] = "IMAGE_FIELD_FAILED_VALIDATION";
    ErrorCodes["EMAIL_FIELD_FAILED_VALIDATION"] = "EMAIL_FIELD_FAILED_VALIDATION";
    ErrorCodes["FILE_UPLOAD_FAILED_VALIDATION"] = "FILE_UPLOAD_FAILED_VALIDATION";
    ErrorCodes["COUPON_FIELD_FAILED_VALIDATION"] = "COUPON_FIELD_FAILED_VALIDATION";
    ErrorCodes["MOBILE_FIELD_FAILED_VALIDATION"] = "MOBILE_FIELD_FAILED_VALIDATION";
    ErrorCodes["REQUEST_BODY_FAILED_VALIDATION"] = "REQUEST_BODY_FAILED_VALIDATION";
    ErrorCodes["BORDER_FIELD_FAILED_VALIDATION"] = "BORDER_FIELD_FAILED_VALIDATION";
    ErrorCodes["HEIGHT_FIELD_FAILED_VALIDATION"] = "HEIGHT_FIELD_FAILED_VALIDATION";
    ErrorCodes["PRODUCT_FIELD_FAILED_VALIDATION"] = "PRODUCT_FIELD_FAILED_VALIDATION";
    ErrorCodes["PROJECT_FIELD_FAILED_VALIDATION"] = "PROJECT_FIELD_FAILED_VALIDATION";
    ErrorCodes["PAYMENT_FIELD_FAILED_VALIDATION"] = "PAYMENT_FIELD_FAILED_VALIDATION";
    ErrorCodes["UPGRADE_REQUIRED_TO_SAVE_PROJECT"] = "UPGRADE_REQUIRED_TO_SAVE_PROJECT";
    ErrorCodes["DOCUMENT_FIELD_FAILED_VALIDATION"] = "DOCUMENT_FIELD_FAILED_VALIDATION";
    ErrorCodes["LENGTH_MUST_BE_A_POSITIVE_NUMBER"] = "LENGTH_MUST_BE_A_POSITIVE_NUMBER";
    ErrorCodes["PRODUCT_PRICE_CODE_ALREADY_EXIST"] = "PRODUCT_PRICE_CODE_ALREADY_EXIST";
    ErrorCodes["CLIP_PATH_FIELD_FAILED_VALIDATION"] = "CLIP_PATH_FIELD_FAILED_VALIDATION";
    ErrorCodes["COMPONENT_FIELD_FAILED_VALIDATION"] = "COMPONENT_FIELD_FAILED_VALIDATION";
    ErrorCodes["BACKGROUND_FIELD_FAILED_VALIDATION"] = "BACKGROUND_FIELD_FAILED_VALIDATION";
    ErrorCodes["MEASUREMENT_FIELD_FAILED_VALIDATION"] = "MEASUREMENT_FIELD_FAILED_VALIDATION";
    ErrorCodes["IMAGE_FILTER_FIELD_FAILED_VALIDATION"] = "IMAGE_FILTER_FIELD_FAILED_VALIDATION";
    ErrorCodes["PROJECT_ASSET_FIELD_FAILED_VALIDATION"] = "PROJECT_ASSET_FIELD_FAILED_VALIDATION";
    ErrorCodes["PRODUCT_PRICE_FIELD_FAILED_VALIDATION"] = "PRODUCT_PRICE_FIELD_FAILED_VALIDATION";
    ErrorCodes["COLOR_PATTERN_FIELD_FAILED_VALIDATION"] = "COLOR_PATTERN_FIELD_FAILED_VALIDATION";
    ErrorCodes["COMPONENT_MAIN_FIELD_FAILED_VALIDATION"] = "COMPONENT_MAIN_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_PRESET_FIELD_FAILED_VALIDATION"] = "DOCUMENT_PRESET_FIELD_FAILED_VALIDATION";
    ErrorCodes["AUTHENTICATION_FIELDS_FAILED_VALIDATION"] = "AUTHENTICATION_FIELDS_FAILED_VALIDATION";
    ErrorCodes["COMPONENT_PARENT_FIELD_FAILED_VALIDATION"] = "COMPONENT_PARENT_FIELD_FAILED_VALIDATION";
    ErrorCodes["COMPONENT_ELEMENT_FIELD_FAILED_VALIDATION"] = "COMPONENT_ELEMENT_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["COMPONENT_ELEMENT_IMAGE_FIELD_FAILED_VALIDATION"] = "COMPONENT_ELEMENT_IMAGE_FIELD_FAILED_VALIDATION";
    ErrorCodes["PROJECT_ASSET_FIELD_CROP_DATA_FAILED_VALIDATION"] = "PROJECT_ASSET_FIELD_CROP_DATA_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_NAVBAR_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_NAVBAR_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_CHILDREN_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_CHILDREN_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_LIST_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_LIST_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_TEXT_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_TEXT_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_TABLE_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_TABLE_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_EMBED_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_EMBED_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_ICONS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_ICONS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_VIDEO_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_VIDEO_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_LINKS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_LINKS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_IMAGE_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_IMAGE_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_HEIGHT_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_HEIGHT_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_NAVBAR_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_NAVBAR_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_BUTTONS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_BUTTONS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_GALLERY_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_GALLERY_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_DIVIDER_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_DIVIDER_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_CONTROL_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_CONTROL_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_ACCORDION_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_ACCORDION_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_CONTAINER_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_CONTAINER_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_CHILDREN_ANIMATION_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_CHILDREN_ANIMATION_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_TABLE_CONTENT_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_TABLE_CONTENT_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_ACCORDION_ELEMENT_TITLE_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_ACCORDION_ELEMENT_TITLE_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_GALLERY_CAPTION_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_GALLERY_CAPTION_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_CHILDREN_ANIMATION_HOVER_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_CHILDREN_ANIMATION_HOVER_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_CHILDREN_ANIMATION_ENTRANCE_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_CHILDREN_ANIMATION_ENTRANCE_SETTINGS_FIELD_FAILED_VALIDATION";
})(ErrorCodes || (exports.ErrorCodes = ErrorCodes = {}));
exports.ErrorMeaningEN = {
    ACCESS_DENIED: "Access denied",
    USER_NOT_FOUND: "User not found!",
    INVALID_SHORT_ID: "Invalid short id",
    INVOICE_NOT_PAID: "Invoice not paid!",
    CLIENT_NOT_FOUND: "Client not found!",
    INVOICE_NOT_FOUND: "Invoice not found!",
    PRODUCT_NOT_FOUND: "Product not found!",
    CUSTOMER_NOT_FOUND: "Customer not found!",
    UNKNOWN_ORDER_TYPE: "Unknown order type.",
    TEMPLATE_NOT_FOUND: "Template not found!",
    PRODUCT_PRICE_NOT_FOUND: "Price not found!",
    ERROR_CREATING_USER: "Error creating user!",
    INVALID_VOUCHER_CODE: "Invalid voucher code!",
    FILE_UPLOAD_FAILED: "The file upload failed.",
    INVALID_REQUEST: "Your request is not valid.",
    INVALID_URL: "The URL you entered is invalid.",
    ERROR_SAVING_ZIP_FILE: "Error saving zip file.",
    ACCOUNT_ALREADY_EXISTS: "Account already exists!",
    INVALID_PAYMENT_PROVIDER: "Invalid payment provider!",
    ERROR_PUBLISHING_PROJECT: "Error publishing project.",
    ERROR_DUPLICATING_PROJECT: "Error duplicating project",
    PROJECT_NOT_PUBLISHED: "The project is not published.",
    UNKNOWN_FILE_CONTENT_TYPE: "Unknown file content type.",
    PRODUCT_PRICE_CODE_ALREADY_EXIST: "Price already exist!",
    PRODUCT_CODE_ALREADY_EXIST: "Product type already exist!",
    PAYMENT_PROVIDER_NOT_FOUND: "Payment provider not found!",
    BILLING_ADDRESS_INVALID: "The billing address is invalid!",
    FONT_FIELD_FAILED_VALIDATION: "The font field is invalid.",
    HREF_FIELD_FAILED_VALIDATION: "The link field is invalid.",
    ICON_FIELD_FAILED_VALIDATION: "The icon field is invalid.",
    UNKNOWN_UPSTREAM_ERROR: "Unknown upstream error occurred!",
    UPDATE_FAILED: "An error occurred while updating the item.",
    FORBIDDEN_SHORT_ID: "The provided short Id is not allowed.",
    IMAGE_FIELD_FAILED_VALIDATION: "The image field is invalid.",
    EMAIL_FIELD_FAILED_VALIDATION: "The email field is invalid.",
    MAXIMUM_PROJECT_EXCEEDED: "Maximum project limit exceeded!",
    MOBILE_FIELD_FAILED_VALIDATION: "The mobile field is invalid.",
    BORDER_FIELD_FAILED_VALIDATION: "The border object is invalid",
    HEIGHT_FIELD_FAILED_VALIDATION: "The height object is invalid",
    SERVICE_NOT_AVAILABLE: "The service is currently unavailable.",
    PROJECT_FIELD_FAILED_VALIDATION: "The project field is invalid.",
    PRODUCT_FIELD_FAILED_VALIDATION: "The product field is invalid.",
    CLIP_PATH_FIELD_FAILED_VALIDATION: "Clip path field is invalid.",
    COUPON_FIELD_FAILED_VALIDATION: "Coupon field failed validation!",
    REQUEST_BODY_FAILED_VALIDATION: "Request body failed validation!",
    SHORT_ID_NOT_AVAILABLE: "The provided short Id is not available.",
    DOCUMENT_FIELD_FAILED_VALIDATION: "The document field is invalid.",
    UNAUTHORIZED: "You don't have permission to access this resource.",
    INVALID_AUTHENTICATION_CODE: "The authentication code is invalid.",
    NO_UPDATABLE_PARAM: "There are no parameters available to update.",
    PAYMENT_FIELD_FAILED_VALIDATION: "Payment field failed validation!",
    COMPONENT_FIELD_FAILED_VALIDATION: "The component field is invalid.",
    UPGRADE_REQUIRED_TO_SAVE_PROJECT: "Upgrade required to save project.",
    IMAGE_FILTER_FIELD_FAILED_VALIDATION: "Image filter field is invalid.",
    LENGTH_MUST_BE_A_POSITIVE_NUMBER: "Length must be a positive integer.",
    BACKGROUND_FIELD_FAILED_VALIDATION: "The background field is invalid.",
    PROJECT_NOT_FOUND: "The project you're looking for could not be found.",
    MEASUREMENT_FIELD_FAILED_VALIDATION: "The measurement field is invalid.",
    PRODUCT_PRICE_FIELD_FAILED_VALIDATION: "Product price field is invalid.",
    COMPONENT_MAIN_FIELD_FAILED_VALIDATION: "Component main field is invalid.",
    EMAIL_IS_DISPOSABLE: "Provided email address is not a valid email address.",
    PROJECT_ASSET_FIELD_FAILED_VALIDATION: "The project asset field is invalid.",
    COLOR_PATTERN_FIELD_FAILED_VALIDATION: "The color pattern field is invalid.",
    DOCUMENT_PRESET_FIELD_FAILED_VALIDATION: "Document preset field is invalid.",
    COMPONENT_PARENT_FIELD_FAILED_VALIDATION: "The component parent field is invalid.",
    COMPONENT_ELEMENT_FIELD_FAILED_VALIDATION: "The component element field is invalid.",
    DOCUMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document settings field is invalid.",
    FILE_UPLOAD_FAILED_VALIDATION: "The file you uploaded does not meet our requirements.",
    AUTHENTICATION_FIELDS_FAILED_VALIDATION: "Your authentication credentials failed validation.",
    COMPONENT_ELEMENT_IMAGE_FIELD_FAILED_VALIDATION: "The component element image field is invalid.",
    PROJECT_ASSET_FIELD_CROP_DATA_FAILED_VALIDATION: "The project asset crop data field is invalid.",
    DOCUMENT_COMPONENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component settings field is invalid.",
    DOCUMENT_COMPONENT_EMBED_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component embed setting is invalid.",
    DOCUMENT_COMPONENT_NAVBAR_SETTINGS_FIELD_FAILED_VALIDATION: "The document component navbar element setting is invalid.",
    DOCUMENT_COMPONENT_HEIGHT_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component height setting is invalid.",
    DOCUMENT_COMPONENT_NAVBAR_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document navigator element setting is invalid.",
    DOCUMENT_COMPONENT_ACCORDION_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document accordion element setting is invalid.",
    DOCUMENT_COMPONENT_LIST_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component list element setting is invalid.",
    DOCUMENT_COMPONENT_TEXT_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component text element setting is invalid.",
    DOCUMENT_COMPONENT_CHILDREN_SETTINGS_FIELD_FAILED_VALIDATION: "The document component elements' generic setting is invalid.",
    DOCUMENT_COMPONENT_ICONS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component icon element setting is invalid.",
    DOCUMENT_COMPONENT_LINKS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component link element setting is invalid.",
    DOCUMENT_COMPONENT_TABLE_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component table element setting is invalid.",
    DOCUMENT_COMPONENT_VIDEO_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component video element setting is invalid.",
    DOCUMENT_COMPONENT_IMAGE_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component image element setting is invalid.",
    DOCUMENT_COMPONENT_CHILDREN_ANIMATION_SETTINGS_FIELD_FAILED_VALIDATION: "The document component animation setting is invalid.",
    DOCUMENT_COMPONENT_GALLERY_CAPTION_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document caption element setting is invalid.",
    DOCUMENT_COMPONENT_BUTTONS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component buttons element setting is invalid.",
    DOCUMENT_COMPONENT_DIVIDER_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component divider element setting is invalid.",
    DOCUMENT_COMPONENT_CONTROL_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component control element setting is invalid.",
    DOCUMENT_COMPONENT_GALLERY_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component gallery element setting is invalid.",
    DOCUMENT_COMPONENT_CONTAINER_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component container element setting is invalid.",
    DOCUMENT_COMPONENT_CHILDREN_ANIMATION_HOVER_SETTINGS_FIELD_FAILED_VALIDATION: "The document component animation hover setting is invalid.",
    DOCUMENT_COMPONENT_ACCORDION_ELEMENT_TITLE_SETTINGS_FIELD_FAILED_VALIDATION: "The document component accordion element setting is invalid.",
    DOCUMENT_COMPONENT_TABLE_CONTENT_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION: "The document component table content element setting is invalid.",
    DOCUMENT_COMPONENT_CHILDREN_ANIMATION_ENTRANCE_SETTINGS_FIELD_FAILED_VALIDATION: "The document component animation entrance setting is invalid.",
};
