import styled from "styled-components";
import { useContext, useState } from "react";
import { useApi } from "../../hooks/use-api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Group } from "../../layout/Group/Group";
import { AuthContext } from "../../context/auth";
import Loading from "../../components/Loading/Loading";
import { forPhoneOnly, growSmaller } from "../../elements";
import { ArrowRight, Kanban } from "react-bootstrap-icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useConsoleLog } from "../../hooks/use-console-log.hook";
import { Button, OutlineButton } from "../../components/Form/Button";
import { useRouteResolver } from "../../hooks/use-route-resolver.hook";
import UpgradeToAccessModalComponent from "../../components/Modals/UpgradeToAccessModal";
import { intersection, Template, TemplateCategories, templateCategoriesArray, uniq, UserPermission } from "shared-library";


const StyledGroup = styled(Group)`
    padding: 20px;
    .title-group {
        padding: 60px 0;
        text-align: center;
        h1 {
            letter-spacing: 6px;
            color: ${props => props.theme.colors.black};
        }

        h5 {
            font-weight: 300;
            color: ${props => props.theme.colors.gray};
        }
    }

    .categories {
        padding: 50px 10px;
        button {
            font-size: 14px;
            padding: 6px 18px;
            cursor: pointer;
            ${growSmaller}
            background-color: transparent;
            color: ${props => props.theme.colors.gray};
            border: 1px solid ${props => props.theme.colors.gray10};

            &.active {
                color: ${props => props.theme.colors.gray};
                border-color: ${props => props.theme.colors.gray};
            }
        }
    }
`;

const TemplateGroup = styled(Group)`
    overflow: hidden;
    flex-grow: 1;
    width: 265px;
    max-width: 265px;
    padding: 10px 0;
    border: 1px solid ${props => props.theme.colors.gray10};
    ${forPhoneOnly("max-width: 85%; margin: 0 auto; margin-bottom: 20px;")}
    ${growSmaller}

    .scrollable {
        overflow-y: auto;
        width: 100%;
        min-height: 200px;
        max-height: 200px;

        ${forPhoneOnly("max-height: 500px;")}
        box-sizing: content-box;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar { 
            display: none;
        }

        img {
            width: 100%;
            margin-bottom: -10px;
        }

    }

    .details {
        padding: 12px;
        transition: all 1s;
        position: relative;

        span {
            font-weight: bold;
            color: ${props => props.theme.colors.gray};
        }

        .options {
            top: 14px;
            display: none;
            position: absolute;

            button {
                font-size: 14px;
                cursor: pointer;
                padding: 4px 20px;
                text-transform: uppercase;

                &:first-child {
                    border: 1px solid ${props => props.theme.colors.secondary};
                    background-color: ${props => props.theme.colors.secondary};
                    border: 1px solid ${props => props.theme.colors.secondary};
                }

                &:nth-child(2) {
                    color: ${props => props.theme.colors.gray};
                    border: 1px solid ${props => props.theme.colors.gray};
                    background-color: transparent;
                }                
            }
        }
    }

    &:hover {
        .details {
            .options {
                background-color: ${props => props.theme.colors.white};
                display: flex;
            }
        }
    }
`

const Templates = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { log } = useConsoleLog();
    const { dashboard } = useRouteResolver();
    const { state, setPreferences } = useContext(AuthContext);
    const { getTemplates, getProjects, createProject } = useApi();
    const [showPricingModal, setShowPricingModal] = useState(false);
    const [busyTemplateIds, setBusyTemplateIds] = useState<Array<string>>([]);
    const [filters, setFilters] = useState<TemplateCategories[]>([]);
    const { data: projects } = useQuery({
        queryFn: getProjects,
        queryKey: ["projects"],
        enabled: !!state.user
    });
    const { data: templates, isLoading } = useQuery({
        staleTime: Infinity,
        queryFn: getTemplates,
        queryKey: ["templates"],
    });
    const { mutateAsync: createProjectAsync } = useMutation({
        mutationFn: createProject
    })

    const pushPopToFilters = (category: TemplateCategories) => {
        if (filters.includes(category)) {
            setFilters(filters.filter(c => c !== category));
            return
        }
        setFilters([...filters, category]);
    }

    const getStarted = (templateId?: string) => {
        if (templateId) setBusyTemplateIds(temp => uniq([templateId, ...temp]))
        if (state.user && projects) {
            const userPermission = new UserPermission(state.user.subscription)
            const { startProject, ...rest } = state.preferences.whereILeftOff.beforeAuthFlow
            if (userPermission.canCreateProject(projects.data, state.user.id)) {
                createProjectAsync({ templateId }).then(({ shortId }) => {
                    setPreferences({ ...state.preferences, whereILeftOff: { ...state.preferences.whereILeftOff, beforeAuthFlow: rest } })
                    setBusyTemplateIds(temp => uniq(temp.filter(t => t !== templateId)))
                    navigate(`/user/dashboard/projects/${shortId}`)
                }).catch(error => {
                    log(error)
                    setBusyTemplateIds(temp => uniq(temp.filter(t => t !== templateId)))
                    setPreferences({ ...state.preferences, whereILeftOff: { ...state.preferences.whereILeftOff, beforeAuthFlow: rest } })
                })
                return
            }
            setBusyTemplateIds(temp => uniq(temp.filter(t => t !== templateId)))
            setShowPricingModal(true)
            return
        }
        setBusyTemplateIds(temp => uniq(temp.filter(t => t !== templateId)))
        setPreferences({ ...state.preferences, whereILeftOff: { ...state.preferences.whereILeftOff, beforeAuthFlow: { ...state.preferences.whereILeftOff.beforeAuthFlow, startProject: { templateId } } } })
        navigate(state.user ? dashboard() : "/auth/sign-in")
    }

    return (
        <StyledGroup className="container-fluid">
            <UpgradeToAccessModalComponent setIsOpen={setShowPricingModal} modalIsOpen={showPricingModal} />
            <Group className="container title-group" align="center" justify="center" gap="xs">
                <h1>{t('templateAggregate')}</h1>
                <h5>{t('withOurIntuitiveDragAndDropInterface')}</h5>
                <Group direction="row" gap="sm" justify="center" align="center" style={{ marginTop: '10px' }}>
                    <Button text={t('signUpForFree')} icon={<ArrowRight />} onClick={() => navigate("/auth/sign-up")} />
                    <OutlineButton text={t('startBlankProject')} icon={<Kanban />} onClick={() => getStarted()} />
                </Group>
            </Group>

            <Group gap="md" className="container">
                {
                    !templates || isLoading ? <Loading /> : <>
                        <Group className="categories" gap="xs" direction="row" align="center" justify="center">
                            {
                                templateCategoriesArray.filter(cat => templates.find(template => template.categories.includes(cat))).sort().map(category => <button key={category} onClick={() => pushPopToFilters(category)} className={filters.includes(category) ? 'active' : ''}>{t(category)} <small>({templates.filter(template => template.categories.includes(category)).length})</small></button>)
                            }
                        </Group>
                        <Group className="template-container" direction="row" gap="sm">
                            {
                                templates.filter(t => !filters.length || intersection(t.categories, filters).length).map(template => <TemplateDom loading={busyTemplateIds.includes(template.id)} key={template.id} template={template} onGetStartedClicked={() => getStarted(template.id)} />)
                            }
                        </Group>
                    </>
                }

            </Group>

        </StyledGroup>
    )
}

export default Templates;

const TemplateDom = ({ template, onGetStartedClicked, loading }: { loading: boolean; template: Template; onGetStartedClicked: () => void; }) => {
    const { t } = useTranslation();
    return (
        <TemplateGroup gap="none">
            <div className="scrollable">
                <img alt={t('templateScreenshot')} src={template.thumbnails.desktopFullPage || "/assets/images/diy-website-builder-dummy-image.png"} />
            </div>
            <Group className="details">
                <span>{template.title}</span>
                <Group align="center" justify="center" className="options" gap="xs" direction="row" wrap="nowrap">
                    <Button size="sm" text={t('getStarted')} loading={loading} disabled={loading} onClick={onGetStartedClicked} />
                    <OutlineButton size="sm" text={t('preview')} onClick={() => window.open(template.fullURL, '_blank')?.focus()} />
                </Group>
            </Group>
        </TemplateGroup>
    )
}