import styled from "styled-components";
import styles from "./Spinner.module.scss";
import { CSSProperties, MouseEvent, ReactNode } from "react";
import { grow, growSmaller, keyboardFocusOnly } from "../../../elements";

interface ButtonProps {
    text?: string;
    icon?: ReactNode;
    className?: string;
    rightIcon?: ReactNode;
    color?: "primary" | "danger" | "success" | "secondary" | "default" | "pale";
    size?: "sm" | "lg" | "md";
    disabled?: boolean;
    loading?: boolean;
    style?: CSSProperties;
    type?: "button" | "submit";
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const StyledButton = styled.button`
    background-color: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.secondary};

    &.sm {
        font-size: 12px;
        padding: 6px 14px;
        gap: 0.25rem;
        ${growSmaller}
    }

    &.md {
        padding: 0.8rem 2rem;
        min-height: 2.4rem;
        gap: 0.75rem;
        line-height: 1.2rem;
        ${grow}
    }

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    overflow-wrap: break-word;
    ${keyboardFocusOnly}

    &:hover {
        text-decoration: none;
    }
    
    &:active {
        transform: scale(0.99);
        background-color: ${(props) => props.theme.colors.primary};
    }

    &.primary {
        color: ${(props) => props.theme.colors.white};
        border-color: ${(props) => props.theme.colors.black};
        background-color: ${(props) => props.theme.colors.black};
    }

    &.pale {
        color: ${(props) => props.theme.colors.black};
        border-color: ${(props) => props.theme.colors.gray10};
        background-color: ${(props) => props.theme.colors.gray10};
    }

    &.success {
        color: ${(props) => props.theme.colors.white};
        border-color: ${(props) => props.theme.colors.success};
        background-color: ${(props) => props.theme.colors.success};
    }

    &.danger {
        color: ${props => props.theme.colors.white};
        border-color: ${(props) => props.theme.colors.danger};
        background-color: ${(props) => props.theme.colors.danger};
    
        &:hover {
            background-color: ${(props) => props.theme.colors.danger};
        }
    }

    &.secondary {
        background-color: ${(props) => props.theme.colors.secondary};
    }

     &:disabled {
        opacity: .5;
        cursor: not-allowed;
    
        &.danger {
            color: ${props => props.theme.colors.white};
            background-color: ${(props) => props.theme.colors.danger};
    
          &:hover {
            background-color: ${(props) => props.theme.colors.danger};
          }
        }

        &.success {
            color: ${props => props.theme.colors.black};
            background-color: ${(props) => props.theme.colors.success};
          }
    
        &:active {
          transform: none;
        }
    }  
`

export const Button = (props: ButtonProps) => {
    const {
        text,
        icon,
        rightIcon,
        className = "",
        disabled = false,
        loading = false,
        color = "default",
        size = "md",
        style = {},
        type = "button",
        onClick,
    } = props;

    const classNames = ["btn", size, color, className].filter(cn => cn);

    return (
        <StyledButton
            className={classNames.join(" ")}
            onClick={onClick}
            disabled={disabled}
            style={style}
            type={type}
        >
            {loading && <Spinner />}
            {!loading && icon && <>{icon}</>}
            {!loading && <>{text}</>}
            {!loading && rightIcon && <>{rightIcon}</>}
        </StyledButton>
    );
};


const StyledSpinnerDiv = styled.div`
    background: ${(props) => props.theme.colors.white};
`

export const Spinner = () => {
    return (
        <div className={styles.ellipsis}>
            <StyledSpinnerDiv></StyledSpinnerDiv>
            <StyledSpinnerDiv></StyledSpinnerDiv>
            <StyledSpinnerDiv></StyledSpinnerDiv>
            <StyledSpinnerDiv></StyledSpinnerDiv>
        </div>
    );
};
