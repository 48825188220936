import { Button } from "../Form/Button";
import styles from "./Navbar.module.scss";
import { useTranslation } from "react-i18next";
import { Group } from "../../layout/Group/Group";
import { AuthContext } from "../../context/auth";
import styled, { useTheme } from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import { CouponTypes } from "../../utils/types/coupon.interface";
import { useRouteResolver } from "../../hooks/use-route-resolver.hook";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { boxShadow, forPhoneOnly, StyledUserPicture } from "../../elements";
import { createImageFromInitials, mediaAssetTypesArray } from "shared-library";
import { ArrowLeftRight, CaretDownFill, CaretUpFill, DoorClosed, HandIndexThumb, PatchCheckFill, Person, Substack } from "react-bootstrap-icons";


const StyledNav = styled.nav`
    ${boxShadow()};
    padding: 14px 0px 14px 0px;

    .inner {
        ${forPhoneOnly("padding: 0 1rem;")}
    }

    .subscription {
        font-size: 10px;
        font-weight: bold;
        padding: 2px 16px;
        border-width: 1px;
        border-radius: 4px;
        border-style: solid;
        letter-spacing: 2px;
        color: ${props => props.theme.colors.gray};
        &.free {
            background-color: ${props => props.theme.colors.gray10};
            border-color: ${props => props.theme.colors.gray10};
        }
        &.basic {
            color: ${props => props.theme.colors.success};
            border-color: ${props => props.theme.colors.success};
        }
        &.pro {
            color: ${props => props.theme.colors.success};
            border-color: ${props => props.theme.colors.success};
            background-color: ${props => props.theme.colors.success10};
        } 
        &.gold {
            color: ${props => props.theme.colors.secondary};
            border-color: ${props => props.theme.colors.secondary};
            background-color: ${props => props.theme.colors.secondary10};
        }            
    }

    .navLinks {
        .navLink {
            font-size: 14px;
            position: relative;
            padding-bottom: 4px;
            text-decoration: none;
            transition: all 0.25s;
            border-bottom: 4px solid transparent;
            color: ${props => props.theme.colors.black};
            border-bottom-color: ${(props) => props.theme.colors.secondary10};

            button {
                ${boxShadow()};
            }
            
            &.active {
                border-bottom-color: ${(props) => props.theme.colors.secondary};
            }
        }

        .dropdown-wrapper {
            position: relative;
            img {
                margin-top: 3px;
            }

            .dropdown,
            .profile-dropdown {
                z-index: 1;
                right: 0px;
                ${boxShadow()};
                position: absolute;
                ${forPhoneOnly("right: -80px;")}
                background-color: ${(props) => props.theme.colors.white};

                .dropdown-link {
                    gap: 0.5rem;
                    font-size: 14px;
                    cursor: pointer;
                    padding: 10px 14px;
                    align-items: center;
                    text-decoration: none;
                    color: ${(props) => props.theme.colors.gray};
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    transition: 0.25s;
                    letter-spacing: 2px;

                    &:hover {
                        color: ${props => props.theme.colors.black};
                        background-color: ${(props) => props.theme.colors.secondary};
                    }
                }
            }

            .dropdown {
                top: 50px;
                min-width: 160px;
            }
                
            .profile-dropdown {
                top: 58px;
                padding: 10px 0;
                min-width: 200px;
                &:last-child {
                    border-top: 1px solid ${(props) => props.theme.colors.gray10};
                }
            }
        }
    }

    input[type=checkbox] { display: none; }
    .wblnavToggler {
        display: none;
    }
    @media (max-width: 599px) {
        .wblnavToggler {
            display: block;
            label div {
                max-width: 35px;
                cursor: pointer;
                min-height: 2px;
                background-color: ${props => props.theme.colors.black};
            }
        }
        input[type=checkbox]:not(:checked)~.wblnavToggler label div:nth-child(2) {
            width: 35px;
            margin: 4px 0;
        }
        input[type=checkbox]:not(:checked)~.wblnavToggler label div:nth-child(1),
        input[type=checkbox]:not(:checked)~.wblnavToggler label div:nth-child(3) {
            width: 25px;
            transition: 0.3s;
            margin-left: 5px;
        }
        input[type=checkbox]:checked~.wblnavToggler label div:nth-child(2) {
            background-color: transparent;
        }
        input[type=checkbox]:checked~.wblnavToggler label div:nth-child(1) {
            width: 35px;
            margin-left: -40px;
            transform: rotate(45deg);
            transition: 0.3s;
        }
        input[type=checkbox]:checked~.wblnavToggler label div:nth-child(3) {
            width: 35px;
            transform: rotate(-45deg);
            margin-top: -4px;
            margin-left: -40px;
            transition: 0.3s;
        }       
        input[type=checkbox]:not(:checked)~.navLinks {
            display: none;
        }               
        input[type=checkbox]:checked~.navLinks {
            display: flex;
            flex-direction: column !important;
            position: absolute;
            top: 90px;
            padding: 30px;
            width: 90%;
            margin: 0 auto;
            background-color: ${(props) => props.theme.colors.white};
        }   
    }   
`

export const Navbar = () => {

    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const { dashboard } = useRouteResolver();
    const { state, logout, switchProfile } = useContext(AuthContext);

    const dropDownRef1 = useRef<HTMLDivElement>(null);
    const dropDownRef2 = useRef<HTMLDivElement>(null);
    const profileDropDownRef = useRef<HTMLDivElement>(null);
    const [showingProfileDropdown, setShowingProfileDropdown] = useState(false);
    const [showingDropdownOption1, setShowingDropdownOption1] = useState(false);
    const [showingDropdownOption2, setShowingDropdownOption2] = useState(false);
    const handleClickOutside = (event: MouseEvent) => {
        if (profileDropDownRef?.current && !profileDropDownRef.current.contains(event.target as Node)) {
            setShowingProfileDropdown(false);
        }
        if (dropDownRef1?.current && !dropDownRef1.current.contains(event.target as Node)) {
            setShowingDropdownOption1(false);
        }
        if (dropDownRef2?.current && !dropDownRef2.current.contains(event.target as Node)) {
            setShowingDropdownOption2(false);
        }
    };

    const switchProfileFunc = () => {
        switchProfile();
        navigate(`/profiles`)
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    return (
        <StyledNav className={`${styles.navMenu}`}>
            <Group direction="row" justify="space-between" align="center" className={`inner container ${styles.inner}`}>
                <input id="nav_checkvzletWLVeONp7paw1PUYyab7" type="checkbox" />
                <Group direction="row" wrap="nowrap" gap="xs" align="center">
                    <Link to="/" className={styles.navLogo} role="logo">
                        <img src="/logo.png" width="100%" alt="Company logo" />
                    </Link>
                    {
                        state.user?.currentUserType === "user" && <Group direction="row" gap="xs" align="center" className={`subscription ${state.user.subscription.plan}`}>
                            {state.user.subscription.plan === "free" ? <Substack /> : <PatchCheckFill />}
                            {state.user.subscription.plan.toUpperCase()}
                        </Group>
                    }
                </Group>

                <Group className="navLinks" gap="sm" align="center" direction="row" justify="center">
                    {
                        state.user ? (
                            <>
                                {
                                    state.user.currentUserType === "admin" &&
                                    <>
                                        <NavLink to="/admin/templates" className={(navData) => (navData.isActive ? 'active' : '') + " navLink"}>{t('templates')}</NavLink>
                                        <NavLink to="/admin/products" className={(navData) => (navData.isActive ? 'active' : '') + " navLink"}>{t('products')}</NavLink>
                                        <div className="dropdown-wrapper" ref={dropDownRef1}>
                                            <Group direction="row" gap="xs" align="center" wrap="nowrap" className={`pointer navLink ${location.pathname.includes("/coupons/") ? "active" : ""}`} onClick={() => setShowingDropdownOption1(!showingDropdownOption1)}>
                                                {t('coupons')}
                                                {
                                                    showingDropdownOption1 ? <CaretUpFill /> : <CaretDownFill />
                                                }
                                            </Group>
                                            {
                                                showingDropdownOption1 && <div className="dropdown">
                                                    <Group gap="none">
                                                        <NavLink to={`/admin/coupons/${CouponTypes.DISCOUNT}`} className="dropdown-link">{t('discounts')}</NavLink>
                                                        <NavLink to={`/admin/coupons/${CouponTypes.VOUCHER}`} className="dropdown-link">{t('vouchers')}</NavLink>
                                                    </Group>
                                                </div>
                                            }
                                        </div>
                                        <div className="dropdown-wrapper" ref={dropDownRef2}>
                                            <Group direction="row" gap="xs" align="center" wrap="nowrap" className={`pointer navLink ${location.pathname.includes("/sdk/") ? "active" : ""}`} onClick={() => setShowingDropdownOption2(!showingDropdownOption2)}>
                                                {t('sdk')}
                                                {
                                                    showingDropdownOption2 ? <CaretUpFill /> : <CaretDownFill />
                                                }
                                            </Group>
                                            {
                                                showingDropdownOption2 && <div className="dropdown">
                                                    <Group gap="none">
                                                        {
                                                            mediaAssetTypesArray.map(mediaAssetType => {
                                                                return (
                                                                    mediaAssetType !== "snapshots" ?
                                                                        <NavLink key={mediaAssetType} to={`/admin/sdk/${mediaAssetType}`} className="dropdown-link">{t(mediaAssetType)}</NavLink> : null
                                                                )
                                                            })
                                                        }
                                                    </Group>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }

                                {
                                    state.user.currentUserType === "user" &&
                                    <>
                                        <NavLink to="/templates" className={(navData) => (navData.isActive ? 'active' : '') + " navLink"}>{t('templates')}</NavLink>
                                        <NavLink to="/pricing" className={(navData) => (navData.isActive ? 'active' : '') + " navLink"}>{t('upgrade')}</NavLink>
                                    </>
                                }

                                {
                                    state.user.currentUserType && !location.pathname.includes(state.user.currentUserType) && <Button onClick={() => navigate(dashboard())} text={t('dashboard')} icon={<HandIndexThumb />} />
                                }

                                <div className="dropdown-wrapper" ref={profileDropDownRef}>
                                    <StyledUserPicture onClick={() => setShowingProfileDropdown(!showingProfileDropdown)} src={state.user?.picture || createImageFromInitials(state.user?.fullName || "Unknown User", theme.colors.black, theme.colors.secondary, 28)} alt={t('profilePicture')} />
                                    {
                                        showingProfileDropdown && <div className="profile-dropdown">
                                            <Group gap="none">
                                                <Link to="/profile" className="dropdown-link"><Person size={18} /> {t('profile')}</Link>
                                                {
                                                    !!state.user.currentUserType && state.user.userTypes.length > 1 &&
                                                    <span className="dropdown-link" onClick={switchProfileFunc}><ArrowLeftRight size={18} /> {t('switchProfile')}</span>
                                                }
                                                <span onClick={logout} className="dropdown-link"><DoorClosed size={18} /> {t('signOut')}</span>
                                            </Group>
                                        </div>
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <NavLink to="/templates" className={(navData) => (navData.isActive ? 'active' : '') + " navLink"}>{t('templates')}</NavLink>
                                <NavLink to="/pricing" className={(navData) => (navData.isActive ? 'active' : '') + " navLink"}>{t('pricing')}</NavLink>
                                <Button onClick={() => navigate("/auth/sign-in")} text={t('signIn')} icon={<HandIndexThumb />} />
                            </>
                        )
                    }
                </Group>
                <div className="wblnavToggler">
                    <label htmlFor="nav_checkvzletWLVeONp7paw1PUYyab7">
                        <div></div>
                        <div></div>
                        <div></div>
                    </label>
                </div>
            </Group>
        </StyledNav>
    );
};
