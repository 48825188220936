import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import { randomID } from "shared-library";
import styles from "./InputContainer.module.scss";
import { InfoCircleFill } from "react-bootstrap-icons";
import { CSSProperties, ReactNode, useMemo } from "react";
import { StyledErrorText, StyledIsRequired } from "../../../elements";
import { FormComponentProps } from "../../../utils/types/form-component-props";

export interface InputContainerProps extends FormComponentProps {
    id?: string;
    label?: string;
    error?: string;
    tooltip?: string;
    isRequired?: boolean;
    description?: string;
    leftIcon?: ReactNode;
    style?: CSSProperties;
    rightIcon?: ReactNode;
    render: (ID: string) => ReactNode;
}

const StyledContainer = styled.div`

    .input-group {
        width: 100%;
        position: relative;

        .left-icon {
            position: absolute;
            top: 12px;
            left: 10px;
            color: ${props => props.theme.colors.gray};
        }

        .right-icon {
            position: absolute;
            top: 16px;
            right: 10px;
            color: ${props => props.theme.colors.gray};
        }
    }
`

export const StyledLabel = styled.label`
    font-weight: 300;
    font-size: 0.8rem;
    margin-bottom: -5px;
    color: ${(props) => props.theme.colors.gray};
`
export const StyledTooltip = styled.span`
    margin: 0 4px;
    color: ${(props) => props.theme.colors.black};
`

export const InputContainer = (props: InputContainerProps) => {
    const { label, description, tooltip, error, style = {}, isRequired = false, render, rightIcon, leftIcon } = props;
    let id = useMemo(randomID, []);

    if (props.id) id = props.id;

    return (
        <StyledContainer className={`${styles.container}`} style={style}>
            {label && (
                <StyledLabel htmlFor={id}>
                    {label}{tooltip && <StyledTooltip>
                        <InfoCircleFill size={10} className={"pointer " + id} />
                    </StyledTooltip>}
                    {isRequired && <StyledIsRequired>*</StyledIsRequired>}
                    {description && <div className="description">{description}</div>}
                </StyledLabel>
            )}
            <div className="input-group">
                {
                    leftIcon && <span className="left-icon">
                        {leftIcon}
                    </span>
                }
                {render(id)}
                {
                    rightIcon && <span className="right-icon">
                        {rightIcon}
                    </span>
                }
            </div>
            {error && <StyledErrorText style={{ marginTop: '-7px' }}>{error}</StyledErrorText>}
            <Tooltip anchorSelect={"." + id} place="top">
                <span dangerouslySetInnerHTML={{ __html: tooltip || "" }}></span>
            </Tooltip>
        </StyledContainer>
    );
};
